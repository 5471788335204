import React, { useState } from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function DatePickerField({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [isOpen, setIsOpen] = useState(false);
  const [field] = useField(props);
  return (
    <>
      {props.label && <label>{props.label}</label>}
      <DatePicker
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        style={{ width: "100%" }}
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setFieldValue(field.name, val);
          setIsOpen(false);
        }}
        onInputClick={() => setIsOpen(true)}
        onClickOutside={() => setIsOpen(false)}
        open={isOpen}
      />
      {/* <span
        className="password-icon"
        style={touched.password && { marginRight: "20px" }}
        onClick={() => setIsOpen(true)}
      >
        <i className="fas fa-calendar-alt"></i>
      </span> */}
      {errors[field.name] && touched[field.name] ? (
        <div className="invalid-datepicker-feedback">
          {errors[field.name].toString()}
        </div>
      ) : (
        <div className="feedback">
          Please enter <b>{props.label}</b> in
          {props.dateFormat ? props.dateFormat : "mm/dd/yyyy"} format
        </div>
      )}
    </>
  );
}
