import { createSlice } from "@reduxjs/toolkit";

const initialRankingsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  rankingForEdit: undefined,
  lastError: null,
  rankingLevel: [],
  specializations: [],
  departments: [],
  prerequsit: undefined,
  rankingType: [],
  rankingCompany: [],
  exams: [],
  rankingRanking: [],
  editableRanking: undefined,
  admissionSessions: [],
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const allRankingsSlice = createSlice({
  name: "rankings",
  initialState: initialRankingsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getRankingById
    rankingFetched: (state, action) => {
      state.actionsLoading = false;
      state.rankingForEdit = action.payload.rankingForEdit;
      state.error = null;
    },

    // findFRankings
    rankingsFetched: (state, action) => {
      const { totalCount, entities, filteredStatusOverview } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.filteredStatusOverview = filteredStatusOverview;
    },

    // createRanking
    rankingCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.ranking);
    },

    // updateRanking
    rankingUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },

    // delete Ranking
    rankingDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.rankingId != action.payload.id
      );
    },
    // delete Ranking
    rankingHardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.rankingId != action.payload.id
      );
    },

    // delete Rankings
    rankingsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },

    // Fetch Rankings Level
    rankingLevelFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.rankingLevel = action.payload.rankingLevel;
    },

    // Fetch Rankings Level
    specializationFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.specializations = action.payload.specializations;
    },
    // Fetch Rankings Level
    departmentFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.departments = action.payload.departments;
    },
    // Fetch Rankings Level
    uniDropdownFetch: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.uniDropdown = action.payload.uniDropdown;
    },

    // get Prerequisit by Level Id
    prerequsitFetched: (state, action) => {
      state.actionsLoading = false;
      state.prerequsit = action.payload.prerequsit;
      state.error = null;
    },

    // get Prerequisit by Level Id
    rankingTypeFetched: (state, action) => {
      state.actionsLoading = false;
      state.rankingType = action.payload.rankingType;
      state.error = null;
    },

    // get Prerequisit by Level Id
    rankingCompanyFetched: (state, action) => {
      state.actionsLoading = false;
      state.rankingCompany = action.payload.rankingCompany;
      state.error = null;
    },

    // get Prerequisit by Level Id
    allExamsFetched: (state, action) => {
      state.actionsLoading = false;
      state.exams = action.payload.exams;
      state.error = null;
    },

    // get Admission Sessions
    allAdmissionSessionFetched: (state, action) => {
      state.actionsLoading = false;
      state.admissionSessions = action.payload.admissionSessions;
      state.error = null;
    },

    // get Prerequisit by Level Id
    rankingRankingFetched: (state, action) => {
      state.actionsLoading = false;
      state.rankingRanking = action.payload.rankingRanking;
      state.error = null;
    },

    // get Prerequisit by Level Id
    rankSingleFetched: (state, action) => {
      state.actionsLoading = false;
      state.editableRanking = action.payload.editableRanking;
      state.error = null;
    },

    // get Prerequisit by Level Id
    clearRankFetched: (state, action) => {
      state.actionsLoading = false;
      state.editableRanking = action.payload.editableRanking;
      state.error = null;
    },

    // createRanking
    rankingRankCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.rankingRanking = action.payload.rankingRanking;
      // state.rankingRanking.push(action.payload.rankingRanking);
    },

    // createRanking
    rankingRankDeleted: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.rankingRanking = state.rankingRanking.filter(
        (el) => el.id !== action.payload.id
      );
    },

    // createRanking
    rankingUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.rankingRanking = action.payload.rankingRanking;
    },
    statusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.statusOverview = action.payload.statusOverview;
    },
  },
});
