import { createSlice } from "@reduxjs/toolkit";

const initialUniState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  roleForEdit: undefined,
  error: null,
  statusOverview: {},
  countryList: [],
  stateNameList: [],
  cityList: [],
  departmentOptions: [],
  designationOptions: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const uniRoleSlice = createSlice({
  name: "role",
  initialState: initialUniState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getroleById
    roleFetched: (state, action) => {
      state.actionsLoading = false;
      state.roleForEdit = action.payload.roleForEdit;
      state.error = null;
    },

    // findrole
    allRoleFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createrole
    roleCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },

    // updaterole
    roleUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },

    // deleterole
    roleDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.roleId !== action.payload.id
      );
    },

    // deleterole
    roleHardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.roleId !== action.payload.id
      );
    },
    statusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.statusOverview = action.payload.statusOverview;
    },

    //country fetch
    countryFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.countryList = action.payload.countryList;
    },
    //state fetch
    stateFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.stateNameList = action.payload.stateNameList;
    },
    //city fetch
    cityFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.cityList = action.payload.cityList;
    },
    //role fetch
    roleFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.roleOptions = action.payload.roleOptions;
    },
    //uniModule fetch
    uniModuleFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.uniModuleOptions = action.payload.uniModuleOptions;
    },
    //designation fetch
    designationFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.designationOptions = action.payload.designationOptions;
    },
  },
});
