import React, { Suspense } from "react";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";
import EditScholarship from "../Universities/courses/scholarship/edit-scholarship/EditScholarship";
import { AllUniScholarships } from "./AllUniScholarship";
// import EditScholarship from "../Universities/scholarships/scholarship-edit/EditScholarship";

export default function AllScholarship() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact={true} from="/scholarship" to="/scholarship/all" />
        <ContentRoute from="/scholarship/all" component={AllUniScholarships} />
        <ContentRoute
          from="/scholarship/:id/edit"
          component={EditScholarship}
        />
      </Switch>
    </Suspense>
  );
}
