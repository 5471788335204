/* eslint-disable eqeqeq */
import React from "react";
import styles from "./MediaBox.module.scss";
export const MediaBox = (props) => {
  const { src, mimetype, mediaName } = props.data;
  const imagePath = src || "/images/image-placeholder.png";

  const handleClick = () => {
    if (props.onClick) props.onClick(props.data);
  };

  const PreviewItem = () => {
    const extension = src.split(".").pop();

    if (extension == "pdf" || mimetype == "application/pdf") {
      return (
        <div className={styles.filterArea}>
          <i className={`fas fa-file-pdf ${styles.icon}`}></i>
          <span className={styles.name}> {mediaName || ""} </span>
        </div>
      );
    }

    if (extension == "xlsx" || mimetype == "application/xlsx") {
      return (
        <div className={styles.filterArea}>
          <i className={`fas fa-file-excel ${styles.icon}`}></i>
          <span className={styles.name}> {mediaName || ""} </span>
        </div>
      );
    }

    if (
      extension == "jpg" ||
      extension == "png" ||
      extension == "svg" ||
      extension == "webp" ||
      mimetype == "image/jpeg" ||
      mimetype == "image/svg+xml" ||
      mimetype == "image/png" ||
      mimetype === "application/pdf"
    ) {
      return (
        <div className={styles.imageArea}>
          <img src={imagePath} alt="" />
          <span className={styles.name}> {mediaName || ""} </span>
        </div>
      );
    }

    if (extension == "mp4" || mimetype == "video/mp4") {
      return (
        <div className={styles.filterArea}>
          <video width="100%" height="130" controls="controls" preload="none">
            <source src={imagePath} type="video/mp4" autostart="false" />
            Your browser does not support the video tag.
          </video>
          <span className={styles.name}> {mediaName || ""} </span>
        </div>
      );
    }

    return (
      <div className={styles.filterArea}>
        <i className={`fas fa-file-excel ${styles.icon}`}></i>
        <span className={styles.name}> {mediaName || ""} </span>
      </div>
    );
  };

  return (
    <>
      <div
        className={`col-md-2 col-sm-3 col-xs-3  ${
          props.is_selected ? styles.selected : styles.button
        }`}
        onClick={handleClick}
      >
        <PreviewItem />
      </div>
    </>
  );
};

export default MediaBox;
