import { postAPI, getAPI } from "../../../../_unisearch/util/authUtils";

export const LOGIN_URL = `/auth/login`;
export const REGISTER_URL = `/auth/register`;
export const REQUEST_PASSWORD_URL = `/auth/forgot-password`;
export const ME_URL = `/auth/profile`;

export function login(email, password, userTypeSlug, recaptchaToken) {
  return postAPI(LOGIN_URL, { email, password, userTypeSlug, recaptchaToken });
}

export function register(email, fullname, username, password) {
  return postAPI(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return postAPI(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return getAPI(ME_URL);
}
