import React, { Fragment } from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler } from "../../_metronic/_partials/dropdowns";
export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { editAction, deleteAction, viewAction, customComponent }
) => {
  if (editAction || deleteAction || viewAction) {
    return (
      <Fragment>
        <Dropdown className="dropdown-inline">
          <Dropdown.Toggle
            className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
            variant="transparent"
            id="dropdown-toggle-top"
            as={DropdownCustomToggler}
          >
            <i className="ki ki-bold-more-hor" />
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu dropdown-menu-xs columnActions">
            <ul className="action-nav">
              {customComponent && (
                <li>
                  <button
                    className="btn-view"
                    onClick={() => customComponent(row)}
                  >
                    <span className="navi-icon">
                      <i className="flaticon-graph"></i>
                    </span>
                    <span className="navi-text">Leads</span>
                  </button>
                </li>
              )}
              {viewAction && (
                <li>
                  <button className="btn-edit" onClick={() => viewAction(row)}>
                    <span className="navi-icon">
                      <i className="flaticon-arrows"></i>
                    </span>
                    <span className="navi-text">View</span>
                  </button>
                </li>
              )}
              {editAction && (
                <li>
                  <button
                    className="btn-edit"
                    onClick={() => {
                      editAction(row);
                      console.log(row);
                    }}
                  >
                    <span className="navi-icon">
                      <i className="flaticon2-pen"></i>
                    </span>
                    <span className="navi-text">Edit</span>
                  </button>
                </li>
              )}
              {deleteAction && (
                <li className="navi-item">
                  <button
                    className="btn-delete"
                    onClick={() => {
                      deleteAction(row);
                    }}
                  >
                    <span className="navi-icon">
                      <i className="flaticon-delete"></i>
                    </span>
                    <span className="navi-text">Delete</span>
                  </button>
                </li>
              )}
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      </Fragment>
    );
  } else {
    return "";
  }
};
