/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";

export function OverviewWidget2({
  className,
  iconColor = "success",
  widgetHeight = "150px",
  props,
}) {
  const overviewData = props;
  return (
    <>
      <div
        className={`card card-custom custom-course-bg`}
        style={{ height: widgetHeight }}
      >
        <div className="card-body">
          <span className={`svg-icon svg-icon-3x svg-icon-white`}>
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/General/Clipboard.svg")}
            />
          </span>
          <div className="text-white font-weight-bolder font-size-h2 mt-3">
            {overviewData?.totalCourse}
          </div>

          <span className="text-white font-weight-bold font-size-lg mt-1">
            Total Course
          </span>
        </div>
      </div>
    </>
  );
}
