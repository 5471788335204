import { createSlice } from "@reduxjs/toolkit";

const initialUniState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  uniRankingForEdit: undefined,
  error: null,
  statusOverview: {},
  rankingCompanyList: [],
  rankingTypeList: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const uniRankingSlice = createSlice({
  name: "uniRanking",
  initialState: initialUniState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getuniRankingById
    uniRankingFetched: (state, action) => {
      state.actionsLoading = false;
      state.uniRankingForEdit = action.payload.uniRankingForEdit;
      state.error = null;
    },

    // finduniRanking
    allUniRankingFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createuniRanking
    uniRankingCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },

    // updateuniRanking
    uniRankingUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },

    // deleteuniRanking
    uniRankingDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.uniRankingId !== action.payload.id
      );
    },

    // deleteuniRanking
    uniRankingHardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.uniRankingId !== action.payload.id
      );
    },
    statusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.statusOverview = action.payload.statusOverview;
    },
    rankingTypeListFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.rankingTypeList = action.payload.rankingTypeList;
    },
    rankingCompanyListFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.rankingCompanyList = action.payload.rankingCompanyList;
    },
  },
});
