import React, { useMemo } from "react";

import { useScholarshipUIContext } from "./ScholarshipUIContext";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { ScholarshipsFilter } from "./scholarship-filter/ScholarshipFilter";
import { AllScholarshipTable } from "./scholarship-table/ScholarshipTable";

export function ScholarshipCard() {
  const scholarshipUIContext = useScholarshipUIContext();

  const scholarshipUIProps = useMemo(() => {
    return {
      ids: scholarshipUIContext.ids,
      queryParams: scholarshipUIContext.queryParams,
      newScholarship: scholarshipUIContext.newScholarship,
      openChangeStatusScholarship:
        scholarshipUIContext.openChangeStatusScholarship,
      openDeleteScholarship: scholarshipUIContext.openDeleteScholarship,
    };
  }, [scholarshipUIContext]);
  const handleDelete = () => {
    if (
      scholarshipUIProps?.queryParams?.filter?.status === "" ||
      scholarshipUIProps?.queryParams?.filter?.status === "Active" ||
      scholarshipUIProps?.queryParams?.filter?.status === "Draft" ||
      !scholarshipUIProps?.queryParams?.filter?.status
    ) {
      const data = scholarshipUIProps.ids?.map((item) => {
        return parseInt(item);
      });
      const status = "Deleted";
      scholarshipUIProps.openChangeStatusScholarship(data, status);
    } else if (scholarshipUIProps?.queryParams?.filter?.status === "Deleted") {
      const data = scholarshipUIProps.ids?.map((item) => {
        return parseInt(item);
      });
      scholarshipUIProps.openDeleteScholarship(data);
    }
  };

  const handleRestore = () => {
    if (
      scholarshipUIProps?.queryParams?.filter?.status === "Draft" ||
      scholarshipUIProps?.queryParams?.filter?.status === "Deleted"
    ) {
      const data = scholarshipUIProps.ids?.map((item) => {
        return parseInt(item);
      });
      const status = "Active";
      scholarshipUIProps.openChangeStatusScholarship(data, status);
    }
  };
  return (
    <Card>
      <CardHeader title="All Scholarships">
        <CardHeaderToolbar>
          {scholarshipUIProps?.ids?.length > 0 && (
            <button
              type="button"
              className="btn btn-danger mr-5"
              onClick={handleDelete}
            >
              {scholarshipUIProps?.queryParams?.filter?.status === "Deleted"
                ? "Delete"
                : "Trash"}
            </button>
          )}

          {(scholarshipUIProps?.queryParams?.filter?.status === "Draft" ||
            scholarshipUIProps?.queryParams?.filter?.status === "Deleted") &&
            scholarshipUIProps?.ids?.length > 0 && (
              <button
                type="button"
                className="btn btn-warning mr-5"
                onClick={handleRestore}
              >
                Restore
              </button>
            )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ScholarshipsFilter />
        <AllScholarshipTable />
      </CardBody>
    </Card>
  );
}
