import React, { useState } from "react";

export const MetaForm = (props) => {
  const [state, setState] = useState(props.data || {});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));

    //data passing parent component
    if (props.onChange) props.onChange(event);
  };

  return (
    <>
      <div className="card card-custom">
        <div className="card-header">
          <h3 className="card-title">Meta Information</h3>
        </div>
        <div className="card-body">
          {/* meta title */}
          <div className="mb-8">
            <label htmlFor="metaTitle" className="form-label">
              Meta Title
            </label>
            <input
              id="metaTitle"
              name="metaTitle"
              type="text"
              className="form-control form-control-solid"
              value={state.metaTitle || ""}
              onChange={handleChange}
            />
          </div>
          {/* meta description */}
          <div className="mb-10">
            <label htmlFor="metaDescription" className="form-label">
              Meta Description
            </label>
            <textarea
              className="form-control form-control-solid"
              name="metaDescription"
              id="metaDescription"
              rows="5"
              value={state.metaDescription || ""}
              onChange={handleChange}
            />
          </div>
          {/* keyword */}
          <div className="mb-10">
            <label className="form-label">Keyword</label>
            <input
              className="form-control form-control-solid"
              id="metaKeyword"
              name="metaKeyword"
              type="text"
              value={state.metaKeyword || ""}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MetaForm;
