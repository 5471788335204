import { createSlice } from "@reduxjs/toolkit";

const initialCounselingState = {
  listLoading: false,
  actionsLoading: false,
  counsellingOverview: undefined,
  totalCount: 0,
  entities: [],
  counselingForEdit: undefined,
  lastError: false,
  leadsEntity: [],
  leadsTotalCount: 0,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const counselingsSlice = createSlice({
  name: "counselings",
  initialState: initialCounselingState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // get counseling by Keyword
    overviewFached: (state, action) => {
      state.actionsLoading = false;
      state.counsellingOverview = action.payload.counsellingOverview;
      state.error = null;
    },

    // get counseling by Keyword
    counselingFetched: (state, action) => {
      state.actionsLoading = false;
      state.counselingForEdit = action.payload.counselingForEdit;
      state.error = null;
    },

    // find counselings
    counselingsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createCourse
    counselingCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.ounselling);
    },

    // updateCourse
    counselingUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.map((entity) => {
      //   if (entity.id === action.payload.course.id) {
      //     return action.payload.course;
      //   }
      //   return entity;
      // });
    },

    // delete Courses
    counselingsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(parseInt(el.counselId))
      );
    },

    // delete Course
    counselingDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => parseInt(el.counselId) !== action.payload.id
      );
    },

    counselingsLeadsFetched: (state, action) => {
      const { totalCount, leadsEntity } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.leadsEntity = leadsEntity;
      state.leadsTotalCount = totalCount;
    },
  },
});
