// import React, { Fragment, useState, useEffect } from "react";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
// import { useHistory, useLocation, NavLink } from "react-router-dom";
import { useLocation, NavLink } from "react-router-dom";

// import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { checkIsActive } from "../../../../_helpers";

import { encryptStorage } from "../../../../../_unisearch/helpers";

import { UniversityMenu } from "../../../../../app/modules/Universities/components";
import * as actions from "../../../../../app/modules/Universities/_redux/dashboard/uniAction";

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const dispatch = useDispatch();

  //unislug
  const [unislug, setunislug] = useState(undefined);

  //get location path
  const { pathname } = useLocation();
  const fullPath = pathname.split("?")[0];
  const pathArray = fullPath.split("/").filter(Boolean);
  const uniProfile = encryptStorage.getItem("uniProfile");

  useEffect(() => {
    if (
      !pathArray.includes("university") ||
      pathname === "/university/new" ||
      pathname === "/university/all" ||
      pathname === "/university/reviews" ||
      pathArray[2] === "edit" ||
      pathArray[2] === "all"
    ) {
      dispatch(actions.removeUniversityProfile());
      encryptStorage.removeItem("uniProfile");
      setunislug(undefined);
    } else {
      if (!uniProfile || uniProfile.slug !== pathArray[1]) {
        dispatch(actions.fetchUniversityProfile(pathArray[1]));
      }
      setunislug(pathArray[1]);
    }
  }, [dispatch, pathArray, uniProfile, pathname]);

  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {!unislug && (
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/dashboard"
            )}`}
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="menu-text">Dashboard</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
        )}

        {unislug && <UniversityMenu layoutProps={layoutProps} slug={unislug} />}
      </ul>
    </div>
  );
}
