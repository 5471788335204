import moment from "moment";
import React, { Fragment, useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  getHandlerTableChange,
  getSelectRow,
  headerSortingClasses,
  sortCaret,
} from "../../../../_metronic/_helpers";
import {
  ModalProgressBar,
  Pagination,
} from "../../../../_metronic/_partials/controls";
import {
  FormatAlignment,
  StatusColumnFormatter,
} from "../../../../_unisearch/helpers";
import { useCourseUIContext } from "../CourseUIContext";
import * as uiHelpers from "../CourseUIHelpers";
import * as actions from "../_redux/courses/coursesAction";
import {
  ActionsColumnFormatter,
  DurationFormater,
  FeesFormater,
} from "./ActionsColumnFormatter";
export function CoursesTable() {
  const coursesUIContext = useCourseUIContext();
  const coursesUIProps = useMemo(() => {
    return {
      ids: coursesUIContext.ids,
      setIds: coursesUIContext.setIds,
      queryParams: coursesUIContext.queryParams,
      setQueryParams: coursesUIContext.setQueryParams,
      openEditCourse: coursesUIContext.openEditCourse,
      openTrahCourse: coursesUIContext.openTrahCourse,
      openDeleteCourse: coursesUIContext.openDeleteCourse,
    };
  }, [coursesUIContext]);

  // Getting current state of customers list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({
      currentState: state.allCourse,
    }),
    shallowEqual
  );

  const { totalCount, entities, listLoading } = currentState;

  // files Redux state
  const dispatch = useDispatch();

  useEffect(() => {
    // clear selections list
    coursesUIProps.setIds([]);

    // server call by queryParams
    dispatch(actions.fetchCourses(coursesUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coursesUIProps.queryParams, dispatch]);

  // Table columns
  const columns = [
    {
      dataField: "courseName",
      text: "Course Title",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cell, row) => FormatAlignment(cell, row, "left"),
    },
    {
      dataField: "specialization.specializationName",
      text: "Course Preference",
      sort: false,
      formatter: (cellContent, row) => {
        if (row?.specializationParentId === (0 || null || "0")) {
          return <div>{row?.specialization?.specializationName}</div>;
        } else {
          return <div>{row?.specializationName}</div>;
        }
      },
    },
    {
      dataField: "specialization",
      text: "Course Specialization",
      sort: false,
      formatter: (cellContent, row) => {
        if (row?.specializationParentId !== (0 || null || "0")) {
          return <div>{row?.specialization?.specializationName}</div>;
        }
      },
    },
    {
      dataField: "country.name",
      text: "Country",
      sort: false,
    },
    {
      dataField: "university.universityName",
      text: "University",
      sort: false,
    },
    {
      dataField: "applicationFee",
      text: "Application Fees",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: FeesFormater, //(cell, row) => FormatAlignment(cell, row, "right"),
    },
    {
      dataField: "courseLevel.levelName",
      text: "Course Level",
      sort: false,
    },
    {
      dataField: "courseDuration",
      text: "Duration",
      sort: false,
      sortCaret: sortCaret,
      formatter: DurationFormater,
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: false,
      formatter: (cellContent, row) => {
        return <div>{moment(row.createdAt).format("YYYY-MM-DD")}</div>;
      },
    },
    {
      dataField: "updatedAt",
      text: "Updated At",
      sort: false,
      formatter: (cellContent, row) => {
        return <div>{moment(row.updatedAt).format("YYYY-MM-DD")}</div>;
      },
    },

    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: StatusColumnFormatter,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditCourse: coursesUIProps.openEditCourse,
        openDeleteCourse: coursesUIProps.openDeleteCourse,
        openTrahCourse: coursesUIProps.openTrahCourse,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: coursesUIProps.queryParams.pageSize,
    page: coursesUIProps.queryParams.pageNumber,
  };
  return (
    <Fragment>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              {listLoading && <ModalProgressBar />}
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden table-custom "
                bootstrap4
                remote
                keyField="courseId"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={[{ dataField: "id", order: "asc" }]}
                onTableChange={getHandlerTableChange(
                  coursesUIProps.setQueryParams
                )}
                selectRow={getSelectRow({
                  entities,
                  ids: coursesUIProps.ids,
                  setIds: coursesUIProps.setIds,
                  keyField: "courseId",
                })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </Fragment>
  );
}
