import { createSlice } from "@reduxjs/toolkit";

const initialScholarshipsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  scholarshipForEdit: undefined,
  lastError: null,
  scholarshipLevel: [],
  specializations: [],
  departments: [],
  workingCountryOptions: [],
  prerequsit: undefined,
  rankingType: [],
  rankingCompany: [],
  exams: [],
  scholarshipRanking: [],
  editableRanking: undefined,
  admissionSessions: [],
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const allScholarshipsSlice = createSlice({
  name: "scholarships",
  initialState: initialScholarshipsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // findFScholarships
    scholarshipsFetched: (state, action) => {
      const { totalCount, entities, filteredStatusOverview } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.filteredStatusOverview = filteredStatusOverview;
    },
    workingCountryDropdownFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.workingCountryOptions = action.payload.workingCountryOptions;
    },

    // delete Scholarship
    scholarshipDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.scholarshipId != action.payload.id
      );
    },
    // delete Scholarship
    scholarshipHardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.scholarshipId != action.payload.id
      );
    },

    // delete Scholarships
    scholarshipsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },

    // Fetch Scholarships Level
    scholarshipLevelFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.scholarshipLevel = action.payload.scholarshipLevel;
    },

    // Fetch Scholarships Level
    specializationFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.specializations = action.payload.specializations;
    },
    // Fetch Scholarships Level
    departmentFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.departments = action.payload.departments;
    },
    // Fetch Scholarships Level
    uniDropdownFetch: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.uniDropdown = action.payload.uniDropdown;
    },
    statusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.statusOverview = action.payload.statusOverview;
    },
  },
});
