import React from "react";
import { ScholarshipUIProvider } from "./ScholarshipUIContext";
import { ScholarshipCard } from "./ScholarshipCard";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import * as actions from "./_redux/scholarship/scholarshipAction";
import { swalConfirm, swalError } from "../../../_unisearch/helpers";
export function AllUniScholarships({ history }) {
  let { slug } = useParams();
  const dispatch = useDispatch();
  const scholarshipUIEvents = {
    openEditScholarship: (id) => {
      history.push(`/scholarship/${id}/edit`);
    },

    openDeleteScholarship: (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "To delete this item?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            dispatch(actions.hardDeleteScholarship(id));
            Swal.fire("Deleted!", "Scholarship has been Deleted.", "success");
            setTimeout(() => {
              history.push(`/scholarship`);
            }, 100);
          } catch (error) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          }
        }
      });
    },

    openChangeStatusScholarship: (id, status) => {
      const messageText = `To move this item to ${
        status === "Deleted" ? "Trash" : "Active"
      }`;
      swalConfirm(messageText, "Are you sure", "Yes move").then((result) => {
        if (result.isConfirmed) {
          try {
            const data = {
              ids: id,
              status: status,
            };
            dispatch(actions.changeStatusScholarship(data));
            setTimeout(() => {
              history.push("/scholarship");
            }, 100);
          } catch (error) {
            swalError(error);
          }
        }
      });
    },
  };

  return (
    <ScholarshipUIProvider scholarshipUIEvents={scholarshipUIEvents}>
      <ScholarshipCard />
    </ScholarshipUIProvider>
  );
}
