import { swalError, swalSuccess } from "../../../../../_unisearch/helpers";
import * as requestFromServer from "./filesCrud";
import { filesSlice, callTypes } from "./filesSlice";

const { actions } = filesSlice;

export const fetchFiles = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return (
    requestFromServer
      .findFiles(queryParams)
      // .getAllFiles()
      .then((response) => {
        const { data } = response;
        dispatch(
          actions.filesFetched({
            totalCount: data.total,
            entities: data.results,
          })
        );
      })
      .catch((error) => {
        // error.clientMessage = "Can't find files";
        dispatch(actions.catchError({ error, callType: callTypes.list }));
      })
  );
};

export const fetchFile = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.fileFetched({ fileForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getFileById(id)
    .then((response) => {
      let file = response.data;
      file.mediaFile = "";
      dispatch(actions.fileFetched({ fileForEdit: file }));
    })
    .catch((error) => {
      // error.clientMessage = "Can't find file";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteFile = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteFile({
      mediaManagerIds: [id],
    })
    .then((response) => {
      dispatch(actions.fileDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete file";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createFile = (fileForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .createFile(fileForCreation)
    .then((response) => {
      // const { data } = response;
      //dispatch(actions.fileCreated(data));
      return response;
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const updateFile = (file, id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateFile(file, id)
    .then((res) => {
      if (!res.error && res.statusCode === 200) {
        swalSuccess(res.message);
      } else {
        swalError(res.message);
      }
    })
    .catch((error) => {
      swalError(error);
      // error.clientMessage = "Can't update file";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteFiles = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteFiles(ids)
    .then(() => {
      dispatch(actions.filesDeleted({ ids }));
    })
    .catch((error) => {
      // error.clientMessage = "Can't delete files";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const hardDeleteMedia = (id) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.deleteFiles(id);
    dispatch(actions.mediaHardDeleted({ id }));
  } catch (errors) {
    console.log(errors);
    dispatch(actions.catchError({ callType: callTypes.action }));
  }
};
