/* eslint-disable array-callback-return */
import * as requestFromServer from "./coursesCrud";
import { coursesSlice, callTypes } from "./coursesSlice";

const { actions } = coursesSlice;

//fetch all course
export const fetchCourses = (university_id, queryParams) => async (
  dispatch
) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.findCourses(
      university_id,
      queryParams
    );
    const { total, results } = response.data;
    dispatch(actions.coursesFetched({ totalCount: total, entities: results }));
  } catch (errors) {
    let responseErr = { ...errors, clientMessage: "Can't find Course" };
    dispatch(actions.catchError({ responseErr, callType: callTypes.list }));
  }
};

//fetch single course
export const fetchCourse = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.courseFetched({ courseForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .getCourseById(id)
    .then((response) => {
      if (!response.error) {
        const result = response.data;

        let courseData = {
          status: result.status,
          courseId:
            result.courseId && result.courseId != null
              ? result.courseId
              : undefined,
          isTopListed:
            result.isTopListed && result.isTopListed != null ? ["1"] : ["0"],
          universityId:
            result.universityId && result.universityId != null
              ? result.universityId
              : "",
          courseName:
            result.courseName && result.courseName != null
              ? result.courseName
              : "",
          tags: result.tags && result.tags != null ? result.tags : [],
          courseLevelId:
            result.courseLevelId && result.courseLevelId != null
              ? result.courseLevelId
              : "",
          courseDescription:
            result.courseDescription && result.courseDescription != null
              ? result.courseDescription
              : "",
          courseRequirement:
            result.courseRequirement && result.courseRequirement != null
              ? result.courseRequirement
              : "",
          courseDuration:
            result.courseDuration && result.courseDuration != null
              ? result.courseDuration
              : "",
          courseSource:
            result.courseSource && result.courseSource != null
              ? result.courseSource
              : "",

          courseDurationType:
            result.courseDurationType && result.courseDurationType != null
              ? result.courseDurationType
              : "",
          applicationFee:
            result.applicationFee && result.applicationFee != null
              ? result.applicationFee
              : "",
          additionalInfo:
            result.additionalInfo && result.additionalInfo != null
              ? result.additionalInfo
              : "",
          featureImageId:
            result.featureImageId && result.featureImageId != null
              ? result.featureImageId
              : "",
          prospectusFileId:
            result.prospectusFileId && result.prospectusFileId != null
              ? result.prospectusFileId
              : "",
          featureImage:
            result.featureImage && result.featureImage != null
              ? result.featureImage
              : "",
          prospectusFile:
            result.prospectusFile && result.prospectusFile != null
              ? result.prospectusFile
              : "",

          coursePreReqMark: {
            courseLevelId:
              result.coursePreReqMark.courseLevelId &&
              result.coursePreReqMark.courseLevelId != null
                ? result.coursePreReqMark.courseLevelId
                : "",
            courseMark:
              result.coursePreReqMark.courseMark &&
              result.coursePreReqMark.courseMark != null
                ? result.coursePreReqMark.courseMark
                : "",
            preReqDescription:
              result.coursePreReqMark.preReqDescription &&
              result.coursePreReqMark.preReqDescription != null
                ? result.coursePreReqMark.preReqDescription
                : "",
          },
        };
        let specializationId = undefined;
        let departmentId = undefined;
        if (result.specialization && result.specialization.parentId !== "0") {
          specializationId = parseInt(result.specialization.specializationId);
          departmentId = parseInt(result.specialization.parentId);
        } else {
          departmentId = parseInt(result.specialization.specializationId);
          specializationId = undefined;
        }

        //tution Fees
        const tuitionFee = [];
        if (result.tuitionFee && result.tuitionFee.length) {
          result.tuitionFee.map((item) => {
            let tution = {
              tuitionFeeId:
                item.tuitionFeeId && item.tuitionFeeId != null
                  ? item.tuitionFeeId
                  : undefined,
              tuitionFor:
                item.tuitionFor && item.tuitionFor != null
                  ? item.tuitionFor
                  : "",
              amountFrom:
                item.amountFrom && item.amountFrom != null
                  ? parseInt(item.amountFrom)
                  : "",
              amountTo:
                item.amountTo && item.amountTo != null
                  ? parseInt(item.amountTo)
                  : "",
              numberOfCredit:
                item.numberOfCredit && item.numberOfCredit != null
                  ? item.numberOfCredit
                  : "",
            };
            tuitionFee.push(tution);
          });
        }

        //livingCost
        const livingCost = [];
        if (result.livingCost && result.livingCost.length > 0) {
          result.livingCost.map((item) => {
            let lcost = {
              livingCostId:
                item.livingCostId && item.livingCostId != null
                  ? item.livingCostId
                  : undefined,
              livingCostType:
                item.livingCostType && item.livingCostType != null
                  ? item.livingCostType
                  : "",

              valueFrom:
                item.valueFrom && item.valueFrom != null
                  ? parseInt(item.valueFrom)
                  : "",

              valueTo:
                item.valueTo && item.valueTo != null
                  ? parseInt(item.valueTo)
                  : "",
            };
            livingCost.push(lcost);
          });
        }

        //examRequiremnt
        const examRequiremnt = [];
        if (result.examRequiremnt && result.examRequiremnt.length > 0) {
          result.examRequiremnt.map((item) => {
            let examreq = {
              examId: item.examId && item.examId != null ? item.examId : "",
              score: item.score && item.score != null ? item.score : "",
              summary: item.summary && item.summary != null ? item.summary : "",
            };
            examRequiremnt.push(examreq);
          });
        }

        //intake
        const intake = [];
        if (result.intake && result.intake.length > 0) {
          result.intake.map((item) => {
            let intakeData = {
              month: item.month && item.month != null ? item.month : "",
              // monthTo:
              //   item.monthTo && item.monthTo != null ? item.monthTo : "January",
              admissionSessionId:
                item.admissionSessionId && item.admissionSessionId != null
                  ? item.admissionSessionId
                  : "",
              intakeId:
                item.intakeId && item.intakeId != null
                  ? item.intakeId
                  : undefined,
            };
            intake.push(intakeData);
          });
        }
        //courseRanking
        const courseRanking = [];
        if (result.courseRanking && result.courseRanking.length > 0) {
          result.courseRanking.map((item) => {
            let intakeData = {
              courseRankFrom:
                item.courseRankFrom && item.courseRankFrom != null
                  ? item.courseRankFrom
                  : "",
              courseRankTo:
                item.courseRankTo && item.courseRankTo != null
                  ? item.courseRankTo
                  : "",
              courseRankingYear:
                item.courseRankingYear && item.courseRankingYear != null
                  ? item.courseRankingYear
                  : "",
              rankingCompanyId:
                item.rankingCompanyId && item.rankingCompanyId != null
                  ? item.rankingCompanyId
                  : "",
              rankingType:
                item.rankingType && item.rankingType != null
                  ? item.rankingType
                  : "",
              rankingVarying:
                item.rankingVarying && item.rankingVarying != null
                  ? item.rankingVarying
                  : "",
            };
            courseRanking.push(intakeData);
          });
        }

        const courseForEdit = {
          ...courseData,
          tuitionFee,
          livingCost,
          examRequiremnt,
          intake,
          courseRanking,
          specializationId,
          departmentId,
        };

        //fetch new courses
        dispatch(actions.courseFetched({ courseForEdit: courseForEdit }));
      }
    })
    .catch((errors) => {
      let responseErr = { ...errors, clientMessage: "Can't find Course" };
      dispatch(actions.catchError({ responseErr, callType: callTypes.action }));
    });
};

//delte single course
export const deleteCourse = (id) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.deleteCourse({
      deletedIds: id,
    });
    dispatch(actions.courseDeleted({ id }));
  } catch (errors) {
    let responseErr = { ...errors, clientMessage: "Can't delete Course" };
    dispatch(actions.catchError({ responseErr, callType: callTypes.action }));
  }
};
//delte single course
export const hardDeleteCourse = (id) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.hardDeleteCourse(id);
    dispatch(actions.courseHardDeleted({ id }));
  } catch (errors) {
    let responseErr = { ...errors, clientMessage: "Can't delete Course" };
    dispatch(actions.catchError({ responseErr, callType: callTypes.action }));
  }
};

//create new course
export const createCourse = (data) => async (dispatch) => {
  delete data.courseRanking;
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.createCourse(data);
    return response;
  } catch (errors) {
    let responseErr = { ...errors, clientMessage: "Can't create course" };
    dispatch(actions.catchError({ responseErr, callType: callTypes.action }));
  }
};

//update single course
export const updateCourse = (course, id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  delete course.courseId;
  delete course.slug;
  delete course.featureImage;
  delete course.prospectusFile;

  return requestFromServer
    .updateCourse(course, id)
    .then((response) => {
      return response;
      //dispatch(actions.courseUpdated({ course }));
    })
    .catch((errors) => {
      let responseErr = { ...errors, clientMessage: "Can't update course" };
      dispatch(actions.catchError({ responseErr, callType: callTypes.action }));
    });
};
//update single course
export const updateCourseIntake = (intake, id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateCourseIntake(intake, id)
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log(errors);
      dispatch(actions.catchError({ errors, callType: callTypes.action }));
    });
};
export const updateCourseFee = (fee, id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateCourseFee(fee, id)
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log(errors);
      dispatch(actions.catchError({ errors, callType: callTypes.action }));
    });
};
export const updateCourseExpense = (expense, id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateCourseExpense(expense, id)
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log(errors);
      dispatch(actions.catchError({ errors, callType: callTypes.action }));
    });
};
export const updateSource = (value, id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateSource(value, id)
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      dispatch(actions.catchError({ errors, callType: callTypes.action }));
    });
};
export const updateMediaFile = (media, id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateMediaFile(media, id)
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log(errors);
      dispatch(actions.catchError({ errors, callType: callTypes.action }));
    });
};

//delte multiple courses
export const deleteCourses = (ids) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    await requestFromServer.deleteCourses(ids);
    dispatch(actions.coursesDeleted({ ids }));
  } catch (errors) {
    let responseErr = { ...errors, clientMessage: "Can't delete courses" };
    dispatch(actions.catchError({ responseErr, callType: callTypes.action }));
  }
};

//get all course level
export const fetchCourseLevel = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.getAllCourseLevel();
    // const courseLevel = response.data
    //   ? response.data.map((level) => {
    //       return {
    //         value: level.levelId,
    //         label: level.levelName,
    //       };
    //     })
    //   : [];
    dispatch(actions.courseLevelFetched({ courseLevel: response.data }));
  } catch (errors) {
    let responseErr = {
      ...errors,
      clientMessage: "Can't find Courses Level",
    };
    dispatch(actions.catchError({ responseErr, callType: callTypes.action }));
  }
};

export const fetchDepartment = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.getDepartment();
    let departments = response.data
      ? response.data.map((sp) => {
          return {
            value: parseInt(sp.specializationId),
            label: sp.specializationName,
            tags:
              sp.tags && sp.tags.length > 0
                ? sp.tags.map((item) => {
                    return {
                      label: item,
                      value: item,
                    };
                  })
                : [],
          };
        })
      : [];
    dispatch(actions.departmentFetched({ departments }));
  } catch (errors) {
    let responseErr = {
      ...errors,
      clientMessage: "Can't find Specialization",
    };
    dispatch(actions.catchError({ responseErr, callType: callTypes.action }));
  }
};
export const fetchSpecialization = (id) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.getAllSpecialization(id);
    let specializations = response.data
      ? response.data.map((sp) => {
          return {
            value: parseInt(sp.specializationId),
            label: sp.specializationName,
            tags:
              sp.tags && sp.tags.length > 0
                ? sp.tags.map((item) => {
                    return {
                      label: item,
                      value: item,
                    };
                  })
                : [],
          };
        })
      : [];
    dispatch(actions.specializationFetched({ specializations }));
  } catch (errors) {
    let responseErr = {
      ...errors,
      clientMessage: "Can't find Specialization",
    };
    dispatch(actions.catchError({ responseErr, callType: callTypes.action }));
  }
};

//get course prerequisite
export const fetchPrerequsite = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.prerequsitFetched({ prerequsite: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .getPrerequsiteByLevel(id)
    .then((response) => {
      let prerequsit = response.data;
      dispatch(actions.prerequsitFetched({ prerequsit }));
    })
    .catch((errors) => {
      let responseErr = {
        ...errors,
        clientMessage: "Can't find Prerequsit",
      };
      dispatch(actions.catchError({ responseErr, callType: callTypes.action }));
    });
};

//update prerequisit
export const updatePrerequsite = (data) => (dispatch) => {
  dispatch(actions.prerequsitFetched({ prerequsit: data }));
};

//get course reanking type
export const fetchRankintType = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  try {
    const response = await requestFromServer.getRankingType();
    let rankingType = response.data
      ? response.data.map((type) => {
          return {
            value: type.rankingTypeId,
            label: type.rankingTypeName,
          };
        })
      : [];
    dispatch(actions.rankingTypeFetched({ rankingType }));
  } catch (errors) {
    let responseErr = {
      ...errors,
      clientMessage: "Can't find Ranking Type",
    };
    dispatch(actions.catchError({ responseErr, callType: callTypes.action }));
  }
};

//get course reanking Company
export const fetchRankintCompany = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  try {
    const response = await requestFromServer.getRankingCompany();
    let rankingCompany = response.data
      ? response.data.map((item) => {
          return {
            value: item.rankingCompanyId,
            label: item.rankingCompanyName,
          };
        })
      : [];
    dispatch(actions.rankingCompanyFetched({ rankingCompany }));
  } catch (errors) {
    let responseErr = {
      ...errors,
      clientMessage: "Can't find Ranking Company",
    };
    dispatch(actions.catchError({ responseErr, callType: callTypes.action }));
  }
};

//get all exams with score
export const fetchAllExams = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.getAllExams();
    let exams = response.data;
    dispatch(actions.allExamsFetched({ exams }));
  } catch (errors) {
    let responseErr = {
      ...errors,
      clientMessage: "Can't find exams",
    };
    dispatch(actions.catchError({ responseErr, callType: callTypes.action }));
  }
};

//fetch Admission Sessions
export const fetchaAdmissionSessions = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.getAdmissionSessions();
    let admissionSessions = response.data;
    dispatch(actions.allAdmissionSessionFetched({ admissionSessions }));
  } catch (errors) {
    let responseErr = {
      ...errors,
      clientMessage: "Can't find Admission Sessions",
    };
    dispatch(actions.catchError({ responseErr, callType: callTypes.action }));
  }
};

//get Course Ranking by course ID
export const fetchCourseRanking = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.courseFetched({ courseRanking: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .getCourseRanking(id)
    .then((response) => {
      dispatch(actions.courseRankingFetched({ courseRanking: response.data }));
    })
    .catch((errors) => {
      let responseErr = {
        ...errors,
        clientMessage: "Can't find Ranking",
      };
      dispatch(actions.catchError({ responseErr, callType: callTypes.action }));
    });
};

//get Course Ranking by course ID
export const fetchSingleRanking = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.rankSingleFetched({ editableRanking: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .getSingleRanking(id)
    .then((response) => {
      const resuilt = response.data;

      let editableRank = {
        id: resuilt.id,
        courseId:
          resuilt.course && resuilt.course.id != null ? resuilt.course.id : "",
        rankFrom:
          resuilt.rankFrom && resuilt.rankFrom != null ? resuilt.rankFrom : "",
        rankTo: resuilt.rankTo && resuilt.rankTo != null ? resuilt.rankTo : "",
        rankingCompanyId:
          resuilt.rankingCompany && resuilt.rankingCompany.id != null
            ? resuilt.rankingCompany.id
            : "",
        rankingType:
          resuilt.rankingType && resuilt.rankingType != null
            ? resuilt.rankingType
            : "",
        year: response.data?.year,
      };

      dispatch(actions.rankSingleFetched({ editableRanking: editableRank }));
    })
    .catch((errors) => {
      let responseErr = {
        ...errors,
        clientMessage: "Can't find Ranking for your request",
      };
      dispatch(actions.catchError({ responseErr, callType: callTypes.action }));
    });
};

//Clear Editable Ranking
export const clearEditableRanking = () => (dispatch) => {
  return dispatch(actions.clearRankFetched({ editableRanking: undefined }));
};

//create new course
export const storeCourseRanking = (data) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.createCourseRank(data);
    const { courseRanking } = response.data;
    dispatch(actions.courseRankCreated({ courseRanking }));
  } catch (errors) {
    let responseErr = {
      ...errors,
      clientMessage: "Can't create course Ranking",
    };
    dispatch(actions.catchError({ responseErr, callType: callTypes.action }));
  }
};

//create new course
export const deleteCourseRanking = (id) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    await requestFromServer.deleteCourseRanking(id);
    dispatch(actions.courseRankDeleted({ id }));
  } catch (errors) {
    let responseErr = {
      ...errors,
      clientMessage: "Can't delete course Ranking",
    };
    dispatch(actions.catchError({ responseErr, callType: callTypes.action }));
  }
};

//update single course
export const updateCourseRanking = (data, id) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    await requestFromServer.updateCourseRanking(data, id);
    dispatch(actions.rankingUpdated({ data }));
  } catch (errors) {
    let responseErr = {
      ...errors,
      clientMessage: "Can't update course ranking",
    };
    dispatch(actions.catchError({ responseErr, callType: callTypes.action }));
  }
};

//fetch status overvire
export const fetchStatus = (uniId) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.fetchStatus(uniId);
    dispatch(actions.statusFetched({ statusOverview: response.data }));
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};
//delte expense
export const deleteCourseExpense = (id) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    await requestFromServer.deleteCourseExpense(id);
  } catch (errors) {
    dispatch(actions.catchError({ callType: callTypes.action }));
  }
};
//delte Fee
export const deleteCourseFee = (id) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    await requestFromServer.deleteCourseFee(id);
  } catch (errors) {
    dispatch(actions.catchError({ callType: callTypes.action }));
  }
};
//delte intake
export const deleteCourseIntake = (id) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    await requestFromServer.deleteCourseIntake(id);
  } catch (errors) {
    dispatch(actions.catchError({ callType: callTypes.action }));
  }
};
