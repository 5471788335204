import React from "react";
import LinearLoader from "./loader/Linear-loader";

export function ModalProgressBar({ variant = "success" }) {
  return (
    // <ProgressBar
    //   variant={variant}
    //   animated
    //   now={100}
    //   style={{ height: "3px", width: "100%" }}
    // />
    <LinearLoader />
  );
}
