import { createSlice } from "@reduxjs/toolkit";

const initialUniState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  fairForEdit: undefined,
  error: null,
  branchOptions: [],
  countryOptions: [],
  fairCategory: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const fairSlice = createSlice({
  name: "fair",
  initialState: initialUniState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getfairById
    fairFetched: (state, action) => {
      state.actionsLoading = false;
      state.fairForEdit = action.payload.fairForEdit;
      state.error = null;
    },

    // findfair
    allFairFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createfair
    fairCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },

    // updatefair
    fairUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter(
      //   (el) => el.id !== action.payload.id
      // );
    },

    // deletefair
    fairStatusChanged: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },

    // countryDropdown
    countryDropdownFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.countryOptions = action.payload.countryOptions;
    },
    // branchDropdown
    branchDropdownFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.branchOptions = action.payload.branchOptions;
    },
    // fetch Category with children
    categoriesFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.fairCategory = action.payload.fairCategory;
    },

    //fetch author
    authorFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.authorOptions = action.payload.authorOptions;
    },
    // fetch fair template
    fairTemplateFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.fairTemplateOptions = action.payload.fairTemplateOptions;
    },
    //fetch tag
    tagsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.fairTags = action.payload.fairTags;
    },
    statusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.statusOverview = action.payload.statusOverview;
    },
    playlistFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.fairPlaylist = action.payload.fairPlaylist;
    },
    // createTag
    tagCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },
    // deletefair
    fairHardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
  },
});
