export const CourseStatusCssClasses = {
  Active: "success",
  Inactive: "danger",
  Draft: "info",
};

export const StatusTitles = ["Active", "Draft", "Inactive"];

export const TypeCssClasses = ["success", "primary", ""];

export const defaultSorted = [{ dataField: "courseId", order: "ASC" }];

export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 },
];

export const initialFilter = {
  filter: {
    // name: "",
    // id: "",
  },
  sortOrder: "DESC", // asc||desc
  sortField: "courseId",
  pageNumber: 1,
  pageSize: 10,
};

export const durations = [
  { value: "", label: "Select Duration" },
  { value: 6, label: "6 months" },
  { value: 12, label: "12 months" },
  { value: 18, label: "18 months" },
  { value: 24, label: "24 months" },
  { value: 30, label: "30 months" },
  { value: 36, label: "36 months" },
  { value: 48, label: "48 months" },
  { value: 54, label: "54 months" },
  { value: 60, label: "60 months" },
  { value: 66, label: "66 months" },
  { value: 72, label: "72 months" },
  { value: 78, label: "78 months" },
  { value: 84, label: "84 months" },
  { value: 90, label: "90 months" },
];

export const sessions = [
  { value: "", label: "Select Session" },
  { value: 1, label: "Summer" },
  { value: 2, label: "Fall" },
  { value: 3, label: "Spring" },
  { value: 4, label: "Winter" },
];

export const months = [
  { id: 1, value: "", label: "Select Month" },
  { id: 2, value: "January", label: "January" },
  { id: 3, value: "February", label: "February" },
  { id: 4, value: "March", label: "March" },
  { id: 5, value: "April", label: "April" },
  { id: 6, value: "May", label: "May" },
  { id: 7, value: "June", label: "June" },
  { id: 8, value: "July", label: "July" },
  { id: 9, value: "August", label: "August" },
  { id: 10, value: "September", label: "September" },
  { id: 11, value: "October", label: "October" },
  { id: 12, value: "November", label: "November" },
  { id: 13, value: "December", label: "December" },
];
export const monthTo = [
  { id: 1, value: "", label: "Select Month" },
  { id: 2, value: "January", label: "January" },
  { id: 3, value: "February", label: "February" },
  { id: 4, value: "March", label: "March" },
  { id: 5, value: "April", label: "April" },
  { id: 6, value: "May", label: "May" },
  { id: 7, value: "June", label: "June" },
  { id: 8, value: "July", label: "July" },
  { id: 9, value: "August", label: "August" },
  { id: 10, value: "September", label: "September" },
  { id: 11, value: "October", label: "October" },
  { id: 12, value: "November", label: "November" },
  { id: 13, value: "December", label: "December" },
];

export const TuitionFor = [
  { value: "", label: "Select" },
  { value: "first_year", label: "1st Year" },
  { value: "second_year", label: "2nd Year" },
  { value: "third_year", label: "3rd Year" },
  { value: "fourth_year", label: "4th Year" },
];

//ranking Years
export const rankingYears = [
  { value: "", label: "Select Year" },
  { value: "2015", label: "2015" },
  { value: "2016", label: "2016" },
  { value: "2017", label: "2017" },
  { value: "2018", label: "2018" },
  { value: "2019", label: "2019" },
  { value: "2020", label: "2020" },
  { value: "2021", label: "2021" },
  { value: "2022", label: "2022" },
  { value: "2023", label: "2023" },
  { value: "2024", label: "2024" },
  { value: "2025", label: "2025" },
  { value: "2026", label: "2026" },
  { value: "2027", label: "2027" },
];
