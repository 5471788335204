import React, { useEffect, useState } from "react";
import { swalError } from "../../../_unisearch/helpers";
import { getAPI } from "../../../_unisearch/util/authUtils";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../controls";
export function TopSources({ timeValue, dateChange, props }) {
  const [devices, setDevices] = useState([]);
  const siteURL = process.env.REACT_APP_GRAPH_URL;
  const graphSecret = process.env.REACT_APP_ANALYSIS_SECRET_KEY;
  useEffect(() => {
    let unmount = false;
    const fetchSourceDataList = async () => {
      try {
        if (unmount) return;
        await getAPI(
          `https://analytics.myunisearch.com/api/stats/${siteURL}/sources?period=${timeValue}&date=${dateChange}&filters={}&auth=${graphSecret}&show_noref=true`
        ).then((res) => {
          if (!res.error) {
            setDevices(res);
          }
          if (res.error) {
            swalError(res.data);
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchSourceDataList();
    return () => {
      unmount = true;
    };
  }, [timeValue]);
  return (
    <Card>
      <CardHeader title="Top Sources" />
      <CardBody>
        <div className="country_stats_wrapper">
          <div className="country_stats_title">
            <span>Source</span>
            <span>Visitors</span>
          </div>
          <ul>
            {devices.map((item, i) => {
              return (
                <li key={`${i}statData`}>
                  <span>{item.name}</span>
                  <span>{item.count}</span>
                  <div
                    className="line"
                    style={{ width: `${item.percentage}%` }}
                  ></div>
                </li>
              );
            })}
          </ul>
        </div>
      </CardBody>
    </Card>
  );
}
