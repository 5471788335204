/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { Field } from "formik";

import { Input, Select } from "../../../../../../_metronic/_partials/controls";

export const ExamComponent = ({
  values,
  exams,
  selectedExam,
  onChange,
  index,
  errors,
  touched,
  ...props
}) => {
  const [scoreState, setScoreState] = useState([]);

  const handleCourseExam = (event) => {
    const { name, value } = event.target;
    if (name == "examId") {
      getExamScore(value);
    }

    if (onChange) {
      onChange(event, index);
    }
  };

  const getExamScore = (id) => {
    const exItem = exams.filter((item) => {
      return item.examId == id;
    });

    if (exItem && exItem.length > 0) setScoreState(exItem[0].point ?? []);
  };

  useEffect(() => {
    if (selectedExam) getExamScore(selectedExam.examId);
  }, [selectedExam, exams]);
  return (
    <Fragment>
      <div className="col-lg-3">
        <div className="form-group">
          <Select
            name={`examId`}
            label="Exam"
            className={` form-control ${
              errors.examId && touched.examId ? "is-invalid" : ""
            }`}
            onChange={(e) => handleCourseExam(e)}
            value={values?.examId}
          >
            <option value="">Select Exam</option>
            {exams.map((exam, index) => (
              <option key={`exam_${index}`} value={exam?.examId}>
                {exam?.examName ?? ""}
              </option>
            ))}
          </Select>
          {errors.examId && touched.examId ? (
            <div className="invalid-feedback">{errors.examId}</div>
          ) : null}
        </div>
      </div>
      <div className="col-lg-2">
        <div className="form-group">
          <Select
            name={`score`}
            label="Exam Score"
            className={`form-control ${
              errors.score && touched.score ? "is-invalid" : ""
            }`}
            onChange={(e) => handleCourseExam(e)}
            value={values?.score}
          >
            <option value="">Select Score</option>
            {scoreState &&
              scoreState.map((score, index) => (
                <option key={`score_ ${index}`} value={score?.point}>
                  {score.point}
                </option>
              ))}
          </Select>
          {errors.score && touched.score ? (
            <div className="invalid-feedback">{errors.score}</div>
          ) : null}
        </div>
      </div>
      <div className="col-lg-5">
        <div className="form-group">
          <Field
            label="Exam Summary"
            className={` form-control ${
              errors.summary && touched.summary ? "is-invalid" : ""
            }`}
            name={`summary`}
            component={Input}
            placeholder="Exam summary"
            type="text"
            onChange={(e) => handleCourseExam(e)}
            value={values?.summary}
            autoComplete="off"
          />
          {errors.summary && touched.summary ? (
            <div className="invalid-feedback">{errors.summary}</div>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};
