import { createSlice } from "@reduxjs/toolkit";

const initialCountryState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  countryForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const countriesSlice = createSlice({
  name: "country",
  initialState: initialCountryState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getCountryById
    // countryFetched: (state, action) => {
    //   state.actionsLoading = false;
    //   state.countryForEdit = action.payload.countryForEdit;
    //   state.error = null;
    // },

    // findFCountry
    countryFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createCountry
    countryCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },

    // updateCountry
    countryUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.map((entity) => {
      //   if (entity.id === action.payload.course.id) {
      //     return action.payload.course;
      //   }
      //   return entity;
      // });
    },

    // delete country
    countryDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },

    // delete Country
    CountriesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
  },
});
