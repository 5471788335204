import { createSlice } from "@reduxjs/toolkit";

const initialUniState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  newsForEdit: {},
  error: null,
  authorOptions: [],
  newsTemplate: [],
  countryOptions: [],
  categoryOptions: [],
  metaKeyword: [],
  newsCategory: {},
  newsTags: [],
  status: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const newsSlice = createSlice({
  name: "news",
  initialState: initialUniState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getNewsById
    newsFetched: (state, action) => {
      state.actionsLoading = false;
      state.newsForEdit = action.payload.newsForEdit;
      state.error = null;
    },

    // findNews
    allNewsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createNews
    newsCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },

    // updateNews
    newsUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.newsId === action.payload.news.newsId) {
          return action.payload.news;
        }
        return entity;
      });
    },

    // deleteNews
    newsTrashed: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.newsId !== action.payload.id
      );
    },
    // deleteNews
    newsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.newsId !== action.payload.id
      );
    },

    // fetch Category with children
    categoriesFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.newsCategory = action.payload.newsCategory;
    },

    // fetch Category without children
    categoryDropdownFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.categoryOptions = action.payload.categoryOptions;
    },

    //fetch template
    templateFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.newsTemplate = action.payload.newsTemplate;
    },

    //fetch author
    authorFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.authorOptions = action.payload.authorOptions;
    },

    //fetch tag
    tagsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.newsTags = action.payload.newsTags;
    },

    countryFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.countryOptions = action.payload.countryOptions;
    },

    statusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.statusOverview = action.payload.statusOverview;
    },
  },
});
