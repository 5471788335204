/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";

export function OverviewWidget3({
  className,
  iconColor = "success",
  widgetHeight = "150px",
  props,
}) {
  const overviewData = props;
  return (
    <>
      <div
        className={`card card-custom bg-info ${className}`}
        style={{ height: widgetHeight }}
      >
        <div className="card-body">
          <span
            className={`svg-icon svg-icon-3x svg-icon-white svg-icon-white ml-n2`}
          >
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}
            />
          </span>
          <div className="text-white font-weight-bolder font-size-h2 mt-3">
            {overviewData?.totalCounsellor}
          </div>

          <span className="text-white font-weight-bold font-size-lg mt-1">
            Total Counsellor
          </span>
        </div>
      </div>
    </>
  );
}
