// import axios from "axios";
import {
  postAPI,
  getAPI,
  patchAPI,
} from "../../../../../_unisearch/util/authUtils";

export const API_ENDPOINTS = `${process.env.REACT_APP_API_URL}`;
export const scholarship_ENDPOINTS = `/admin/university-scholarship`;

// CREATE =>  POST: add a new scholarship to the server
export function createScholarship(scholarship) {
  return postAPI(scholarship_ENDPOINTS, scholarship);
}
// READ
export function getAllScholarships() {
  return getAPI(scholarship_ENDPOINTS);
}
// items => filtered/sorted result
export function fetchUnniDropdown() {
  return postAPI(`admin/university/finduniversity`, {
    filter: {},
    pageNumber: 1,
    pageSize: 1000,
    sortField: "universityId",
    sortOrder: "DESC",
  });
}
export function findScholarships(queryParams) {
  const { filter, pageNumber, pageSize, sortField, sortOrder } = queryParams;

  return postAPI(`/admin/university-scholarship/filter-list/all-scholarship`, {
    filter: filter,
    pageNumber: pageNumber,
    pageSize: pageSize,
    sortField: sortField,
    sortOrder: sortOrder,
  });
}

// DELETE => delete the Scholarship from the server
export function statusChangeScholarship(data) {
  return patchAPI("/admin/university-scholarship/change-status", data);
}

// DELETE Scholarship by ids
export function deleteScholarships(ids) {
  return patchAPI(`admin/university-scholarship/hard-delete`, { ids });
}

export function getAllScholarshipLevel() {
  return getAPI(`study/scholarship/level-dropdown`);
}

//get specialization
export function getDepartment() {
  return getAPI(`/public/specialization`);
}
export function fetchWorkingCountryDropdown() {
  return getAPI("admin/country/workable");
}
//get Department
export function getAllSpecialization(id) {
  return getAPI(`/public/department/${id}`);
}

export function fetchStatus() {
  return getAPI(`admin/university-scholarship/status-overview-all-scholarship`);
}
