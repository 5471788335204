import { createSlice } from "@reduxjs/toolkit";

const initialUniState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  newsForEdit: undefined,
  error: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const blogTagsSlice = createSlice({
  name: "tags",
  initialState: initialUniState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getNewsById
    tagFetched: (state, action) => {
      state.actionsLoading = false;
      state.tagForEdit = action.payload.tagForEdit;
      state.error = null;
    },

    // findNews
    allTagFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createNews
    tagCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },

    // updateNews
    tagUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.map((entity) => {
      //   if (entity.id === action.payload.category.id) {
      //     return action.payload.category;
      //   }
      //   return entity;
      // });
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },

    // deleteNews
    tagDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },

    statusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.statusOverview = action.payload.statusOverview;
    },

    statusChanged: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.statusChange = action.payload.statusChange;
      let yFilter = action.payload.statusChange.ids?.map((itemY) => {
        return itemY.toString();
      });
      state.entities = state.entities.filter((el) => !yFilter.includes(el.id));
    },

    // // deleteFiles
    // universitiesDeleted: (state, action) => {
    //   state.error = null;
    //   state.actionsLoading = false;
    //   state.entities = state.entities.filter(
    //     (el) => !action.payload.ids.includes(el.id)
    //   );
    // },
  },
});
