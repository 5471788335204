import { swalError, swalSuccess } from "../../../../../../_unisearch/helpers";
import * as requestFromServer from "./scholarshipRequest";
import { scholarshipSlice, callTypes } from "./scholarshipSlice.js";

const { actions } = scholarshipSlice;

export const fetchAllScholarship = (uniId, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllScholarship(uniId, queryParams)
    .then((response) => {
      dispatch(
        actions.allScholarshipFetched({
          totalCount: response.data.total,
          entities: response.data.results,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find Scholarship";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchScholarship = (id) => (dispatch) => {
  if (!id) {
    return dispatch(
      actions.scholarshipFetched({ scholarshipForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getScholarshipById(id)
    .then((response) => {
      let Scholarship = response.data;
      let scholarshipForEdit = {
        scholarshipName:
          Scholarship?.scholarshipName && Scholarship?.scholarshipName != null
            ? Scholarship?.scholarshipName
            : "",
        scholarshipAmount:
          Scholarship?.scholarshipAmount &&
          Scholarship?.scholarshipAmount != null
            ? Scholarship?.scholarshipAmount
            : "",
        description:
          Scholarship?.description && Scholarship?.description != null
            ? Scholarship?.description
            : "",
        scholarshipLink:
          Scholarship?.scholarshipLink && Scholarship?.scholarshipLink != null
            ? Scholarship?.scholarshipLink
            : "",
        deadline:
          Scholarship?.deadline && Scholarship?.deadline != null
            ? Scholarship?.deadline
            : "",
        status:
          Scholarship?.status && Scholarship?.status != null
            ? Scholarship?.status
            : "",
        specializationId:
          Scholarship?.specializationId && Scholarship?.specializationId != null
            ? Scholarship?.specializationId
            : undefined,
        universityId:
          Scholarship?.universityId && Scholarship?.universityId != null
            ? Scholarship?.universityId
            : undefined,
      };
      if (Scholarship.level && Scholarship.level.length > 0) {
        scholarshipForEdit.levelId = Scholarship?.level.map((item) => {
          return {
            label: item.levelName,
            value: item.levelId,
          };
        });
      }
      dispatch(
        actions.scholarshipFetched({
          scholarshipForEdit: scholarshipForEdit,
        })
      );
    })
    .catch((error) => {
      console.log(error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createScholarship = (scholarshipForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createScholarship(scholarshipForCreation)
    .then((response) => {
      const { Scholarship } = response.data;
      dispatch(actions.scholarshipCreated({ Scholarship }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create Scholarship";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateScholarship = (Scholarship, id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateScholarship(Scholarship, id)
    .then((res) => {
      if (!res.error) {
        swalSuccess("Updated Successfully");
      } else {
        swalError(res.message);
      }
      dispatch(actions.scholarshipUpdated({ Scholarship }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteScholarship = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteScholarship({
      deleteIds: ids,
    })
    .then((response) => {
      dispatch(actions.scholarshipDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete Scholarship";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const hardDeleteScholarship = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .hardDeleteScholarship(id)
    .then((response) => {
      dispatch(actions.scholarshipHardDeleted({ id }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//fetch parent scholarship
export const fetchAllParentScholarship = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchParentScholarship()
    .then((response) => {
      dispatch(
        actions.parentScholarshipFetched({ parentScholarship: response.data })
      );
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//fetch status overvire
export const fetchStatus = (uniId) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.fetchStatus(uniId);
    dispatch(actions.statusFetched({ statusOverview: response.data }));
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};
//fetch status overvire
export const fetchSpecialization = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.getSpecialization();
    const options = response.data.map((item) => {
      return {
        label: item.specializationName,
        value: item.specializationId,
      };
    });
    dispatch(actions.specializationFetched({ specializationOptions: options }));
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};
//fetch status overvire
export const fetchLevel = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.getLevel();
    const data = response.data;
    const levelOptions = data.map((item) => {
      return {
        label: item.levelName,
        value: item.levelId,
      };
    });

    dispatch(actions.levelFetched({ allLevel: levelOptions }));
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};
