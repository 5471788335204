import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  userForEdit: undefined,
  lastError: null,
  role: [],
  countryList: [],
  stateList: [],
  cityList: [],
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const adminUsersSlice = createSlice({
  name: "users",
  initialState: initialUserState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getUserById
    userFetched: (state, action) => {
      state.actionsLoading = false;
      state.userForEdit = action.payload.userForEdit;
      state.error = null;
    },

    // create User
    userCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.user);
    },

    // all country
    countryFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.countryList = action.payload.countryList;
    },

    // all state by country
    stateFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.stateList = action.payload.stateList;
    },

    // all city by state
    cityFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.cityList = action.payload.cityList;
    },

    // all user type
    userTypeFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.userType = action.payload.userType;
    },

    // find users
    usersFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // updateUser
    userUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.map((entity) => {
      //   if (entity.id === action.payload.course.id) {
      //     return action.payload.course;
      //   }
      //   return entity;
      // });
    },

    //delete user
    userDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id != action.payload.id
      );
    },

    // delete Courses
    // coursesDeleted: (state, action) => {
    //   state.error = null;
    //   state.actionsLoading = false;
    //   state.entities = state.entities.filter(
    //     (el) => !action.payload.ids.includes(el.id)
    //   );
    // },

    // get all user type
    allUserTypeFetched: (state, action) => {
      state.actionsLoading = false;
      state.userType = action.payload.userType;
      state.error = null;
    },

    // get all user role
    allUserRoleFetched: (state, action) => {
      state.actionsLoading = false;
      state.role = action.payload.role;
      state.error = null;
    },
  },
});
