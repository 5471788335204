import React, { useEffect, useState } from "react";

const FormTextEditor = (props) => {
  const placeholder = props.placeholder || false;
  const [value, setValue] = useState(props.value);

  const handleChange = (e) => {
    setValue(e.target.value);

    if (props.onChange) {
      if (props.optional) props.onChange(e, props.optional);
      else props.onChange(e);
    }
  };

  useEffect(() => {
    if (props.value) setValue(props.value);
  }, [props.value]);
  return (
    <>
      <textarea
        id={props.id || `_uid${Math.random() * 10}`}
        className={`form-control form-control-solid`}
        type={props.type || "text"}
        name={props.name || "input_name"}
        placeholder={placeholder || "..."}
        value={value}
        min={props.min || ""}
        readOnly={props.readOnly ? true : false}
        required={props.required ? true : false}
        onChange={handleChange}
        autoComplete="off"
      />
    </>
  );
};

export default FormTextEditor;
 