import React, { Fragment, useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getHandlerTableChange,
  getSelectRow,
  headerSortingClasses,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../_metronic/_helpers";
import { Pagination } from "../../../../_metronic/_partials/controls";
import {
  FormatAlignment,
  FormateImage,
  StatusColumnFormatter,
} from "../../../../_unisearch/helpers";
import * as actions from "../_redux/scholarship/scholarshipAction";
import { useScholarshipUIContext } from "../ScholarshipUIContext";
import * as uiHelpers from "../ScholarshipUIHelpers";
import {
  ActionsColumnFormatter,
  DurationFormater,
  FeesFormater,
} from "./ActionsColumnFormatter";
export function AllScholarshipTable() {
  const history = useHistory();
  const scholarshipsUIContext = useScholarshipUIContext();
  const scholarshipsUIProps = useMemo(() => {
    return {
      ids: scholarshipsUIContext.ids,
      setIds: scholarshipsUIContext.setIds,
      queryParams: scholarshipsUIContext.queryParams,
      setQueryParams: scholarshipsUIContext.setQueryParams,
      openEditScholarship: scholarshipsUIContext.openEditScholarship,
      openChangeStatusScholarship:
        scholarshipsUIContext.openChangeStatusScholarship,
      openDeleteScholarship: scholarshipsUIContext.openDeleteScholarship,
    };
  }, [scholarshipsUIContext]);

  // Getting current state of customers list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({
      currentState: state.allScholarship,
    }),
    shallowEqual
  );

  const { totalCount, entities, listLoading } = currentState;

  // files Redux state
  const dispatch = useDispatch();

  useEffect(() => {
    // clear selections list
    scholarshipsUIProps.setIds([]);

    // server call by queryParams
    dispatch(actions.fetchScholarships(scholarshipsUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scholarshipsUIProps.queryParams, dispatch]);

  // Table columns
  const columns = [
    {
      dataField: "universityScholarshipId",
      text: "Id",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "scholarshipName",
      text: "Scholarship Title",
      sort: true,
      sortCaret: sortCaret,
      style: {
        width: "350px",
      },
    },
    {
      dataField: "specialization",
      text: "Scholarship Preference",
      sort: false,
    },
    {
      dataField: "university.universityName",
      text: "University",
      sort: false,
    },
    {
      dataField: "country.name",
      text: "Country",
      sort: false,
    },
    {
      dataField: "scholarshipAmount",
      text: "Scholarship Amount",
      sort: false,
    },
    {
      dataField: "level",
      text: "Scholarship Level",
      formatter: (cellContent, row) => {
        return (
          <div>
            {row.level &&
              row.level.length > 0 &&
              row.level.map((item, index) => (
                <span key={`level_${index}`}>
                  {item}
                  {row.level.length !== index + 1 && <br />}
                </span>
              ))}
          </div>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: StatusColumnFormatter,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditScholarship: scholarshipsUIProps.openEditScholarship,
        openDeleteScholarship: scholarshipsUIProps.openDeleteScholarship,
        openChangeStatusScholarship:
          scholarshipsUIProps.openChangeStatusScholarship,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: scholarshipsUIProps.queryParams.pageSize,
    page: scholarshipsUIProps.queryParams.pageNumber,
  };
  return (
    <Fragment>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="universityScholarshipId"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={[{ dataField: "id", order: "asc" }]}
                onTableChange={getHandlerTableChange(
                  scholarshipsUIProps.setQueryParams
                )}
                selectRow={getSelectRow({
                  entities,
                  ids: scholarshipsUIProps.ids,
                  setIds: scholarshipsUIProps.setIds,
                  keyField: "universityScholarshipId",
                })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </Fragment>
  );
}
