import React from "react";
import FormTextEditor from "../../_unisearch/forms/FormTextEditor";
import MetaForm from "../../_unisearch/forms/MetaForm";
import { useSubheader } from "../../_metronic/layout";

export const MyPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("My Custom title");

  return (
    <div className="row">
      <div className="col-lg-8">
        <div className="card card-custom shadow mb-5">
          <div className="card-header">
            <h3 className="card-title">Country Basic Information</h3>
          </div>
          <div className="card-body">
            {/* select country */}
            <div className="mb-10">
              <label className="form-label title">Select Country</label>
              <select
                className="form-control form-control-solid flex-column-fluid"
                aria-label="Select example"
              >
                <option>Select Country</option>
                <option value="1">Bangladesh</option>
                <option value="2">Australia</option>
                <option value="3">England</option>
              </select>
            </div>

            {/* city name */}
            <div className="mb-10">
              <label className="form-label">City Name</label>
              <input
                type="text"
                className="form-control form-control-solid"
                placeholder="City Name"
              />
            </div>

            {/* city description */}
            <div>
              <label className="form-label">City Description</label>
              <div className="card card-custom card-px-0 card-border">
                <div className="card-body py-0 h-250px">
                  <FormTextEditor
                    name="description"
                    value={
                      "<p>Simplicity is the best policy form <strong>editor</strong> rocks 😀</p>"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* meta card */}
        <MetaForm />
      </div>

      {/* column two */}
      <div className="col-lg-4"></div>
    </div>
  );
};
