import { createSlice } from "@reduxjs/toolkit";

const initialCityState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  cityForEdit: undefined,
  lastError: null,
  countries: undefined,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const citySlice = createSlice({
  name: "city",
  initialState: initialCityState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getCountryById
    // countryFetched: (state, action) => {
    //   state.actionsLoading = false;
    //   state.cityForEdit = action.payload.cityForEdit;
    //   state.error = null;
    // },

    // findFCountry
    cityFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createCountry
    cityCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },

    // updateCountry
    cityUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.map((entity) => {
      //   if (entity.id === action.payload.course.id) {
      //     return action.payload.course;
      //   }
      //   return entity;
      // });
    },

    // delete city
    cityDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.cityId !== action.payload.id
      );
    },

    // delete Country
    CitiesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },

    coutnriesFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.countries = action.payload.countries;
    },

    stateFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.states = action.payload.states;
    },
  },
});
