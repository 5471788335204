import * as requestFromServer from "./uniRequest";
import { uniSlice, callTypes } from "./uniSlice.js";

const { actions } = uniSlice;

export const fetchUniversities = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findUniversities(queryParams)
    .then((response) => {
      const { results, total } = response.data;
      dispatch(
        actions.universitiesFetched({ totalCount: total, entities: results })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find universities";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchUniversity = (id) => (dispatch) => {
  if (!id) {
    return dispatch(
      actions.universityFetched({ universityForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getUniversityById(id)
    .then((response) => {
      let university = response.data;
      dispatch(actions.universityFetched({ universityForEdit: university }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find university";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createUniversity = (universityForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createUniversity(universityForCreation)
    .then((response) => {
      const { university } = response.data;
      dispatch(actions.universityCreated({ university }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create university";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateUniversity = (university, id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateUniversity(university, id)
    .then(() => {
      dispatch(actions.universityUpdated({ university }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update university";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteUniversity = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteUniversity({
      universityIds: [id],
    })
    .then((response) => {
      dispatch(actions.universityDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete university";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteUniversities = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteUniversities(ids)
    .then(() => {
      dispatch(actions.universitiesDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete universities";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchUniversityProfile = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.profileFetched({ uniProfile: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getUniProfiles(id)
    .then((response) => {
      let university = response.data;
      dispatch(actions.profileFetched({ uniProfile: university }));
    })
    .catch((error) => {
      console.log(error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//empty university profile
export const removeUniversityProfile = () => (dispatch) => {
  return dispatch(actions.profileFetched({ uniProfile: undefined }));
};

//fetch all countries
export const fetchAllCountries = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAllCountries()
    .then((response) => {
      dispatch(actions.coutnriesFetched({ uniCuntries: response.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find country";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//All University Review
export const fetchUniversitiesReview = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findUniversitiesReview(queryParams)
    .then((response) => {
      const { results, total } = response.data;
      dispatch(
        actions.universitiesReviewFetched({
          totalCount: total,
          entities: results,
          // allUniReview: response.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find Review";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//Single University Review
export const fetcSinglehUniversityReview = (queryParams, id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findSingleUniversityReview(queryParams, id)
    .then((response) => {
      const { results, total } = response.data;
      dispatch(
        actions.singleUniversityReviewFetched({
          reviewSingleTotalCount: response.data.total,
          reviewSingleEntities: response.data.results,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find Review";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//publish review
export const publishReview = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .publishReview({
      universityReviewIds: id,
      status: "Active",
    })
    .then((response) => {
      dispatch(actions.reviewPublish({ id }));
    })
    .catch((error) => {
      // error.clientMessage = "Can't approve comment";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//feature review
export const featureReview = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .featureReview({
      ids: [id],
    })
    .then((response) => {
      dispatch(actions.reviewFeature({ id }));
    })
    .catch((error) => {
      // error.clientMessage = "Can't approve comment";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
//feature review
export const unFeatureReview = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .unFeatureReview({
      ids: [id],
    })
    .then((response) => {
      dispatch(actions.reviewUnfeature({ id }));
    })
    .catch((error) => {
      // error.clientMessage = "Can't approve comment";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchUniByNameSearch = (searchParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findAllUniversity(searchParams)
    .then((response) => {
      let uniOptions = response.data.results.map((item) => {
        return {
          label: item.universityName,
          value: item.universityId,
        };
      });
      //uniOptions = [{ label: "Select...", value: "" }, ...uniOptions];
      dispatch(actions.universityList({ allUniversityOptions: uniOptions }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete universities";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchStateByCountry = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .stateList(id)
    .then((response) => {
      let stateByCountry = response.data.map((item) => {
        return {
          label: item.name,
          value: item.stateId,
        };
      });
      dispatch(actions.stateListFetched({ stateOptions: stateByCountry }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find state";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchCityByState = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .cityList(id)
    .then((response) => {
      let cityByState = response.data.map((item) => {
        return {
          label: item.name,
          value: item.cityId,
        };
      });
      dispatch(actions.cityListFetched({ cityOptions: cityByState }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find State";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchAllUniStatus = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchAllUniversityStatus()
    .then((response) => {
      dispatch(
        actions.allUniStatusFetched({ allUniReviewStatus: response.data })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find Status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchSingleUniStatus = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchSingleUniversityStatus(id)
    .then((response) => {
      dispatch(
        actions.singleUniStatusFetched({ singleUniReviewStatus: response.data })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find Status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
// delete review
export const deleteUniversityReview = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteReview(id)
    .then((response) => {
      dispatch(actions.reviewDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete review";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
//feature review
export const trashReview = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .publishReview({
      universityReviewIds: id,
      status: "Deleted",
    })
    .then((response) => {
      dispatch(actions.reviewTrashed({ id }));
    })
    .catch((error) => {
      // error.clientMessage = "Can't approve comment";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const isTopUniversity = (data) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .topUniversity(data)
    .then((response) => {
      dispatch(actions.isTopUniversity({ data }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
