import React, { Suspense } from "react";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";
import EditCourse from "../Universities/courses/course-edit/EditCourse";
import { AllUniCourses } from "./AllUniCourses";

export default function Country() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact={true} from="/courses" to="/courses/all" />
        <ContentRoute from="/courses/all" component={AllUniCourses} />
        <ContentRoute from="/courses/:id/edit" component={EditCourse} />
      </Switch>
    </Suspense>
  );
}
