import { createSlice } from "@reduxjs/toolkit";

const initialFilesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  fileForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const filesSlice = createSlice({
  name: "files",
  initialState: initialFilesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getFileById
    fileFetched: (state, action) => {
      state.actionsLoading = false;
      state.fileForEdit = action.payload.fileForEdit;
      state.error = null;
    },

    // findFiles
    filesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createFile
    fileCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload[0]);
      return action.payload[0];
    },

    // updateFile
    fileUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.file.id) {
          return action.payload.file;
        }
        return entity;
      });
    },

    // deleteFile
    fileDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.mediaManagerId != action.payload.id
      );
    },

    // deleteFiles
    filesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    // delete Scholarship
    mediaHardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.mediaManagerId != action.payload.id
      );
    },
  },
});
