import { createSlice } from "@reduxjs/toolkit";

const initialUniState = {
  listLoading: false,
  actionsLoading: false,
  uniActionsLoading: false,
  totalCount: 0,
  entities: null,
  uniTotalCount: 0,
  uniEntities: null,
  lastError: null,
  eventForEdit: undefined,
  error: null,
  uniError: null,
  branchOptions: [],
  countryOptions: [],
  eventCategory: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const eventSlice = createSlice({
  name: "event",
  initialState: initialUniState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // geteventById
    eventFetched: (state, action) => {
      state.actionsLoading = false;
      state.eventForEdit = action.payload.eventForEdit;
      state.error = null;
    },

    // findevent
    allEventFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findevent
    allUniFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.uniActionsLoading = false;
      state.uniError = null;
      state.uniEntities = entities;
      state.uniTotalCount = totalCount;
    },

    // createevent
    eventCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },

    // updateevent
    eventUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter(
      //   (el) => el.id !== action.payload.id
      // );
    },

    // deleteevent
    eventStatusChanged: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    statusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.statusOverview = action.payload.statusOverview;
    },
    // deleteevent
    eventHardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    leadFormDropdownFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.leadFormOptions = action.payload.leadFormOptions;
    },
    counsellorFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.counsellorList = action.payload.counsellorList;
    },
    //fetch leads
    leadsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.allLeads = action.payload.allLeads;
    },
  },
});
