import React, { useRef, useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../app/modules/Media/_redux/files/filesAction";
import { Input, ModalProgressBar } from "../../../_metronic/_partials/controls";
import { swalConfirm, swalError } from "../../helpers";
import styles from "./uploadform.module.scss";

export const UploadForm = ({
  checkUpload,
  maxSize = 1000000,
  allowItem = "image",
  ...props
}) => {
  const btnRef = useRef();
  const dispatch = useDispatch();

  const [allowFormate, setAllowFormate] = useState([
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/webp",
    "image/gif",
    "image/svg+xml",
    "application/pdf",
  ]);

  const [names, setNames] = useState([]);
  const [altName, setAltName] = useState([]);
  const [tags, setTags] = useState([]);
  const [fileState, setFileState] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.files.actionsLoading,
    }),
    shallowEqual
  );

  //when change item and format
  useEffect(() => {
    if (allowItem && allowItem === "image") {
      setAllowFormate([
        "image/jpg",
        "image/jpeg",
        "image/png",
        "image/webp",
        "image/gif",
        "image/svg+xml",
      ]);
    }
    if (allowItem && allowItem === "video") {
      setAllowFormate(["video/mp4"]);
    }
    if (allowItem && allowItem === "pdf") {
      setAllowFormate(["application/pdf"]);
    }
  }, [allowItem]);

  //when change name
  const changeName = (e) => {
    let index = e.target.name.replace("file_name_", "");
    let namesArr = [...names];
    namesArr[index] = e.target.value;
    setNames(namesArr);
  };

  //when change alter tag
  const changeAlt = (e) => {
    let index = e.target.name.replace("alt_name_", "");
    let altNameArr = [...altName];
    altNameArr[index] = e.target.value;
    setAltName(altNameArr);
  };

  //remove item which upload
  const removeCurrent = (index) => {
    let fileArr = [...fileState];
    let namesArr = [...names];
    let tagsArr = [...tags];
    let altNameArr = [...altName];
    if (index !== -1) {
      fileArr.splice(index, 1);
      namesArr.splice(index, 1);
      tagsArr.splice(index, 1);
      altNameArr.splice(index, 1);
      setFileState(fileArr);
      setNames(namesArr);
      setAltName(altNameArr);
      setTags(tagsArr);
    }
  };

  //when remove
  const handleRemove = (index) => {
    swalConfirm("to remove this")
      .then((result) => {
        if (result.isConfirmed) {
          removeCurrent(index);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //when select file
  const onFileChange = (event) => {
    let namesArr = [...names];
    let tagsArr = [...tags];
    let fileArr = [...fileState];

    if (event.target.files && event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        // alert(event.target.files[i].type)
        // console.warn(event.target.files[i].type);

        if (
          allowFormate.includes(event.target.files[i].type) &&
          event.target.files[i].size / 1000 <= maxSize
        ) {
          let fname = event.target.files[i].name;
          let name = fname
            .split(".")
            .slice(0, -1)
            .join(".");
          namesArr.push(name);

          let tagName = event.target.files[i].type.split("/")[0];
          tagsArr.push(tagName);

          fileArr.push(event.target.files[i]);
        } else {
          alert(event.target.files[0].type);
          // swalError(
          //   "Upload file ( " + event.target.files[i].name + " ) is not allowed"
          // );
        }
      }
      setFileState(fileArr);
      setNames(namesArr);
      setAltName(namesArr);
      setTags(tagsArr);
    }
  };

  const createTable = () => {
    let table = [];
    if (fileState && fileState.length > 0) {
      for (let i = 0; i < fileState.length; i++) {
        if (allowFormate.indexOf(fileState[i].type) > -1) {
          table.push(
            <tr key={i}>
              <td className={styles.textcenter}>{i + 1}</td>
              <td>
                <input
                  name={`file_name_${i}`}
                  key={i}
                  onChange={changeName}
                  value={names[i]}
                  className={styles.fileName}
                />
              </td>
              <td>
                <input
                  name={`alt_name_${i}`}
                  key={i}
                  onChange={changeAlt}
                  value={altName[i]}
                  className={styles.fileName}
                />
              </td>
              <td>
                <span className="shorter">{fileState[i].type}</span>
              </td>

              <td className={styles.textcenter}>
                <button
                  className={styles.btn_remove}
                  onClick={() => handleRemove(i)}
                  value={i}
                  type="button"
                >
                  <i className="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          );
        } else {
          //remove state
          removeCurrent(i);
          //show alert
          swalError(fileState[i].type + " Not Allowed");
        }
      }
    }
    return table;
  };

  //upload file
  const saveFile = async (values) => {
    swalConfirm(
      "to upload this file",
      "Are You sure",
      "Yes Upload this file"
    ).then(async (rsp) => {
      if (rsp.isConfirmed) {
        //enable loading
        setIsLoading(true);

        //form initialize
        const formData = new FormData();
        formData.append("mediaName", names.toString());
        formData.append("alt", altName.toString());
        formData.append("tag", tags.toString());

        if (fileState.length > 0) {
          fileState.forEach((file, index) => {
            formData.append("files", file);
          });
        }

        //dispatch file upload action
        await dispatch(actions.createFile(formData))
          .then((resp) => {
            if (resp.error) {
              swalError(resp.data);
            }
            if (!resp.error) {
              if (checkUpload) {
                checkUpload(true);
              }
            }
          })
          .catch((err) => {
            if (checkUpload) {
              checkUpload(false);
            } else {
              swalError("Sorry!");
            }
          });
        //remove loading
        setIsLoading(false);
      }
    });
  };

  return (
    <div className="mt-5">
      {actionsLoading && <ModalProgressBar />}
      <div className="row">
        <div className="col-md-3">
          <div className={`form-group ${styles.files}`}>
            <div className={`${styles.inputPlace}`}>
              <div>
                <h5>Choose File </h5>
                <span>
                  {fileState && fileState.length + " Items Selected "}
                </span>
              </div>
            </div>
            <input
              type="file"
              name="files"
              component={Input}
              placeholder={"Select File"}
              label={false}
              multiple={true}
              className={`${styles.filesInput}`}
              onChange={onFileChange}
            />
          </div>
        </div>
        <div className="col-md-9">
          <table className={`table ${styles.uploadTable}`}>
            <thead>
              <tr>
                <th style={{ width: "40px" }}>SL</th>
                <th>New Name</th>
                <th>Alt Tag</th>
                <th>Type</th>
                <th style={{ width: "80px" }}>Action</th>
              </tr>
            </thead>
            <tbody>{createTable()}</tbody>
          </table>
          {fileState && fileState.length > 0 ? (
            <button
              ref={btnRef}
              type="submit"
              className="btn btn-primary"
              onClick={() => saveFile()}
              disabled={isLoading}
            >
              <span className="apsis-loader">
                {isLoading && (
                  <Spinner
                    animation="border"
                    style={{ color: "#fff" }}
                    size="sm"
                  />
                )}{" "}
                &nbsp; Upload Files
              </span>
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
export default UploadForm;
