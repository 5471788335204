import { createSlice } from "@reduxjs/toolkit";

const initialUniState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  counsellorForEdit: undefined,
  error: null,
  branchOptions: [],
  countryOptions: [],
  workableCountryOptions: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const counsellorSlice = createSlice({
  name: "counsellor",
  initialState: initialUniState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getcounsellorById
    counsellorFetched: (state, action) => {
      state.actionsLoading = false;
      state.counsellorForEdit = action.payload.counsellorForEdit;
      state.error = null;
    },

    // findcounsellor
    allCounsellorFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createcounsellor
    counsellorCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },

    // updatecounsellor
    counsellorUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter(
      //   (el) => el.id !== action.payload.id
      // );
    },

    // deletecounsellor
    counsellorStatusChanged: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },

    // fetch Category without children
    countryDropdownFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.countryOptions = action.payload.countryOptions;
    },
    // fetch Category without children
    workableCountryDropdownFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.workableCountryOptions = action.payload.workableCountryOptions;
    },
    // branchDropdown
    branchDropdownFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.branchOptions = action.payload.branchOptions;
    },
    statusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.statusOverview = action.payload.statusOverview;
    },
    // deletecounsellor
    counsellorHardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
  },
});
