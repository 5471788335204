import { createSlice } from "@reduxjs/toolkit";

const initialUniState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  studentForEdit: undefined,
  error: null,
  authorOptions: [],
  countryOptions: [],
  studentTemplateOptions: [],
  workingCountryOptions: [],
  studentTags: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const studentSlice = createSlice({
  name: "student",
  initialState: initialUniState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getstudentById
    studentFetched: (state, action) => {
      state.actionsLoading = false;
      state.studentForEdit = action.payload.studentForEdit;
      state.error = null;
    },

    // findstudent
    allStudentFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createstudent
    studentCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },

    // updatestudent
    studentUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter(
      //   (el) => el.id !== action.payload.id
      // );
    },

    // deletestudent
    studentStatusChanged: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },

    // fetch Category without children
    countryDropdownFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.countryOptions = action.payload.countryOptions;
    },
    // fetch Category without children
    workingCountryDropdownFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.workingCountryOptions = action.payload.workingCountryOptions;
    },
    // fetch Category without children
    highEduDropdownFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.educationOptions = action.payload.educationOptions;
    },
    statusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.statusOverview = action.payload.statusOverview;
    },
    // deletestudent
    studentHardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // Fetch Courses Level
    specializationFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.specializations = action.payload.specializations;
    },
    // Fetch Courses Level
    departmentFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.departments = action.payload.departments;
    },
    // get Prerequisit by Level Id
    allExamsFetched: (state, action) => {
      state.actionsLoading = false;
      state.exams = action.payload.exams;
      state.error = null;
    },
  },
});
