import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Card, CardBody, CardHeader } from "../controls";
import { getAPI } from "../../../_unisearch/util/authUtils";
import { swalError, WEB_LINK } from "../../../_unisearch/helpers";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
export function VisitorChart({ timeValue, dateChange, props }) {
  const siteURL = process.env.REACT_APP_GRAPH_URL;
  const graphSecret = process.env.REACT_APP_ANALYSIS_SECRET_KEY;

  const [graphData, setGraphData] = useState({
    interval: "month",
    labels: [],
    plot: [],
  });
  const [currentVisitor, setCurrentVisitor] = useState();
  useEffect(() => {
    let unmount = false;
    const fetchCountryDataList = async () => {
      try {
        if (unmount) return;
        await getAPI(
          `https://analytics.myunisearch.com/api/stats/${siteURL}/main-graph?period=${timeValue}&date=${dateChange}&filters={}&auth=${graphSecret}`
        ).then((res) => {
          if (!res.error) {
            setGraphData(res);
          }
          if (res.error) {
            swalError(res.data);
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    const fetchCurrentVisitor = async () => {
      try {
        if (unmount) return;
        await getAPI(
          `https://analytics.myunisearch.com/api/stats/${siteURL}/current-visitors?auth=${graphSecret}`
        ).then((res) => {
          if (!res.error) {
            setCurrentVisitor(res);
          }
          if (res.error) {
            swalError(res.data);
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchCurrentVisitor();
    fetchCountryDataList();
    return () => {
      unmount = true;
    };
  }, [timeValue]);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };
  const data = {
    labels: graphData.labels || [],
    datasets: [
      {
        label: "Visitors",
        data: graphData.plot || [],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  return (
    <Card>
      <CardHeader title={`Visitors (${currentVisitor} Current visitor)`} />
      <CardBody>
        <div className="chart_info_lists">
          {graphData &&
            graphData?.top_stats?.length > 0 &&
            graphData?.top_stats.map((visitor, i) => {
              return (
                <div className="chart_info_item" key={`${i}visitor`}>
                  <span className="chart_info_item_title">{visitor?.name}</span>
                  <div className="chart_info_item_num_wrapper">
                    <span className="chart_info_item_num">
                      {visitor?.value ? visitor.value : "0"}
                    </span>
                    <div className="num_percentage_wrapper">
                      <div className="num_percentage_wrapper">
                        {visitor?.change ? (
                          <>
                            {visitor?.change > 0 ? (
                              <i
                                style={{ color: "#27ae60" }}
                                className="fa fa-arrow-up"
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <i
                                style={{ color: "#eb5757" }}
                                className="fa fa-arrow-down"
                                aria-hidden="true"
                              ></i>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        <div className="num_percentage">
                          {visitor?.change ? <>{visitor?.change}%</> : `~N/A`}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="chart_graph_wrapper">
          <Line options={options} data={data} />
        </div>
      </CardBody>
    </Card>
  );
}
