import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import AllUniversityCourse from "./modules/Course";
import AllScholarship from "./modules/Scholarship";
import { AllUniScholarships } from "./modules/Scholarship/AllUniScholarship";
import { DashboardPage } from "./pages/DashboardPage";
import { MyPage } from "./pages/MyPage";

//profile
const UserProfile = lazy(() => import("./modules/UserProfile/UserProfilePage"));

//add country Module
const CountryModule = lazy(() => import("./modules/Countries"));
const UniversityModule = lazy(() => import("./modules/Universities"));
const ExamModule = lazy(() => import("./modules/Exams"));
// const DocumentationModule = lazy(() => import("./modules/Documentation"));
const BlogModule = lazy(() => import("./modules/Blog"));
const CounsellorModule = lazy(() => import("./modules/Counsellor"));
const AgentModule = lazy(() => import("./modules/Agent"));
const MentorModule = lazy(() => import("./modules/Mentor"));
const StudentModule = lazy(() => import("./modules/Student"));
const AbroadModule = lazy(() => import("./modules/Abroad"));
const SettingsModule = lazy(() => import("./modules/Settings"));
const CareerModule = lazy(() => import("./modules/Career"));
const PageModule = lazy(() => import("./modules/pages"));
const DocumentsModule = lazy(() => import("./modules/Documents"));
const TeamsModule = lazy(() => import("./modules/Teams"));
const NewsModule = lazy(() => import("./modules/News"));
const CommentsModule = lazy(() => import("./modules/Comments"));
const VlogModule = lazy(() => import("./modules/VideoBlog"));
const ForumModule = lazy(() => import("./modules/Forum"));
const LeadContactModule = lazy(() => import("./modules/LeadContacts"));
const TestimonialModule = lazy(() => import("./modules/Testimonial"));
const AllScholarshipModule = lazy(() => import("./modules/Scholarship"));
const AllRankingModule = lazy(() => import("./modules/Ranking"));
const AllVfairModule = lazy(() => import("./modules/Vfair"));
const AllFormModule = lazy(() => import("./modules/Form"));

const CounselingModule = lazy(() => import("./modules/Counseling"));
const FormModule = lazy(() => import("./modules/FormBuilder"));

//helpful
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);

const TestModule = lazy(() => import("./modules/Test"));
const MediaModule = lazy(() => import("./modules/Media/files/FilesPage"));
const keywordModule = lazy(() => import("./modules/Keywords"));
const CounsellorMeetingModule = lazy(() =>
  import("./modules/CounsellorMeeting/meeting")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/user-profile" component={UserProfile} />

        {/* country */}
        <Route path="/country" component={CountryModule} />
        <Route path="/university" component={UniversityModule} />
        <Route path="/page" component={PageModule} />
        <Route path="/exam" component={ExamModule} />
        <Route path="/documents" component={DocumentsModule} />
        <Route path="/comments" component={CommentsModule} />

        {/* <Route path="/documentation" component={DocumentationModule} /> */}
        <Route path="/blog" component={BlogModule} />
        <Route path="/abroad" component={AbroadModule} />

        <Route path="/counsellors" component={CounsellorModule} />
        <Route path="/agent" component={AgentModule} />
        <Route path="/mentors" component={MentorModule} />
        <Route path="/students" component={StudentModule} />

        {/* Unisearch */}
        <Route path="/career" component={CareerModule} />
        <Route path="/teams" component={TeamsModule} />
        <Route path="/counseling" component={CounselingModule} />
        <Route path="/testimonial" component={TestimonialModule} />

        {/* settings */}
        <Route path="/settings" component={SettingsModule} />
        <Route path="/media" component={MediaModule} />
        <Route path="/news" component={NewsModule} />
        <Route path="/news" component={NewsModule} />
        <Route path="/vlog" component={VlogModule} />
        <Route path="/forum" component={ForumModule} />
        <Route path="/keywords" component={keywordModule} />
        <Route path="/leads-form" component={LeadContactModule} />
        <Route path="/form" component={AllFormModule} />
        {/* <Route path="/form-build" component={FormModule} /> */}
        <Route path="/courses" component={AllUniversityCourse} />
        <Route path="/scholarship" component={AllScholarshipModule} />
        <Route path="/ranking" component={AllRankingModule} />
        <Route path="/vfair" component={AllVfairModule} />
        <Route path="/meeting" component={CounsellorMeetingModule} />

        {/* un-Used route */}
        <Route path="/test" component={TestModule} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />

        {/* Redirecft Routes */}
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
