// import axios from "axios";
import {
  getAPI,
  patchAPI,
  postAPI,
  deleteAPI,
} from "../../../../../../_unisearch/util/authUtils";

const API_ENDPOINTS = "/admin/university-scholarship";

// Create  new Scholarship
export function createScholarship(data) {
  return postAPI(API_ENDPOINTS, data);
}

export function getAllScholarship(uniId, queryParams) {
  const { filter, pageNumber, pageSize, sortField, sortOrder } = queryParams;

  return postAPI(`${API_ENDPOINTS}/filter-list/${uniId}`, {
    filter: filter,
    pageNumber: pageNumber,
    pageSize: pageSize,
    sortField: sortField,
    sortOrder: sortOrder,
  });
}

//get sigle Scholarship
export function getScholarshipById(id) {
  return getAPI(`${API_ENDPOINTS}/${id}`);
}
//get specialization
export function getSpecialization() {
  return getAPI(`/public/specialization`);
}
//get specialization
export function getLevel() {
  return getAPI(`/study/course/level`);
}

// UPDATE Scholarship by ID
export function updateScholarship(data, id) {
  return patchAPI(`${API_ENDPOINTS}/${id}`, data);
}

// DELETE  Scholarship information from server
export function deleteScholarship(id) {
  return patchAPI(`${API_ENDPOINTS}`, id);
}
// DELETE  Scholarship information from server
export function hardDeleteScholarship(id) {
  return deleteAPI(`${API_ENDPOINTS}/${id}`);
}
export function fetchParentScholarship() {
  return getAPI(`vlog-scholarship/dropdown`);
}

export function fetchStatus(uniId) {
  return getAPI(`${API_ENDPOINTS}/status-overview/${uniId}`);
}
