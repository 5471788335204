/* eslint-disable react-hooks/exhaustive-deps */
import { Field } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import Swal from "sweetalert2";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Select,
} from "../../../../../../_metronic/_partials/controls";
import { TuitionFor } from "../../CourseUIHelpers";

export const TutionFeesComponent = ({
  data,
  setFieldValue,
  values,
  errors,
  touched,
  ...props
}) => {
  const [feesArray, setFeeseArray] = useState([]);

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const list = [...feesArray];
        list.splice(index, 1);
        setFeeseArray(list);

        //value update when remove rows
        let newvalues = [...values.tuitionFee];
        newvalues.splice(index, 1);
        setFieldValue("tuitionFee", newvalues);

        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setFeeseArray([
      ...feesArray,
      {
        tuitionFor: "",
        amountFrom: "",
        amountTo: "",
        numberOfCredit: "",
      },
    ]);

    const newValues = [
      ...values.tuitionFee,
      {
        tuitionFor: "",
        amountFrom: "",
        amountTo: "",
        numberOfCredit: "",
      },
    ];
    setFieldValue("tuitionFee", newValues);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      setFeeseArray(data);
    } else {
      setFeeseArray([
        {
          tuitionFor: "",
          amountFrom: "",
          amountTo: "",
          numberOfCredit: "",
        },
      ]);
    }
  }, [data]);
  return (
    <Fragment>
      <Card>
        <CardHeader title="Tuition & Fees" />
        <CardBody>
          <div className="row">
            <div className="col-md-5">
              <div className="form-group">
                <Field
                  name="applicationFee"
                  type="number"
                  className={`form-control ${
                    errors.applicationFee && touched.applicationFee
                      ? "is-invalid"
                      : ""
                  }`}
                  component={Input}
                  placeholder="Application Fees"
                  label="Application Fees"
                  autoComplete="off"
                />
              </div>
            </div>
          </div>

          {feesArray.map((fees, i) => {
            const feesErrors =
              (errors?.tuitionFee?.length && errors?.tuitionFee[i]) || {};
            const feesTouched =
              (touched?.tuitionFee?.length && touched?.tuitionFee[i]) || {};
            return (
              <div className="row" key={`fees_${i}`}>
                <div className="col-md-3">
                  <div className="form-group">
                    <Select
                      name={`tuitionFee.${i}.tuitionFor`}
                      className={`form-control ${
                        feesErrors.tuitionFor && feesTouched.tuitionFor
                          ? "is-invalid"
                          : ""
                      }`}
                      label="Tuition For"
                    >
                      {TuitionFor &&
                        TuitionFor.map((year, index) => (
                          <option key={`year_${index}`} value={year.value}>
                            {year.label}
                          </option>
                        ))}
                    </Select>
                    {feesErrors.tuitionFor && feesTouched.tuitionFor ? (
                      <div className="invalid-feedback">
                        {feesErrors.tuitionFor}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <Field
                      name={`tuitionFee.${i}.amountFrom`}
                      className={` form-control ${
                        feesErrors.amountFrom && feesTouched.amountFrom
                          ? "is-invalid"
                          : ""
                      }`}
                      type="number"
                      onChange={(e) =>
                        setFieldValue(
                          `tuitionFee.${i}.amountFrom`,
                          parseInt(e.target.value)
                        )
                      }
                      component={Input}
                      placeholder="Amount From"
                      label="Amount From"
                      autoComplete="off"
                    />
                    {feesErrors.amountFrom && feesTouched.amountFrom ? (
                      <div className="invalid-feedback">
                        {feesErrors.amountFrom}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <Field
                      name={`tuitionFee.${i}.amountTo`}
                      className={` form-control ${
                        feesErrors.amountTo && feesTouched.amountTo
                          ? "is-invalid"
                          : ""
                      }`}
                      component={Input}
                      onChange={(e) =>
                        setFieldValue(
                          `tuitionFee.${i}.amountTo`,
                          parseInt(e.target.value)
                        )
                      }
                      type="number"
                      placeholder="Amount To"
                      label="Amount To"
                      autoComplete="off"
                    />
                    {feesErrors.amountTo && feesTouched.amountTo ? (
                      <div className="invalid-feedback">
                        {feesErrors.amountTo}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <Field
                      name={`tuitionFee.${i}.numberOfCredit`}
                      component={Input}
                      className={`form-control ${
                        feesErrors.numberOfCredit && feesTouched.numberOfCredit
                          ? "is-invalid"
                          : ""
                      }`}
                      type="number"
                      placeholder="Credit"
                      label="Credit"
                      autoComplete="off"
                    />
                    {feesErrors.numberOfCredit && feesTouched.numberOfCredit ? (
                      <div className="invalid-feedback">
                        {feesErrors.numberOfCredit}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-2 pt-6">
                  {feesArray.length !== 1 && (
                    <button
                      type="button"
                      className="btn"
                      onClick={() => handleRemoveClick(i)}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </span>
                    </button>
                  )}
                  {feesArray.length - 1 === i && (
                    <button
                      type="button"
                      className="btn"
                      onClick={handleAddClick}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default TutionFeesComponent;
