/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { useSelector } from "react-redux";
import objectPath from "object-path";
import React, { useMemo, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { Button, Modal } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import { Input } from "../../../../_partials/controls";
import * as Yup from "yup";
import { postAPI } from "../../../../../_unisearch/util/authUtils";
import { swalError, swalSuccess } from "../../../../../_unisearch/helpers";
export function UserProfileDropdown() {
  const { user } = useSelector((state) => state.auth);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);
  const updatePassword = async (values) => {
    await postAPI("auth/change-password", values)
      .then((res) => {
        if (!res.error) {
          swalSuccess(res.message);
          handleClose();
          history.push("/logout");
        }
        if (res.error) {
          swalError(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
        handleClose();
      });
  };

  const [show, setShow] = useState(false);
  const history = useHistory();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Field is required"),
    password: Yup.string()
      .min(6, "Must be more than or equal 6 characters")
      .required("Password is required")
      .matches(
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/,
        "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    passwordConfirm: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });
  return (
    <>
      <Modal dialogClassName="modal-40w" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            oldPassword: "",
            password: "",
            passwordConfirm: "",
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            updatePassword(values);
          }}
        >
          {({ handleSubmit, values, setFieldValue, errors, touched }) => (
            <Form className="form">
              <Modal.Body>
                <>
                  <div className="form-group change_password_alert">
                    <p>
                      <i
                        className="fa fa-exclamation-circle error text-sm"
                        aria-hidden="true"
                      ></i>
                      {"  "}
                      After you udpate your password, you will be logged out and
                      asked to login.
                    </p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="oldPassword">
                      Old Password <span className="error">*</span>
                    </label>
                    <Field
                      name="oldPassword"
                      component={Input}
                      value={values?.oldPassword || ""}
                      placeholder="Old Password"
                      type="password"
                      autoComplete="off"
                      onChange={(e) => {
                        setFieldValue("oldPassword", e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">
                      New Password <span className="error">*</span>
                    </label>
                    <Field
                      name="password"
                      component={Input}
                      type="password"
                      value={values?.password || ""}
                      placeholder="New Password"
                      autoComplete="off"
                      onChange={(e) => {
                        setFieldValue("password", e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="passwordConfirm">
                      Confirm Password <span className="error">*</span>
                    </label>
                    <Field
                      name="passwordConfirm"
                      component={Input}
                      type="password"
                      value={values?.passwordConfirm || ""}
                      placeholder="Old Password"
                      autoComplete="off"
                      onChange={(e) => {
                        setFieldValue("passwordConfirm", e.target.value);
                      }}
                    />
                  </div>
                </>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    // saveUpdate(values);
                    handleSubmit();
                    // handleClose();
                  }}
                  type="submit"
                  className="btn btn-primary"
                >
                  Confirm Change
                </Button>
                <Button
                  variant="secondary"
                  onClick={(event) => {
                    handleClose();
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="dropdown-toggle-user-profile"
        >
          <div
            className={
              "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
            }
          >
            <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
              Hi,
            </span>{" "}
            <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
              {user.name}
            </span>
            <span className="symbol symbol-35 symbol-light-success">
              <span className="symbol-label font-size-h5 font-weight-bold">
                <i className="fa fa-caret-down"></i>
              </span>
            </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          <>
            {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
            {layoutProps.light && (
              <>
                <div className="d-flex align-items-center p-8 rounded-top">
                  <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                    <img
                      src={toAbsoluteUrl("/media/users/300_21.jpg")}
                      alt=""
                    />
                  </div>
                  <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                    {/* {user.firstname} {user.lastname} */}
                    shamim
                  </div>
                  <span className="label label-light-success label-lg font-weight-bold label-inline">
                    3 messages
                  </span>
                </div>
                <div className="separator separator-solid"></div>
              </>
            )}

            {!layoutProps.light && (
              <div
                className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl(
                    "/media/misc/bg-1.jpg"
                  )})`,
                }}
              >
                <div className="symbol bg-white-o-15 mr-3">
                  <img
                    alt="Pic"
                    className="hidden"
                    src={user.profileImageSrc || "/media/logos/logo-light.svg"}
                  />
                </div>
                <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                  {user.name}
                </div>
                {/* <span className="label label-success label-lg font-weight-bold label-inline">
                3 messages
              </span> */}
              </div>
            )}
          </>

          <div className="navi navi-spacer-x-0 pt-5">
            {/* <Link to="/user-profile" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  My Profile
                </div>
                <div className="text-muted">
                  Account settings and more
                  <span className="label label-light-danger label-inline font-weight-bold">
                    update
                  </span>
                </div>
              </div>
            </div>
          </Link> */}

            {/* <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-mail text-warning"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Messages</div>
                <div className="text-muted">Inbox and tasks</div>
              </div>
            </div>
          </a> */}

            {/* <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-rocket-1 text-danger"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Activities</div>
                <div className="text-muted">Logs and notifications</div>
              </div>
            </div>
          </a> */}

            {/* <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-hourglass text-primary"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Tasks</div>
                <div className="text-muted">latest tasks and projects</div>
              </div>
            </div>
          </a> */}
            <div className="navi-separator mt-3"></div>

            <div className="navi-footer  px-8 py-5">
              <span style={{ cursor: "pointer" }} onClick={handleShow}>
                Change Password
              </span>
            </div>
            <div className="navi-separator"></div>

            <div className="navi-footer  px-8 py-5">
              <Link
                to="/logout"
                className="btn btn-light-primary font-weight-bold"
              >
                Sign Out
              </Link>
              {/* <a href="#" className="btn btn-clean font-weight-bold">
              Upgrade Plan
            </a> */}
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
