/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment } from "react";
import { Pagination } from "react-bootstrap";

export const UniPager = ({
  total = 1,
  perpage = 1,
  current,
  children,
  pageClicked,
  ...props
}) => {
  const [pageArray, setPageArray] = useState([]);

  useEffect(() => {
    var totPages = Math.ceil(total / perpage);
    var currentPage = parseInt(current);
    var pageArr = [];

    if (totPages > 1) {
      if (totPages <= 9) {
        var i = 1;
        while (i <= totPages) {
          pageArr.push(i);
          i++;
        }
      } else {
        if (currentPage <= 5) pageArr = [1, 2, 3, 4, 5, 6, 7, 8, "", totPages];
        else if (totPages - currentPage <= 4)
          pageArr = [
            1,
            "",
            totPages - 7,
            totPages - 6,
            totPages - 5,
            totPages - 4,
            totPages - 3,
            totPages - 2,
            totPages - 1,
            totPages,
          ];
        else
          pageArr = [
            1,
            "",
            currentPage - 3,
            currentPage - 2,
            currentPage - 1,
            currentPage,
            currentPage + 1,
            currentPage + 2,
            currentPage + 3,
            "",
            totPages,
          ];
      }
    }
    setPageArray(pageArr);
  }, [total, current]);

  return (
    <Fragment>
      {children}
      <div style={{ marginTop: "15px" }}>
        <Pagination style={{ justifyContent: "center" }}>
          {pageArray.map((ele, ind) => {
            const toReturn = [];

            if (ind === 0) {
              toReturn.push(
                <Pagination.First
                  key={"firstpage"}
                  onClick={
                    parseInt(current) === 1
                      ? () => {}
                      : () => {
                          pageClicked(1);
                        }
                  }
                />
              );

              toReturn.push(
                <Pagination.Prev
                  key={"prevpage"}
                  onClick={
                    parseInt(current) === 1
                      ? () => {}
                      : () => {
                          pageClicked(parseInt(current) - 1);
                        }
                  }
                />
              );
            }

            if (ele === "") toReturn.push(<Pagination.Ellipsis key={ind} />);
            else
              toReturn.push(
                <Pagination.Item
                  key={ind}
                  active={parseInt(current) === ele ? true : false}
                  onClick={
                    parseInt(current) === ele
                      ? () => {}
                      : () => {
                          pageClicked(ele);
                        }
                  }
                >
                  {ele}
                </Pagination.Item>
              );

            if (ind === pageArray.length - 1) {
              toReturn.push(
                <Pagination.Next
                  key={"nextpage"}
                  onClick={
                    parseInt(current) === ele
                      ? () => {}
                      : () => {
                          pageClicked(parseInt(current) + 1);
                        }
                  }
                />
              );

              toReturn.push(
                <Pagination.Last
                  key={"lastpage"}
                  onClick={
                    parseInt(current) === ele
                      ? () => {}
                      : () => {
                          pageClicked(ele);
                        }
                  }
                />
              );
            }

            return toReturn;
          })}
        </Pagination>
      </div>
    </Fragment>
  );
};

export default UniPager;
