import { createSlice } from "@reduxjs/toolkit";

const uniBlogState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  blogForEdit: undefined,
  lastError: null,
  blogCategory: [],
  blogTag: [],
  authorOptions: [],
  statusOverview: {},
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const uniBlogSlice = createSlice({
  name: "uniBlogs",
  initialState: uniBlogState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getBlogById
    blogFetched: (state, action) => {
      state.actionsLoading = false;
      state.blogForEdit = action.payload.blogForEdit;
      state.error = null;
    },

    // create Blog
    blogCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.blog);
    },

    // find blogs
    blogsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;

      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // all category
    categoryFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.blogCategory = action.payload.blogCategory;
    },

    // all category
    tagFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.blogTag = action.payload.blogTag;
    },

    // update blog
    blogUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },

    //delete blog
    blogDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },

    //delete blog
    blogHardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },

    //fetch author
    authorFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.authorOptions = action.payload.authorOptions;
    },
    statusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.statusOverview = action.payload.statusOverview;
    },

    // Decline blog
    blogUnpublish: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // Approve blog
    blogPublish: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
  },
});
