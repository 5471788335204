/* eslint-disable array-callback-return */
import { swalError, swalSuccess } from "../../../../../_unisearch/helpers";
import * as requestFromServer from "./scholarshipCrud";
import { allScholarshipsSlice, callTypes } from "./scholarshipSlice";

const { actions } = allScholarshipsSlice;

//fetch all scholarship
export const fetchScholarships = (queryParams) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.findScholarships(queryParams);
    const { total, results, option } = response.data;
    dispatch(
      actions.scholarshipsFetched({
        totalCount: total,
        entities: results,
        filteredStatusOverview: option,
      })
    );
  } catch (errors) {
    let responseErr = { ...errors, clientMessage: "Can't find Scholarship" };
    dispatch(actions.catchError({ responseErr, callType: callTypes.list }));
  }
};

//delte single scholarship
export const changeStatusScholarship = (data) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    await requestFromServer.statusChangeScholarship(data);
    dispatch(actions.scholarshipDeleted({ data }));
  } catch (errors) {
    console.log(errors);
    dispatch(actions.catchError({ callType: callTypes.action }));
  }
};
//delte single scholarship
export const hardDeleteScholarship = (id) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.deleteScholarships(id);
    dispatch(actions.scholarshipHardDeleted({ id }));
  } catch (errors) {
    let responseErr = { ...errors, clientMessage: "Can't delete Scholarship" };
    dispatch(actions.catchError({ responseErr, callType: callTypes.action }));
  }
};

//get all scholarship level
export const fetchScholarshipLevel = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.getAllScholarshipLevel();
    dispatch(
      actions.scholarshipLevelFetched({ scholarshipLevel: response.data })
    );
  } catch (errors) {
    console.log(errors);
    dispatch(actions.catchError({ callType: callTypes.action }));
  }
};
export const fetchUniDropdownList = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.fetchUnniDropdown();
    const uniDropdown = response.data.results
      ? response.data.results.map((uni) => {
          return {
            value: uni.universityId,
            label: uni.universityName,
          };
        })
      : [];
    dispatch(actions.uniDropdownFetch({ uniDropdown: uniDropdown }));
  } catch (errors) {
    let responseErr = {
      ...errors,
      clientMessage: "Can't find Scholarships Level",
    };
    dispatch(actions.catchError({ responseErr, callType: callTypes.action }));
  }
};

export const fetchDepartment = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.getDepartment();
    let departments = response.data
      ? response.data.map((sp) => {
          return {
            value: parseInt(sp.specializationId),
            label: sp.specializationName,
          };
        })
      : [];
    dispatch(actions.departmentFetched({ departments }));
  } catch (errors) {
    let responseErr = {
      ...errors,
      clientMessage: "Can't find Specialization",
    };
    dispatch(actions.catchError({ responseErr, callType: callTypes.action }));
  }
};

//fetch country
export const fetchAllWorkingCountryDropdown = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchWorkingCountryDropdown()
    .then((response) => {
      let result = response.data;
      const options = result.map((item) => {
        return {
          label: item.name,
          value: item.countryId,
        };
      });
      dispatch(
        actions.workingCountryDropdownFetched({
          workingCountryOptions: options,
        })
      );
    })
    .catch((error) => {
      swalError(error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
//fetch status overvire
export const fetchStatus = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.fetchStatus();
    dispatch(actions.statusFetched({ statusOverview: response.data }));
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};
