/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useState, Fragment, useEffect } from "react";
import { useHistory, useLocation, NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_metronic/_helpers";
import { encryptStorage } from "../../../../_unisearch/helpers";

export function UniversityMenu({ layoutProps }) {
  let history = useHistory();
  const location = useLocation();

  const [slug, setSlug] = useState();
  const uniInfo = encryptStorage.getItem("uniProfile");

  useEffect(() => {
    if (!uniInfo || !uniInfo.slug) {
      history.push("/university");
    } else {
      setSlug(uniInfo.slug);
    }
  }, []);

  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  return (
    <Fragment>
      {/*begin::1 Level*/}
      <li
        className={`menu-item menu-item-rel ${getMenuItemActive(
          `/university/${slug}/dashboard`
        )}`}
      >
        <NavLink className="menu-link" to={`/university/${slug}/dashboard`}>
          <span className="menu-text">Dashboard</span>
          {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
        </NavLink>
      </li>
      <li
        className={`menu-item menu-item-rel ${getMenuItemActive(
          `/university/${slug}/contact`
        )}`}
      >
        <NavLink className="menu-link" to={`/university/${slug}/contacts`}>
          <span className="menu-text">Contact</span>
          {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
        </NavLink>
      </li>

      {/* Pages */}
      <li
        data-menu-toggle={layoutProps.menuDesktopToggle}
        aria-haspopup="true"
        className={`menu-item menu-item-submenu menu-item-rel 
        ${getMenuItemActive(`/university/${slug}/page`)}`}
      >
        <NavLink
          className="menu-link menu-toggle"
          to={`/university/${slug}/page`}
        >
          <span className="menu-text">Pages</span>
          <i className="menu-arrow"></i>
        </NavLink>
        <div className="menu-submenu menu-submenu-classic menu-submenu-left">
          <ul className="menu-subnav">
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/page/overview/highlights`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}/page/overview/highlights`}
              >
                <span className="menu-text">Page Overview</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/page/overview/contact`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}/page/overview/contact`}
              >
                <span className="menu-text">Contact Overview</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/page/overview/ranking`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}/page/overview/ranking`}
              >
                <span className="menu-text">Ranking Overview</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/page/overview/course`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}/page/overview/course`}
              >
                <span className="menu-text">Course Overview</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/page/admission-faq`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}/page/admission-faq`}
              >
                <span className="menu-text">Admission FAQ</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </li>
      {/* pages end */}

      {/*begin::1 Level*/}
      {/* <li
        className={`menu-item menu-item-rel ${getMenuItemActive(
          `/university/${slug}/conversation`
        )}`}
      >
        <NavLink className="menu-link" to={`/university/${slug}/conversation`}>
          <span className="menu-text">Conversation</span>
          {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
        </NavLink>
      </li> */}
      {/*end::1 Level*/}

      {/*begin::1 Level*/}
      {/*    <li
        className={`menu-item menu-item-rel ${getMenuItemActive(
          `/university/${slug}/application/all`
        )}`}
      >
        <NavLink
          className="menu-link"
          to={`/university/${slug}/application/all`}
        >
          <span className="menu-text">Application</span>
          {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
        </NavLink>
      </li> */}
      {/*end::1 Level*/}

      {/* Course */}
      <li
        data-menu-toggle={layoutProps.menuDesktopToggle}
        aria-haspopup="true"
        className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
          `/university/${slug}/course`
        )}`}
      >
        <NavLink
          className="menu-link menu-toggle"
          to={`/university/${slug}/course`}
        >
          <span className="menu-text">Courses</span>
          <i className="menu-arrow"></i>
        </NavLink>

        <div className="menu-submenu menu-submenu-classic menu-submenu-left">
          <ul className="menu-subnav">
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/course/all`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}/course/all`}
              >
                <span className="menu-text">All Courses</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/course/new`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}/course/new`}
              >
                <span className="menu-text">New Course</span>
              </NavLink>
            </li>

            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                `/university/${slug}/course/scholarship`
              )}`}
              data-menu-toggle="hover"
              aria-haspopup="true"
            >
              <NavLink
                className="menu-link menu-toggle"
                to={`/university/${slug}/course/scholarship`}
              >
                <span className="menu-text">Scholarship</span>
                <i className="menu-arrow" />
              </NavLink>
              <div
                className={`menu-submenu menu-submenu-classic menu-submenu-right`}
              >
                <ul className="menu-subnav">
                  {/*begin::3 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      `/university/${slug}/course/scholarship/all`
                    )}`}
                  >
                    <NavLink
                      className="menu-link"
                      to={`/university/${slug}/course/scholarship/all`}
                    >
                      {/* <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i> */}
                      <span className="menu-text">All Scholarship</span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}

                  {/*begin::3 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      `/university/${slug}/course/scholarship/new`
                    )}`}
                  >
                    <NavLink
                      className="menu-link"
                      to={`/university/${slug}/course/scholarship/new`}
                    >
                      {/* <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i> */}
                      <span className="menu-text">Add New</span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </li>
      {/* Course end */}

      {/* Event */}
      <li
        data-menu-toggle={layoutProps.menuDesktopToggle}
        aria-haspopup="true"
        className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
          `/university/${slug}/event`
        )}`}
      >
        {/* <NavLink
          className="menu-link menu-toggle"
          to={`/university/${slug}/event`}
        >
          <span className="menu-text">Event</span>
          <i className="menu-arrow"></i>
        </NavLink> */}
        <div className="menu-submenu menu-submenu-classic menu-submenu-left">
          <ul className="menu-subnav">
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/event/all`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}/event/all`}
              >
                <span className="menu-text">All Events</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/event/new`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}event/new`}
              >
                <span className="menu-text">New Event</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </li>
      {/* event end */}

      {/* start */}
      {/*begin::1 Level*/}
      <li
        data-menu-toggle={layoutProps.menuDesktopToggle}
        aria-haspopup="true"
        className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
          `/university/${slug}/blog`
        )}`}
      >
        {/* <NavLink
          className="menu-link menu-toggle"
          to={`/university/${slug}/blog`}
        >
          <span className="menu-text menu-link menu-toggle">Blog</span>
          <i className="menu-arrow"></i>
        </NavLink> */}

        <div className="menu-submenu menu-submenu-classic menu-submenu-left">
          <ul className="menu-subnav">
            {/*begin::2 Level*/}
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/blog/all`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}/blog/all`}
              >
                <span className="menu-text">All Post</span>
              </NavLink>
            </li>
            {/*end::2 Level*/}
            {/*begin::2 Level*/}
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/blog/new`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}/blog/new`}
              >
                <span className="menu-text">Add Post</span>
              </NavLink>
            </li>
            {/*end::2 Level*/}

            {/*begin::2 Level*/}
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                `/university/${slug}/blog/category`
              )}`}
              data-menu-toggle="hover"
              aria-haspopup="true"
            >
              <NavLink
                className="menu-link menu-toggle"
                to={`/university/${slug}/blog/category`}
              >
                <span className="menu-text">Category</span>
                <i className="menu-arrow" />
              </NavLink>
              <div
                className={`menu-submenu menu-submenu-classic menu-submenu-right`}
              >
                <ul className="menu-subnav">
                  {/*begin::3 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      `/university/${slug}/blog/category/all`
                    )}`}
                  >
                    <NavLink
                      className="menu-link"
                      to={`/university/${slug}/blog/category/all`}
                    >
                      {/* <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i> */}
                      <span className="menu-text">All Category</span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}

                  {/*begin::3 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      `/university/${slug}/blog/category/new`
                    )}`}
                  >
                    <NavLink
                      className="menu-link"
                      to={`/university/${slug}/blog/category/new`}
                    >
                      {/* <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i> */}
                      <span className="menu-text">Add New</span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}
                </ul>
              </div>
            </li>
            {/*end::2 Level*/}
          </ul>
        </div>
      </li>
      {/*end::1 Level*/}
      {/* end */}

      {/* gallery start */}
      <li
        data-menu-toggle={layoutProps.menuDesktopToggle}
        aria-haspopup="true"
        className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
          `/university/${slug}/album`
        )}`}
      >
        <NavLink
          className="menu-link menu-toggle"
          to={`/university/${slug}/album`}
        >
          <span className="menu-text">Album</span>
          <i className="menu-arrow"></i>
        </NavLink>
        <div className="menu-submenu menu-submenu-classic menu-submenu-left">
          <ul className="menu-subnav">
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/album/all`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}/album/all`}
              >
                <span className="menu-text">All Album</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/album/new`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}/album/new`}
              >
                <span className="menu-text">New Album</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </li>
      {/* album end */}

      {/*begin::1 Level*/}
      {/* <li
        className={`menu-item menu-item-rel ${getMenuItemActive(
          `/university/${slug}/review`
        )}`}
      >
        <NavLink className="menu-link" to={`/university/${slug}/review`}>
          <span className="menu-text">Review</span>
          {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
        </NavLink>
      </li> */}
      {/*end::1 Level*/}

      {/*begin::1 Level*/}
      {/* <li
        className={`menu-item menu-item-rel ${getMenuItemActive(
          "/university/feedback"
        )}`}
      >
        <NavLink className="menu-link" to={`/university/${slug}/feedback`}>
          <span className="menu-text">Feedback</span>
          {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
        </NavLink>
      </li> */}
      {/*end::1 Level*/}

      {/*begin::1 Level*/}
      <li
        className={`menu-item menu-item-rel ${getMenuItemActive(
          `/university/${slug}/profile`
        )}`}
      >
        {/* <NavLink
          className="menu-link menu-toggle"
          to={`/university/${slug}/profile`}
        >
          <span className="menu-text">Profile</span>
          {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
        </NavLink> */}
        <div className="menu-submenu menu-submenu-classic menu-submenu-left">
          <ul className="menu-subnav">
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/profile/all`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}/profile/all`}
              >
                <span className="menu-text">All Admin</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/profile/new`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}/profile/new`}
              >
                <span className="menu-text">New Admin</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </li>
      {/*end::1 Level*/}

      {/* ranking */}
      <li
        data-menu-toggle={layoutProps.menuDesktopToggle}
        aria-haspopup="true"
        className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
          `/university/${slug}/ranking`
        )}`}
      >
        <NavLink
          className="menu-link menu-toggle"
          to={`/university/${slug}/ranking`}
        >
          <span className="menu-text">Ranking</span>
          <i className="menu-arrow"></i>
        </NavLink>
        <div className="menu-submenu menu-submenu-classic menu-submenu-left">
          <ul className="menu-subnav">
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/ranking/all`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}/ranking/all`}
              >
                <span className="menu-text">All Ranking</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/ranking/new`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}/ranking/new`}
              >
                <span className="menu-text">New Ranking</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </li>
      {/* ranking end */}
      {/* document start */}
      <li
        className={`menu-item menu-item-rel ${getMenuItemActive(
          `/university/${slug}/documents`
        )}`}
      >
        <NavLink className="menu-link" to={`/university/${slug}/documents`}>
          <span className="menu-text">Documents</span>
          {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
        </NavLink>
      </li>
      {/* documents end */}
      {/* user start */}
      <li
        className={`menu-item menu-item-rel ${getMenuItemActive(
          `/university/${slug}/reviews`
        )}`}
      >
        <NavLink className="menu-link" to={`/university/${slug}/reviews`}>
          <span className="menu-text">Reviews</span>
          {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
        </NavLink>
      </li>
      {/* blogs & blogs category : start */}
      <li
        data-menu-toggle={layoutProps.menuDesktopToggle}
        aria-haspopup="true"
        className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
          `/university/${slug}/blogs`
        )}`}
      >
        <NavLink
          className="menu-link menu-toggle"
          to={`/university/${slug}/blogs`}
        >
          <span className="menu-text"> Blogs </span>
          <i className="menu-arrow"></i>
        </NavLink>

        <div className="menu-submenu menu-submenu-classic menu-submenu-left">
          <ul className="menu-subnav">
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/blogs/all`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}/blogs/all`}
              >
                <span className="menu-text">All Blog</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/blogs/new`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}/blogs/new`}
              >
                <span className="menu-text">Add New</span>
              </NavLink>
            </li>

            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                `/university/${slug}/blogs/category`
              )}`}
              data-menu-toggle="hover"
              aria-haspopup="true"
            >
              <NavLink
                className="menu-link menu-toggle"
                to={`/university/${slug}/blogs/category`}
              >
                <span className="menu-text">Category</span>
                <i className="menu-arrow" />
              </NavLink>
              <div
                className={`menu-submenu menu-submenu-classic menu-submenu-right`}
              >
                <ul className="menu-subnav">
                  {/*begin::3 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      `/university/${slug}/blogs/category/all`
                    )}`}
                  >
                    <NavLink
                      className="menu-link"
                      to={`/university/${slug}/blogs/category/all`}
                    >
                      <span className="menu-text">All Category</span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}

                  {/*begin::3 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      `/university/${slug}/blogs/category/new`
                    )}`}
                  >
                    <NavLink
                      className="menu-link"
                      to={`/university/${slug}/blogs/category/new`}
                    >
                      <span className="menu-text">Add New</span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}
                </ul>
              </div>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/blogs/comments`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}/blogs/comments`}
              >
                <span className="menu-text">Comments</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </li>
      {/* blogs & blogs category : end*/}

      {/* user end */}
      {/* staff */}
      <li
        data-menu-toggle={layoutProps.menuDesktopToggle}
        aria-haspopup="true"
        className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
          `/university/${slug}/staff`
        )} ${getMenuItemActive(
          `/university/${slug}/department`
        )} ${getMenuItemActive(
          `/university/${slug}/designation`
        )} ${getMenuItemActive(`/university/${slug}/user`)}`}
      >
        <NavLink
          className="menu-link menu-toggle"
          to={`/university/${slug}/staff`}
        >
          <span className="menu-text">Managing Staff</span>
          <i className="menu-arrow"></i>
        </NavLink>
        <div className="menu-submenu menu-submenu-classic menu-submenu-left">
          <ul className="menu-subnav">
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/staff/all`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}/staff/all`}
              >
                <span className="menu-text">All Staff</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/staff/new`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}/staff/new`}
              >
                <span className="menu-text">Add New</span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                `/university/${slug}/staff/roles`
              )}`}
              data-menu-toggle="hover"
              aria-haspopup="true"
            >
              <NavLink
                className="menu-link menu-toggle"
                to={`/university/${slug}/staff/roles`}
              >
                <span className="menu-text">Roles</span>
                <i className="menu-arrow" />
              </NavLink>
              <div
                className={`menu-submenu menu-submenu-classic menu-submenu-right`}
              >
                <ul className="menu-subnav">
                  {/*begin::3 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      `/university/${slug}/staff/roles/all`
                    )}`}
                  >
                    <NavLink
                      className="menu-link"
                      to={`/university/${slug}/staff/roles/all`}
                    >
                      <span className="menu-text">All Roles</span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}

                  {/*begin::3 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      `/university/${slug}/staff/roles/new`
                    )}`}
                  >
                    <NavLink
                      className="menu-link"
                      to={`/university/${slug}/staff/roles/new`}
                    >
                      <span className="menu-text">Add New</span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}
                  {/*begin::3 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      `/university/${slug}/staff/roles/permission`
                    )}`}
                  >
                    <NavLink
                      className="menu-link"
                      to={`/university/${slug}/staff/roles/permission`}
                    >
                      <span className="menu-text">Permission</span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}
                </ul>
              </div>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                `/university/${slug}/department`
              )}`}
              data-menu-toggle="hover"
              aria-haspopup="true"
            >
              <NavLink
                className="menu-link menu-toggle"
                to={`/university/${slug}/department`}
              >
                <span className="menu-text">Department</span>
                <i className="menu-arrow" />
              </NavLink>
              <div
                className={`menu-submenu menu-submenu-classic menu-submenu-right`}
              >
                <ul className="menu-subnav">
                  {/*begin::3 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      `/university/${slug}/department/all`
                    )}`}
                  >
                    <NavLink
                      className="menu-link"
                      to={`/university/${slug}/department/all`}
                    >
                      <span className="menu-text">All Department</span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}

                  {/*begin::3 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      `/university/${slug}/department/new`
                    )}`}
                  >
                    <NavLink
                      className="menu-link"
                      to={`/university/${slug}/department/new`}
                    >
                      <span className="menu-text">Add New</span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}
                </ul>
              </div>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                `/university/${slug}/designation`
              )}`}
              data-menu-toggle="hover"
              aria-haspopup="true"
            >
              <NavLink
                className="menu-link menu-toggle"
                to={`/university/${slug}/designation`}
              >
                <span className="menu-text">Designation</span>
                <i className="menu-arrow" />
              </NavLink>
              <div
                className={`menu-submenu menu-submenu-classic menu-submenu-right`}
              >
                <ul className="menu-subnav">
                  {/*begin::3 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      `/university/${slug}/designation/all`
                    )}`}
                  >
                    <NavLink
                      className="menu-link"
                      to={`/university/${slug}/designation/all`}
                    >
                      <span className="menu-text">All Designation</span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}

                  {/*begin::3 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      `/university/${slug}/designation/new`
                    )}`}
                  >
                    <NavLink
                      className="menu-link"
                      to={`/university/${slug}/designation/new`}
                    >
                      <span className="menu-text">Add New</span>
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}
                </ul>
              </div>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/user`
              )}`}
            >
              <NavLink className="menu-link" to={`/university/${slug}/user`}>
                <span className="menu-text">Default User</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </li>
      {/* staff end */}
      {/* Media start */}
      <li
        className={`menu-item menu-item-rel ${getMenuItemActive(
          `/university/${slug}/media`
        )}`}
      >
        <NavLink className="menu-link" to={`/university/${slug}/media/all`}>
          <span className="menu-text">Media</span>
          {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
        </NavLink>
      </li>
      {/* Media start */}
      {/* Campus */}
      <li
        data-menu-toggle={layoutProps.menuDesktopToggle}
        aria-haspopup="true"
        className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
          `/university/${slug}/campus`
        )}`}
      >
        <NavLink
          className="menu-link menu-toggle"
          to={`/university/${slug}/campus`}
        >
          <span className="menu-text">Campus</span>
          <i className="menu-arrow"></i>
        </NavLink>
        <div className="menu-submenu menu-submenu-classic menu-submenu-left">
          <ul className="menu-subnav">
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/campus/all`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}/campus/all`}
              >
                <span className="menu-text">All Campus</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                `/university/${slug}/campus/new`
              )}`}
            >
              <NavLink
                className="menu-link"
                to={`/university/${slug}/campus/new`}
              >
                <span className="menu-text">New Campus</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </li>
    </Fragment>
  );
}

export default UniversityMenu;
