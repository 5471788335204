import { Field, Form, Formik } from "formik";
import React, { useEffect, useSt } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  encryptStorage,
  statusOptions,
} from "../../../../../../_unisearch/helpers";
import * as Yup from "yup";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Input,
} from "../../../../../../_metronic/_partials/controls";
import { DatePickerField } from "../../../../../../_metronic/_partials/controls";
import UnieditorText from "../../../../../../_unisearch/forms/UnieditorText";
import * as actions from "../_redux/scholarshipAction";
import { useLocation } from "react-router-dom";
const scholarshipState = {
  scholarshipName: "",
  levelId: [],
  scholarshipAmount: undefined,
  specializationId: undefined,
  deadline: "",
  description: "",
  scholarshipLink: "",
};

export function EditScholarship({
  history,
  match: {
    params: { id },
  },
}) {
  const uniInfo = encryptStorage.getItem("uniProfile");
  const dispatch = useDispatch();

  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { specializationOptions, allLevel, scholarshipForEdit } = useSelector(
    (state) => ({
      specializationOptions: state.uniScholarship.specializationOptions,
      allLevel: state.uniScholarship.allLevel,
      scholarshipForEdit: state.uniScholarship.scholarshipForEdit,
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(actions.fetchSpecialization());
    dispatch(actions.fetchLevel());
    dispatch(actions.fetchScholarship(id));
  }, [id, dispatch]);

  // add scholarship
  const saveScholarship = async (values) => {
    const SelectedLevel = values.levelId.map((item) => {
      return parseInt(item.value);
    });

    const {
      scholarshipName,
      scholarshipAmount,
      deadline,
      description,
      scholarshipLink,
      specializationId,
      status,
      universityId,
    } = values;

    const payload = {
      scholarshipName: scholarshipName,
      levelId: SelectedLevel,
      scholarshipAmount: scholarshipAmount,
      deadline: deadline,
      description: description,
      scholarshipLink: scholarshipLink,
      specializationId: specializationId,
      status: status,
      universityId: universityId,
    };
    dispatch(actions.updateScholarship(payload, id));
  };

  // Validation schema
  const validationSchema = Yup.object().shape({
    scholarshipName: Yup.string().required("Field is required"),
    levelId: Yup.string().required("Field is required"),
    specializationId: Yup.number().required("Field is required"),
    scholarshipAmount: Yup.string().required("Field is required"),
    deadline: Yup.date().required("Field is required"),
  });
  const { pathname } = useLocation();
  return (
    <Formik
      initialValues={scholarshipForEdit || scholarshipState}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        saveScholarship(values);
      }}
    >
      {({ handleSubmit, values, setFieldValue, errors, touched }) => (
        <Form className="form">
          <div className="row">
            <div className="col-md-8">
              <Card>
                <CardHeader title="Edit Scholarship">
                  <CardHeaderToolbar>
                    {pathname.includes("university") === false && (
                      <div
                        className="btn btn-primary btn-sm"
                        onClick={() => history.push("/scholarship")}
                      >
                        All List
                      </div>
                    )}
                  </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <Field
                        name="scholarshipName"
                        value={values?.scholarshipName || ""}
                        component={Input}
                        placeholder="Name"
                        label="Name"
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <Field
                        name="scholarshipAmount"
                        component={Input}
                        value={values?.scholarshipAmount || ""}
                        placeholder="Value"
                        label="Value"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label>Select Course Level</label>
                      <Select
                        menuPortalTarget={
                          typeof document !== "undefined" ? document.body : ""
                        }
                        isMulti
                        id="levelId"
                        classnameprefix="react-select"
                        className={`select form-control ${
                          errors.levelId && touched.levelId ? "is-invalid" : ""
                        }`}
                        instanceId="levelId"
                        name="levelId"
                        placeholder="Select Level"
                        value={values?.levelId}
                        options={allLevel ?? []}
                        onChange={(e) => {
                          setFieldValue("levelId", e);
                        }}
                      />
                      {errors.levelId && touched.levelId ? (
                        <div className="invalid-feedback">{errors.levelId}</div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label>Select Specialization</label>
                      <Select
                        menuPortalTarget={
                          typeof document !== "undefined" ? document.body : ""
                        }
                        id="specializationId"
                        classnameprefix="react-select"
                        className={`select form-control ${
                          errors.specializationId && touched.specializationId
                            ? "is-invalid"
                            : ""
                        }`}
                        instanceId="specializationId"
                        name="specializationId"
                        placeholder="Select Specialization"
                        options={specializationOptions ?? []}
                        onChange={(item) => {
                          setFieldValue("specializationId", item.value);
                        }}
                        value={
                          specializationOptions
                            ? specializationOptions.filter(
                                (item) => item.value == values?.specializationId
                              )
                            : {}
                        }
                      />
                      {errors.specializationId && touched.specializationId ? (
                        <div className="invalid-feedback">
                          {errors.specializationId}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <Field
                        name="scholarshipLink"
                        value={values?.scholarshipLink || ""}
                        component={Input}
                        placeholder="External Link"
                        label="External Link"
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <DatePickerField
                        name="deadline"
                        autoComplete="off"
                        label="Deadline"
                        placeholder="select date"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Description</label>
                    <UnieditorText
                      name="description"
                      height="450"
                      contents={values.description ? values.description : ""}
                      onChange={(event) => {
                        const content = event.target.value.replace(
                          /(<([^>]+)>)/gi,
                          ""
                        );

                        if (content) {
                          setFieldValue("description", event.target.value);
                        } else {
                          setFieldValue("description", "");
                        }
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="col-md-4">
              <Card>
                <CardHeader title="Action" />
                <CardBody>
                  <div className="form-group">
                    <label>Status</label>
                    <Select
                      menuPortalTarget={
                        typeof document !== "undefined" ? document.body : ""
                      }
                      id="status"
                      name="status"
                      instanceId="status"
                      options={statusOptions ?? []}
                      onChange={(item) => {
                        setFieldValue("status", item.value);
                      }}
                      value={
                        statusOptions
                          ? statusOptions.filter(
                              (item) => item.value === values?.status
                            )
                          : {}
                      }
                    />
                  </div>
                  <button
                    onClick={() => handleSubmit}
                    type="submit"
                    className="btn btn-primary"
                  >
                    Update
                  </button>
                </CardBody>
              </Card>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default EditScholarship;
