import React, { Fragment } from "react";
import { useSelector } from "react-redux";
// import { useLocation } from "react-router";
// import { checkIsActive } from "../../../../_helpers";
import { SectionNavs } from "./SectionNavs";

export function AsideMenuList({ layoutProps }) {
  // const location = useLocation();
  // const getMenuItemActive = (url, hasSubmenu = false) => {
  //   return checkIsActive(location, url)
  //     ? ` ${!hasSubmenu &&
  //         "menu-item-active"} menu-item-open menu-item-not-hightlighted`
  //     : "";
  // };

  const state = useSelector((state) => state.auth.user);
  const menus = state.menus;

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/* Overview */}
        {menus && menus.Overview && (
          <Fragment>
            <li className="menu-section ">
              <h4 className="menu-text">Overview</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {menus.Overview.map((item, index) => {
              return (
                <Fragment key={`overview${index}`}>
                  {SectionNavs(item)}
                </Fragment>
              );
            })}
          </Fragment>
        )}
        {/* end:: Overview */}

        {/* Common */}
        {menus && menus.Common && (
          <Fragment>
            <li className="menu-section">
              <h4 className="menu-text">Common</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {menus.Common.map((item, index) => {
              return (
                <Fragment key={`common${index}`}>{SectionNavs(item)}</Fragment>
              );
            })}
          </Fragment>
        )}
        {/* end:: Common */}

        {/* University */}
        {menus && menus.Common && (
          <Fragment>
            <li className="menu-section ">
              <h4 className="menu-text">University</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {menus.University.map((item, index) => {
              return (
                <Fragment key={`university${index}`}>
                  {SectionNavs(item)}
                </Fragment>
              );
            })}
          </Fragment>
        )}
        {/* end:: University */}

        {/* Leads */}
        {menus && menus.Leads && (
          <Fragment>
            <li className="menu-section ">
              <h4 className="menu-text">Leads</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {menus.Leads.map((item, index) => {
              return (
                <Fragment key={`leads${index}`}>{SectionNavs(item)}</Fragment>
              );
            })}
          </Fragment>
        )}
        {/* end:: Leads */}

        {/* Leads */}
        {menus && menus.Unisearch && (
          <Fragment>
            <li className="menu-section ">
              <h4 className="menu-text">Unisearch</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {menus.Unisearch.map((item, index) => {
              return (
                <Fragment key={`unisearch${index}`}>
                  {SectionNavs(item)}
                </Fragment>
              );
            })}
          </Fragment>
        )}
        {/* end:: Leads */}

        {/* Leads */}
        {menus && menus.Settings && (
          <Fragment>
            <li className="menu-section ">
              <h4 className="menu-text">Settings</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {menus.Settings.map((item, index) => {
              return (
                <Fragment key={`settings${index}`}>
                  {SectionNavs(item)}
                </Fragment>
              );
            })}
          </Fragment>
        )}
        {/* end:: Leads */}
      </ul>
    </>
  );
}
