import { createSlice } from "@reduxjs/toolkit";

const initialUniState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  vlogForEdit: undefined,
  error: null,
  authorOptions: [],
  categoryOptions: [],
  vlogTags: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const vlogSlice = createSlice({
  name: "vlog",
  initialState: initialUniState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getvlogById
    vlogFetched: (state, action) => {
      state.actionsLoading = false;
      state.vlogForEdit = action.payload.vlogForEdit;
      state.error = null;
    },

    // findvlog
    allVlogFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createvlog
    vlogCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },

    // updatevlog
    vlogUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },

    // deletevlog
    vlogDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.vlogId !== action.payload.id
      );
    },

    // fetch Category without children
    categoryDropdownFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.categoryOptions = action.payload.categoryOptions;
    },

    //fetch author
    authorFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.authorOptions = action.payload.authorOptions;
    },
    // fetch Category with children
    categoriesFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.vlogCategory = action.payload.vlogCategory;
    },
    //fetch tag
    tagsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.vlogTags = action.payload.vlogTags;
    },
    statusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.statusOverview = action.payload.statusOverview;
    },
    playlistFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.vlogPlaylist = action.payload.vlogPlaylist;
    },
    // createTag
    tagCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },
    // deletevlog
    vlogHardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
  },
});
