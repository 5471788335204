import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { swalError, WEB_LINK } from "../../../_unisearch/helpers";
import { getAPI } from "../../../_unisearch/util/authUtils";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../controls";
export function TopPages({ timeValue, dateChange, props }) {
  const [devices, setDevices] = useState([]);
  const [pagesOverview, setPagesOverview] = useState("Top Pages");
  const siteURL = process.env.REACT_APP_GRAPH_URL;
  const graphSecret = process.env.REACT_APP_ANALYSIS_SECRET_KEY;
  useEffect(() => {
    let unmount = false;
    const fetchDeviceDataList = async () => {
      try {
        if (unmount) return;
        await getAPI(
          `https://analytics.myunisearch.com/api/stats/${siteURL}/${
            pagesOverview === "Entry Pages"
              ? "entry-pages"
              : pagesOverview === "Exit Pages"
              ? "exit-pages"
              : "pages"
          }?period=${timeValue}&date=${dateChange}&filters={}&auth=${graphSecret}`
        ).then((res) => {
          if (!res.error) {
            setDevices(res);
          }
          if (res.error) {
            swalError(res.data);
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchDeviceDataList();
    return () => {
      unmount = true;
    };
  }, [timeValue, pagesOverview]);
  return (
    <Card>
      <CardHeader title={pagesOverview}>
        <CardHeaderToolbar>
          <div
            onClick={() => setPagesOverview("Top Pages")}
            className={`btn ${
              pagesOverview === "Top Pages"
                ? "btn-primary"
                : "btn-outline-primary"
            } btn-sm mr-2`}
          >
            Top Pages
          </div>
          <div
            className={`btn ${
              pagesOverview === "Entry Pages"
                ? "btn-primary"
                : "btn-outline-primary"
            } btn-sm mr-2`}
            onClick={() => setPagesOverview("Entry Pages")}
          >
            Entry Pages
          </div>
          <div
            className={`btn ${
              pagesOverview === "Exit Pages"
                ? "btn-primary"
                : "btn-outline-primary"
            } btn-sm`}
            onClick={() => setPagesOverview("Exit Pages")}
          >
            Exit Pages
          </div>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="country_stats_wrapper">
          <div className="country_stats_title">
            <span>Page URL</span>
            <span>Visitors</span>
          </div>
          <ul>
            {devices.map((item, i) => {
              return (
                <li key={`${i}statData`}>
                  <span>
                    <a href={`${WEB_LINK}/${item.name}`} target="_blank">
                      {item.name}
                    </a>
                  </span>
                  <span>{item.count}</span>
                  <div
                    className="line"
                    style={{ width: `${item.percentage}%` }}
                  ></div>
                </li>
              );
            })}
          </ul>
        </div>
      </CardBody>
    </Card>
  );
}
