/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState } from "react";
import Select from "react-select";
import { swalConfirm, swalSuccess } from "../../../../../../_unisearch/helpers";
import { months, monthTo } from "../../CourseUIHelpers";

export const IntakeComponent = ({
  intakes,
  sessions,
  setFieldValue,
  errors,
  touched,
  ...props
}) => {
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    swalConfirm(
      "You won't be able to revert this!",
      "Are you sure?",
      "Yes, delete it!"
    ).then((result) => {
      if (result.isConfirmed) {
        const intakelist = [...intakes];
        intakelist.splice(index, 1);
        setFieldValue("intake", intakelist);
        swalSuccess("Your file has been deleted.");
      }
    });
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setFieldValue("intake", [
      ...intakes,
      { admissionSessionId: "", month: "", monthTo: "January" },
    ]);
  };

  const handleChange = (values, name, index) => {
    let prevState = [...intakes];
    let UpdateItem = { ...prevState[index], [name]: values.value };
    prevState[index] = UpdateItem;
    setFieldValue("intake", prevState);
  };

  return (
    <Fragment>
      {intakes.map((intake, i) => {
        const essionErrors = (errors?.length && errors[i]) || {};
        const essionTouched =
          (touched.intake?.length && touched.intake[i]) || {};

        return (
          <div className="row" key={`intake_${i}`}>
            <div className="col">
              <div className="form-group">
                <Select
                  id={`session_${i}`}
                  classnameprefix="react-select"
                  className={`select form-control ${
                    essionErrors.admissionSessionId &&
                    essionTouched.admissionSessionId
                      ? "is-invalid"
                      : ""
                  }`}
                  name={`admissionSessionId`}
                  options={sessions ?? []}
                  multi={false}
                  onChange={(e) => handleChange(e, "admissionSessionId", i)}
                  value={
                    sessions
                      ? sessions.filter(
                          (item) => item.value == intakes[i].admissionSessionId
                        )
                      : {}
                  }
                />
                {essionErrors.admissionSessionId &&
                essionTouched.admissionSessionId ? (
                  <div className="invalid-feedback">
                    {essionErrors.admissionSessionId}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <Select
                  id={`month_${i}`}
                  classnameprefix="react-select"
                  className={`select form-control ${
                    essionErrors.month && essionTouched.month
                      ? "is-invalid"
                      : ""
                  }`}
                  name={`month`}
                  options={months ?? []}
                  multi={false}
                  onChange={(e) => {
                    handleChange(e, "month", i);
                  }}
                  value={
                    months
                      ? months.filter((item) => item.value == intakes[i].month)
                      : {}
                  }
                />
                {essionErrors.month && essionTouched.month ? (
                  <div className="invalid-feedback">{essionErrors.month}</div>
                ) : null}
              </div>
            </div>
            <div className="col-md-2">
              {intakes.length !== 1 && (
                <button
                  type="button"
                  className="btn"
                  onClick={() => handleRemoveClick(i)}
                >
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </span>
                </button>
              )}
              {intakes.length - 1 === i && (
                <button type="button" className="btn" onClick={handleAddClick}>
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </button>
              )}
            </div>
          </div>
        );
      })}
    </Fragment>
  );
};
export default IntakeComponent;
