// import axios from "axios";
import {
  postAPI,
  getAPI,
  putAPI,
  patchAPI,
  deleteAPI,
} from "../../../../../_unisearch/util/authUtils";

const API_ENDPOINTS = "/admin/university";

// // get all universities with pagination
// export function findUniversities(queryParams) {
//   // return postAPI(`${"/university"}/fetch`, { queryParams });
//   return getAPI(`${API_ENDPOINTS}`, { queryParams });
// }

export function findUniversities(queryParams) {
  // return getAPI(`/admin/city/`, { queryParams });
  const { filter, pageNumber, pageSize, sortField, sortOrder } = queryParams;

  return postAPI(`admin/university/finduniversity`, {
    filter: filter,
    pageNumber: pageNumber,
    pageSize: pageSize,
    sortField: sortField,
    sortOrder: sortOrder,
  });
}

// Create  new university
export function createUniversity(data) {
  return postAPI(API_ENDPOINTS, data);
}

// get all university
export function getAllUniversities() {
  return patchAPI(`${API_ENDPOINTS}/finduniversity`);
}

//get sigle university
export function getUniversityById(id) {
  return getAPI(`${API_ENDPOINTS}/${id}`);
}

// UPDATE University by ID
export function updateUniversity(data, id) {
  return patchAPI(`${API_ENDPOINTS}/${id}`, data);
}

// DELETE  University information from server
export function deleteUniversity(id) {
  return patchAPI(`${API_ENDPOINTS}`, id);
}

// DELETE File by ids
export function deleteUniversities(ids) {
  return patchAPI(`${API_ENDPOINTS}`, { ids });
}

// Get University Profiles
// export function getUniProfiles(id) {
//   return getAPI(`${API_ENDPOINTS}/${id}`);
// }

export function getUniProfiles(slug) {
  return getAPI(`${"/university"}/slug/?slug=${slug}`);
}

// get all Countries
export function getAllCountries() {
  return getAPI(`/public/country/workable`);
}

//University All Review

export function findUniversitiesReview(queryParams) {
  const { filter, pageNumber, pageSize, sortField, sortOrder } = queryParams;

  return postAPI(`admin/university-review/all/filter-list`, {
    filter: filter,
    pageNumber: pageNumber,
    pageSize: pageSize,
    sortField: sortField,
    sortOrder: sortOrder,
  });
}

//Review By University Id

export function findSingleUniversityReview(queryParams, id) {
  const { filter, pageNumber, pageSize, sortField, sortOrder } = queryParams;

  return postAPI(`admin/university-review/${id}`, {
    filter: filter,
    pageNumber: pageNumber,
    pageSize: pageSize,
    sortField: sortField,
    sortOrder: sortOrder,
  });
}

//All University
export function findAllUniversity(queryParams) {
  return postAPI(`admin/university/finduniversity`, {
    filter: {
      //name: queryParams,
    },
    pageNumber: 1,
    pageSize: 500,
    sortField: "universityId",
    sortOrder: "DESC",
  });
}
//All University status overview
export function fetchAllUniversityStatus() {
  return getAPI(`admin/university-review/status/all`);
}

//single university status overview
export function fetchSingleUniversityStatus(id) {
  return getAPI(`admin/university-review/status/university/${id}`);
}
//publish review
export function publishReview(id) {
  return patchAPI(`admin/university-review/publish`, id);
}
//feature review
export function featureReview(id) {
  return patchAPI(`admin/university-review/featured/add`, id);
}
//unfeature review
export function unFeatureReview(id) {
  return patchAPI(`admin/university-review/featured/remove`, id);
}
//state List
export function stateList(id) {
  return postAPI(`admin/state/list-by-country`, { countryId: id });
}
//state List
export function cityList(id) {
  return postAPI(`admin/city/list-by-state`, { stateId: id });
}
//review Delete
export function deleteReview(id) {
  return deleteAPI(`admin/university-review/${id}`);
}
// //review Delete
// export function trashReview(id) {
//   return patchAPI(`admin/university-review/soft-delete`, id);
// }
//top university
export function topUniversity(data) {
  return postAPI(`admin/university/top`, data);
}
