export const CourseStatusCssClasses = {
  Active: "success",
  Inactive: "danger",
  Draft: "info",
};

export const StatusTitles = ["Active", "Draft", "Inactive"];

export const TypeCssClasses = ["success", "primary", ""];

export const defaultSorted = [{ dataField: "courseId", order: "ASC" }];

export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 },
];

export const initialFilter = {
  filter: {
    status: "Active",
  },
  sortOrder: "DESC", // asc||desc
  sortField: "courseId",
  pageNumber: 1,
  pageSize: 10,
};
