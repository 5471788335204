import { createSlice } from "@reduxjs/toolkit";

const initState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  stateForEdit: undefined,
  lastError: null,
  countryList: [],
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const stateSlice = createSlice({
  name: "State",
  initialState: initState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // get state ById
    stateFetched: (state, action) => {
      state.actionsLoading = false;
      state.stateForEdit = action.payload.stateForEdit;
      state.error = null;
    },

    // findF states
    statesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createState
    stateCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.state);
    },

    // updateState
    stateUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.map((entity) => {
      //   if (entity.id === action.payload.state.id) {
      //     return action.payload.state;
      //   }
      //   return entity;
      // });
    },

    // delete State
    stateDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.stateId !== action.payload.id
      );
    },

    // delete States
    statesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.stateId)
      );
    },

    // Fetch States Level
    countryFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.countryList = action.payload.countryList;
    },
  },
});
