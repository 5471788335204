import React, { useEffect, useState } from "react";

import { swalError, WEB_LINK } from "../../../_unisearch/helpers";
import { getAPI } from "../../../_unisearch/util/authUtils";
import {
  OverviewWidget1,
  OverviewWidget2,
  OverviewWidget3,
  OverviewWidget4,
  OverviewWidget5,
} from "../widgets";
import { Chart } from "react-google-charts";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../controls";
import { countries } from "country-data";
import Select from "react-select";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { VisitorChart } from "../components/VisitorChart";
import { VisitorMap } from "../components/VisitorMap";
import moment from "moment";
import { Devices } from "../components/Devices";
import { TopPages } from "../components/TopPages";
import { TopSources } from "../components/Sources";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
export function Demo1Dashboard() {
  const [overviewData, setOverviewData] = useState();
  const [timeState, setTimeState] = useState({
    title: "Today",
    value: "day",
  });
  const formattedData = moment(new Date()).format("YYYY-MM-DD");
  useEffect(() => {
    let unmount = false;
    const fetchDataList = async () => {
      try {
        if (unmount) return;
        await getAPI("/admin/admin-dashboard").then((res) => {
          if (!res.error) {
            setOverviewData(res.data);
          }
          if (res.error) {
            swalError(res.data);
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataList();
    return () => {
      unmount = true;
    };
  }, []);

  const timeData = [
    {
      id: 1,
      label: "Today",
      value: "day",
    },
    {
      id: 2,
      label: "Realtime",
      value: "realtime",
    },
    {
      id: 3,
      label: "Last 7 days",
      value: "7d",
    },
    {
      id: 4,
      label: "Last 30 days",
      value: "30d",
    },
    // {
    //   id: 5,
    //   label: "Month to date",
    //   value: "month",
    // },
    {
      id: 6,
      label: "Last 6 months",
      value: "6mo",
    },
    {
      id: 7,
      label: "Last 12 months",
      value: "12mo",
    },
  ];
  return (
    <>
      <div className=" container row">
        <div className="col">
          <OverviewWidget1 props={overviewData} />
        </div>
        <div className="col">
          <OverviewWidget2 props={overviewData} />
        </div>
        <div className="col">
          <OverviewWidget3 props={overviewData} />
        </div>
        <div className="col">
          <OverviewWidget4 props={overviewData} />
        </div>
        <div className="col">
          <OverviewWidget5 props={overviewData} />
        </div>
      </div>
      <Card>
        <CardHeader title="Reports">
          <CardHeaderToolbar>
            <Select
              id="timeData"
              name="timeData"
              className="col-lg-12"
              classnameprefix="react-select"
              options={timeData ?? []}
              multi={false}
              onChange={(item) => {
                setTimeState({
                  title: item.label,
                  value: item.value,
                });
              }}
              value={
                timeData
                  ? timeData.filter((item) => item.value == timeState?.value)
                  : {}
              }
            />
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-md-6">
              <VisitorChart
                timeValue={timeState.value}
                dateChange={formattedData}
              />
            </div>
            <div className="col-md-6">
              <VisitorMap
                timeValue={timeState.value}
                dateChange={formattedData}
              />
            </div>
            <div className="col-md-6">
              <TopPages
                timeValue={timeState.value}
                dateChange={formattedData}
              />
            </div>

            <div className="col-md-6">
              <Devices timeValue={timeState.value} dateChange={formattedData} />
            </div>
            <div className="col-md-6">
              <TopSources
                timeValue={timeState.value}
                dateChange={formattedData}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
