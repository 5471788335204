import { createSlice } from "@reduxjs/toolkit";

const initialUniState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  formForEdit: {
    title: "",
    properties: [
      {
        id: 0,
        name: "",
        label: "",
        type: "",
        isRequired: 0,
        columnSize: "",
        placeholder: "",
      },
    ],
  },
  error: null,
  authorOptions: [],
  categoryOptions: [],
  formTemplateOptions: [],
  formTags: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const formSlice = createSlice({
  name: "form",
  initialState: initialUniState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getformById
    formFetched: (state, action) => {
      state.actionsLoading = false;
      state.formForEdit = action.payload.formForEdit;
      state.error = null;
    },

    // findform
    allFormFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createform
    formCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },

    // updateform
    formUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },

    // deleteform
    formHardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
  },
});
