import { createSlice } from "@reduxjs/toolkit";

const initialUniState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  error: null,
  statusOverview: {},
  evaluationOptions: [],
  evaluationForEdit: {},
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const evaluationSlice = createSlice({
  name: "evaluation",
  initialState: initialUniState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // findevaluation
    allEvaluationFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // deleteevaluation
    evaluationDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.evaluationId !== action.payload.id
      );
    },

    // deleteevaluation
    evaluationHardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.evaluationId !== action.payload.id
      );
    },

    evaluationUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.evaluationId !== action.payload.id
      );
    },

    evaluationFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.evaluationForEdit = action.payload.evaluationForEdit;
    },

    statusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.statusOverview = action.payload.statusOverview;
    },

    parentEvaluationFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.evaluationOptions = action.payload.evaluationOptions;
    },

    // Active evaluation
    evaluationActive: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },

    // createevaluation
    evaluationCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },
  },
});
