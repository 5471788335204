/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import { postAPI } from "../../_unisearch/util/authUtils";
import { MediaBox } from "../common";
import { UploadForm } from "../common/media";
import UniPager from "../common/UniPager";
import { swalError } from "../helpers";
import { FaRegTrashAlt } from "react-icons/fa";

import styles from "./ImageInput.module.scss";
import Swal from "sweetalert2";

export const ImageInput = ({
  headerMessage = false,
  ratio = false,
  name,
  url,
  onChange,
  returnItem,
  component_type = "default",
  title = "Upload Image",
  allowItem = "image", //"video", "pdf", "xlsx"
  maxSize,
  ...props
}) => {
  //initial media name and files
  const target_name = name || "featured_image";
  const FILES_URL = `/media-manager/find-mediaFile`;

  //handle popup
  const [popup, setPop] = useState(false);
  const [source, setSource] = useState();
  const [loading, setLoading] = useState(false);
  const [is_EnableForm, setEnableForm] = useState(false);
  const [selectedImageRatio, setSelectedImageRatio] = useState();

  //get Media Data
  const [mediaData, setMediaData] = useState();

  //Pager Info
  const [totalPage, setTotalPage] = useState(1);
  const [perpage, setPerpage] = useState(24);
  const [currentPage, setCurrentPage] = useState(1);

  //handle close
  const handleClose = () => {
    setPop(false);
  };

  //handle show
  const handleShow = () => {
    setPop(true);
    //get media data
    handleSearch();
  };

  //search handleClose
  const [searchFields, setSearchFields] = useState({
    filter: { mediaName: "", alt: "", tag: allowItem, status: "Active" },
    sortOrder: "DESC",
    sortField: "mediaManagerId",
    pageNumber: currentPage,
    pageSize: 24,
  });

  //handle serach input
  const handleInput = (e) => {
    const { name, value } = e.target;
    const filter = { ...searchFields.filter, [name]: value };
    setSearchFields({ ...searchFields, filter });
  };

  const handleOrder = (e) => {
    const { name, value } = e.target;
    setSearchFields({ ...searchFields, [name]: value });
  };

  //when click on handle serach button
  const handleSearch = async (customSearch = undefined) => {
    //loading true
    setLoading(true);
    let filterSearch = { ...searchFields };

    if (customSearch) {
      filterSearch = customSearch;
    }

    //post handle search
    await postAPI(`${FILES_URL}`, filterSearch)
      .then((response) => {
        if (response.error) {
          swalError("Something Wrong!");
        }

        if (!response.error) {
          const { total, results } = response.data;

          //set total items
          setTotalPage(total);

          //set media data
          setMediaData({ total, entities: results });
        }
      })
      .catch((error) => {
        swalError(error);
      });

    //loading false
    setLoading(false);
  };

  //Manage image path
  let placeholder =
    allowItem && allowItem == "video"
      ? "/images/video-placeholder.png"
      : "/images/image-placeholder.png";

  const [imageUrl, setImageUrl] = useState(placeholder);

  const [selected, setSelected] = useState();

  const imageChange = (data) => {
    setSelected(data);
    setSource(undefined);
  };

  const handleInsert = () => {
    if (ratio) {
      const img = new Image();
      img.onload = function() {
        if (this.width / this.height == ratio) {
          setImageUrl(selected.src);
          //pass parent props
          if (onChange) {
            const target = {
              name: target_name,
              value: selected[returnItem],
              url: selected.src,
            };
            onChange({ target });
          }
          setPop((prevPop) => !prevPop);
          setSource(selected.src);
        } else {
          Swal.fire({
            title: "Are you sure?",
            text: `The Selected Image Size is ${this.width}*${this.height} px.
             But ${headerMessage ?? "It doesn't match with required size."}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, insert it!",
          }).then((result) => {
            if (result.isConfirmed) {
              setImageUrl(selected.src);
              //pass parent props
              if (onChange) {
                const target = {
                  name: target_name,
                  value: selected[returnItem],
                  url: selected.src,
                };
                onChange({ target });
              }
              setPop((prevPop) => !prevPop);
              setSource(selected.src);
            }
          });
        }
      };
      img.src = `${selected.src}`;
    } else {
      setImageUrl(selected.src);
      //pass parent props
      if (onChange) {
        const target = {
          name: target_name,
          value: selected[returnItem],
          url: selected.src,
        };
        onChange({ target });
      }
      setPop((prevPop) => !prevPop);
      setSource(selected.src);
    }
  };

  //handle Remove Item
  const handleRemove = () => {
    setImageUrl(placeholder);
    //pass parent props
    if (onChange) {
      const target = { name: target_name, value: "" };
      onChange({ target });
    }
    setPop(false);
    setSelected(undefined);
    setSource(undefined);
  };

  const PreviewImage = () => {
    const extension = imageUrl.split(".").pop();

    if (extension == "pdf") {
      return <i className={`fas fa-file-pdf ${styles.icon}`}></i>;
    }

    if (extension == "xlsx") {
      return <i className={`fas fa-file-excel ${styles.icon}`}></i>;
    }

    if (extension == "mp4") {
      return (
        <video width="100%" height="180" controls="controls" preload="none">
          <source src={imageUrl} type="video/mp4" autostart="false" />
          Your browser does not support the video tag.
        </video>
      );
    }

    if (
      extension == "jpg" ||
      extension == "jpeg" ||
      extension == "png" ||
      extension == "svg"
    ) {
      return <img src={imageUrl} alt="" />;
    }

    return <img src={imageUrl} alt="" />;
  };

  const handlePager = (page) => {
    setCurrentPage(page);
    setSearchFields({ ...searchFields, pageNumber: page });
    handleSearch({ ...searchFields, pageNumber: page });
  };

  //change image url
  useEffect(() => {
    setImageUrl(url || placeholder);
  }, [url, placeholder]);

  useEffect(() => {
    setSource(url);
  }, []);

  //handleList
  const handleList = () => {
    setEnableForm(false);
    setCurrentPage(1);
  };

  //get data form child
  const checkUpload = (result) => {
    if (result) {
      setEnableForm(false);
      handleSearch({ ...searchFields, pageNumber: 1 });
    } else {
      swalError("Upload Error");
    }
  };

  const RendarItem = () => {
    return (
      <Fragment>
        <Modal.Header closeButton>
          <Modal.Title className={styles.filterArea}>
            {!is_EnableForm && (
              <div id="imgSearch" className="form form-label-right">
                <Form.Group className="row mb-0">
                  <Col xs={3}>
                    <small className="form-text text-muted">
                      Filter by Type
                    </small>
                    <select
                      className="form-control"
                      name="tag"
                      placeholder="Filter by Type"
                      onBlur={() => handleSearch()}
                      onChange={handleInput}
                      value={searchFields.filter.tag}
                    >
                      {allowItem && allowItem == "All" && (
                        <Fragment>
                          <option value="">All</option>
                          <option value="image">Image</option>
                          <option value="video">Video</option>
                          <option value="pdf">PDF</option>
                        </Fragment>
                      )}
                      {allowItem && allowItem == "image" && (
                        <option value="image">Image</option>
                      )}
                      {allowItem && allowItem == "video" && (
                        <option value="video">Video</option>
                      )}
                      {allowItem && allowItem == "pdf" && (
                        <option value="pdf">PDF</option>
                      )}
                    </select>
                  </Col>
                  <Col xs={3}>
                    <small className="form-text text-muted">Short Order</small>
                    <select
                      className="form-control"
                      name="sortOrder"
                      placeholder="Filter by Type"
                      onBlur={() => handleSearch()}
                      onChange={handleOrder}
                      value={searchFields.sortOrder}
                    >
                      <option value="DESC">DESC</option>
                      <option value="ASC">ASC</option>
                    </select>
                  </Col>
                  <Col xs={3}>
                    <small className="form-text text-muted">
                      Filter by name
                    </small>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      name="mediaName"
                      placeholder="Search"
                      onBlur={() => handleSearch()}
                      value={searchFields.filter.mediaName}
                      onChange={handleInput}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleSearch();
                        }
                      }}
                    />
                  </Col>
                  <Col xs={1} style={{ textAlign: "left" }}>
                    <button
                      type="button"
                      className="btn btn-icon btn-clean btn-lg mt-5"
                      onClick={() => handleSearch()}
                    >
                      <i className="fa fa-search" />
                    </button>
                  </Col>
                  <Col xs={2} style={{ textAlign: "right" }}>
                    <button
                      type="button"
                      className="btn btn-icon btn-clean btn-lg mt-5"
                      onClick={(e) => setEnableForm(true)}
                      style={{ marginLeft: "2px" }}
                    >
                      <i className="fa fa-upload" />
                    </button>
                  </Col>
                </Form.Group>
              </div>
            )}

            {is_EnableForm && (
              <Row>
                <Col xs={9}>
                  <h5 className={`${styles.modalTitle}`}> Upload Files</h5>
                </Col>
                <Col xs={3} style={{ textAlign: "right" }}>
                  <button
                    type="button"
                    className={`btn btn-icon btn-clean btn-lg ${styles.upclsIcon}`}
                    onClick={handleList}
                  >
                    <i className="fa fa-th-large" />
                  </button>
                </Col>
              </Row>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          {!is_EnableForm && (
            <Row>
              {loading && (
                <div className="d-block text-center w-100 py-5">
                  <span className="spinner p-5"></span>
                </div>
              )}
              {mediaData &&
                mediaData.entities &&
                mediaData.entities.map((entity, index) => {
                  return (
                    <MediaBox
                      data={entity}
                      key={entity.mediaManagerId}
                      onClick={imageChange}
                      is_selected={
                        selected &&
                        entity.mediaManagerId == selected.mediaManagerId
                      }
                    />
                  );
                })}

              {(!mediaData?.entities || mediaData?.entities.length == 0) && (
                <div className={styles.noRecord}>No Record Found</div>
              )}
            </Row>
          )}

          {/* Upload form */}
          {is_EnableForm && (
            <UploadForm
              checkUpload={checkUpload}
              maxSize={maxSize}
              allowItem={allowItem}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          {!is_EnableForm && (
            <UniPager
              pageClicked={handlePager}
              total={parseInt(totalPage)}
              perpage={parseInt(perpage)}
              current={parseInt(currentPage)}
            />
          )}
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {!is_EnableForm && selected && selected.src && (
            <Button variant="primary" onClick={handleInsert}>
              Insert
            </Button>
          )}
        </Modal.Footer>
      </Fragment>
    );
  };

  if (component_type == "button") {
    return (
      <Fragment>
        <button
          type="button"
          className={styles.button}
          name={target_name}
          onClick={handleShow}
        >
          <PreviewImage />
        </button>
        {source && source != "" && (
          <button
            type="button"
            className={styles.removeItem}
            onClick={() => {
              handleRemove();
            }}
          >
            <FaRegTrashAlt />
          </button>
        )}

        <Modal
          dialogClassName="modal-90w"
          show={popup}
          backdrop="static"
          onHide={handleClose}
        >
          {RendarItem()}
        </Modal>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Card>
        <CardHeader title={title ?? ""}></CardHeader>
        <CardBody>
          {headerMessage && (
            <Fragment>
              <span className="error">*</span>
              {headerMessage}
            </Fragment>
          )}
          <button
            type="button"
            className={styles.button}
            name={target_name}
            onClick={handleShow}
          >
            <PreviewImage />
          </button>

          {((source && source != "") || imageUrl != placeholder) && (
            <button
              type="button"
              className={styles.removeItem}
              onClick={() => {
                handleRemove();
              }}
            >
              <FaRegTrashAlt />
            </button>
          )}

          {props?.error && (
            <div className="error mt-5 text-center">{props?.error}</div>
          )}
          <Modal
            dialogClassName="modal-90w"
            show={popup}
            backdrop="static"
            onHide={handleClose}
          >
            {RendarItem()}
          </Modal>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default ImageInput;
