import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { countriesSlice } from "../app/modules/Countries/_redux/countries/countrySlice";
import { filesSlice } from "../app/modules/Media/_redux/files/filesSlice";

import { uniSlice } from "../app/modules/Universities/_redux/dashboard/uniSlice";
import { coursesSlice } from "../app/modules/Universities/_redux/courses/coursesSlice";
import { stateSlice } from "../app/modules/Countries/_redux/state/stateSlice";
import { citySlice } from "../app/modules/Countries/_redux/city/citySlice";
import { counselingsSlice } from "../app/modules/Counseling/_redux/counsel/counselingSlice";
import { newsSlice } from "../app/modules/News/_redux/news/newsSlice";
import { categorySlice } from "../app/modules/News/_redux/category/categorySlice";
import { blogCategorySlice } from "../app/modules/Blog/_redux/category/categorySlice";
import { blogTagsSlice } from "../app/modules/Blog/_redux/tags/tagSlice";
import { tagsSlice } from "../app/modules/News/_redux/tags/tagSlice";
import { commentSlice } from "../app/modules/Comments/_redux/commentsSlice";
import { adminUsersSlice } from "../app/modules/Settings/user/_redux/usersSlice";
import { uniBlogSlice } from "../app/modules/Universities/_redux/blogs/blogsSlice";
import { uniBlogCategorySlice } from "../app/modules/Universities/_redux/blogsCategory/categorySlice";
import { scholarshipSlice } from "../app/modules/Universities/courses/scholarship/_redux/scholarshipSlice";
import { uniBlogCommentSlice } from "../app/modules/Universities/blog/blog-comments/_redux/commentsSlice";
import { uniAlbumSlice } from "../app/modules/Universities/gallery/_redux/uniAlbum/uniAlbumSlice";
import { uniDepartmentSlice } from "../app/modules/Universities/department/_redux/uniDepartmentSlice";
import { uniDesignationSlice } from "../app/modules/Universities/designation/_redux/uniDesignationSlice";
import { uniGallerySlice } from "../app/modules/Universities/gallery/_redux/uniGallery/uniGallerySlice";
import { uniBranchSlice } from "../app/modules/Abroad/branch/_redux/branchSlice";
import { uniRankingSlice } from "../app/modules/Universities/ranking/_redux/uniRankingSlice";
import { uniStaffSlice } from "../app/modules/Universities/managing-staff/_redux/staff/staffSlice";
import { uniRoleSlice } from "../app/modules/Universities/managing-staff/_redux/role/roleSlice";
import { vlogSlice } from "../app/modules/VideoBlog/_redux/vlog/vlogSlice";
import { vlogTagsSlice } from "../app/modules/VideoBlog/_redux/tags/tagSlice";
import { vlogPlayListSlice } from "../app/modules/VideoBlog/_redux/playList/playListSlice";
import { vlogCommentSlice } from "../app/modules/VideoBlog/_redux/comment/commentsSlice";
import { vlogCategorySlice } from "../app/modules/VideoBlog/_redux/category/categorySlice";
import { forumGroupSlice } from "../app/modules/Forum/_redux/group/groupSlice";
import { forumGroupTopicSlice } from "../app/modules/Forum/_redux/topic/topicSlice";
import { forumGroupPostSlice } from "../app/modules/Forum/_redux/post/postSlice";
import { SpecialKeywordSlice } from "../app/modules/Keywords/_redux/special/keywordSlice";
import { SearchedKeywordSlice } from "../app/modules/Keywords/_redux/keywordSlice";
import { evaluationSlice } from "../app/modules/Abroad/evalution/_redux/evaluationSlice";
import { pageSlice } from "../app/modules/pages/_redux/pageSlice";
import { blogSlice } from "../app/modules/Blog/_redux/blog/blogSlice";
import { leadsContactSlice } from "../app/modules/LeadContacts/_redux/leadsContact/leadsContactSlice";
import { leadsFormSlice } from "../app/modules/LeadContacts/_redux/leadsPage/leadsFormSlice";
import { testimonialSlice } from "../app/modules/Testimonial/_redux/testimonialSlice";
import { mentorSlice } from "../app/modules/Mentor/_redux/mentorSlice";
import { studentSlice } from "../app/modules/Student/_redux/student/studentSlice";
import { memberSlice } from "../app/modules/Teams/_redux/member/memberSlice";
import { counsellorSlice } from "../app/modules/Counsellor/_redux/counsellor/counsellorSlice";
import { allCoursesSlice } from "../app/modules/Course/_redux/courses/coursesSlice";
import { allScholarshipsSlice } from "../app/modules/Scholarship/_redux/scholarship/scholarshipSlice";
import { allRankingsSlice } from "../app/modules/Ranking/_redux/ranking/rankingSlice";
import { fairSlice } from "../app/modules/Vfair/_redux/fair/fairSlice";
import { eventSlice } from "../app/modules/Vfair/_redux/event/eventSlice";
// import { formSlice } from "../app/modules/FormBuilder/_redux/form/FormSlice";
import { meetingSlice } from "../app/modules/CounsellorMeeting/_redux/meeting/meetingSlice";
import { formSlice } from "../app/modules/Form/_redux/form/formSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  files: filesSlice.reducer,
  universities: uniSlice.reducer,
  courses: coursesSlice.reducer,
  countries: countriesSlice.reducer,
  countryState: stateSlice.reducer,
  countryCity: citySlice.reducer,
  counselings: counselingsSlice.reducer,
  news: newsSlice.reducer,
  category: categorySlice.reducer,
  blogCategory: blogCategorySlice.reducer,
  blogTags: blogTagsSlice.reducer,
  tags: tagsSlice.reducer,
  comment: commentSlice.reducer,
  user: adminUsersSlice.reducer,
  uniBlogs: uniBlogSlice.reducer,
  uniBlogsCategory: uniBlogCategorySlice.reducer,
  uniBlogscomments: uniBlogCommentSlice.reducer,
  videoBlog: vlogSlice.reducer,
  vlogTags: vlogTagsSlice.reducer,
  vlogPlayList: vlogPlayListSlice.reducer,
  vlogCategory: vlogCategorySlice.reducer,
  forumGroup: forumGroupSlice.reducer,
  forumGroupTopic: forumGroupTopicSlice.reducer,
  forumGroupPost: forumGroupPostSlice.reducer,
  vlogComment: vlogCommentSlice.reducer,
  specialKeyword: SpecialKeywordSlice.reducer,
  searchedKeyword: SearchedKeywordSlice.reducer,
  uniAlbum: uniAlbumSlice.reducer,
  uniGallery: uniGallerySlice.reducer,
  uniScholarship: scholarshipSlice.reducer,
  uniDepartment: uniDepartmentSlice.reducer,
  uniDesignation: uniDesignationSlice.reducer,
  uniBranch: uniBranchSlice.reducer,
  uniRanking: uniRankingSlice.reducer,
  evaluationObjective: evaluationSlice.reducer,
  pages: pageSlice.reducer,
  uniStaff: uniStaffSlice.reducer,
  uniStaffRole: uniRoleSlice.reducer,
  blog: blogSlice.reducer,
  leadsContact: leadsContactSlice.reducer,
  leadsForm: leadsFormSlice.reducer,
  testimonial: testimonialSlice.reducer,
  mentor: mentorSlice.reducer,
  student: studentSlice.reducer,
  teamMember: memberSlice.reducer,
  counsellor: counsellorSlice.reducer,
  allCourse: allCoursesSlice.reducer,
  allScholarship: allScholarshipsSlice.reducer,
  allRanking: allRankingsSlice.reducer,
  allVirtualFair: fairSlice.reducer,
  allVirtualEvent: eventSlice.reducer,
  counsellorMeeting: meetingSlice.reducer,
  form: formSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
