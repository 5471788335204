import { StatusCssClasses } from "./UIHelpers";

//Alignment Formater
export const FormatAlignment = (value, row, align = "left") => {
  return <span className={`d-block text-${align}`}>{value}</span>;
};

//image filter
export const FormateImage = (value, row, size = 50) => {
  return (
    <span className="images">
      <img src={value ?? "/images/image.png"} alt="" width={size ?? 50} />
    </span>
  );
};

//Status Column Filter
export const StatusColumnFormatter = (cell, row) => {
  const getLabelCssClasses = () => {
    return `label label-lg label-light-${StatusCssClasses[cell]} label-inline`;
  };
  return <span className={getLabelCssClasses()}>{cell}</span>;
};
