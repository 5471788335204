import { createSlice } from "@reduxjs/toolkit";

const initialUniState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  universityForEdit: undefined,
  uniProfile: undefined,
  error: null,
  uniCuntries: [],
  reviewEntities: null,
  reviewTotalCount: 0,
  reviewSingleEntities: null,
  reviewSingleTotalCount: 0,
  stateOptions: [],
  cityOptions: [],
  allUniversityOptions: [],
  allUniReviewStatus: {},
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const uniSlice = createSlice({
  name: "universities",
  initialState: initialUniState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    //get University Profile
    profileFetched: (state, action) => {
      state.actionsLoading = false;
      state.uniProfile = action.payload.uniProfile;
      state.error = null;
    },

    // getFileById
    universityFetched: (state, action) => {
      state.actionsLoading = false;
      state.universityForEdit = action.payload.universityForEdit;
      state.error = null;
    },

    // findFiles
    universitiesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createFile
    universityCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.university);
    },

    // updateFile
    universityUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.university.id) {
          return action.payload.file;
        }
        return entity;
      });
    },

    // deleteFile
    universityDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.universityId !== action.payload.id
      );
    },

    // deleteFiles
    universitiesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },

    // deleteFiles
    coutnriesFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.uniCuntries = action.payload.uniCuntries;
    },

    // // deleteFiles
    // universitiesReviewFetched: (state, action) => {
    //   state.error = null;
    //   state.actionsLoading = false;
    //   state.allUniReview = action.payload.allUniReview;
    // },

    // University All Review
    universitiesReviewFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.reviewEntities = entities;
      state.reviewTotalCount = totalCount;
    },

    // Review By university Id
    singleUniversityReviewFetched: (state, action) => {
      const { reviewSingleTotalCount, reviewSingleEntities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.reviewSingleEntities = reviewSingleEntities;
      state.reviewSingleTotalCount = reviewSingleTotalCount;
    },

    // Publish review
    reviewPublish: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.reviewEntities = state.reviewEntities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // feature Review
    reviewFeature: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.reviewEntities = state.reviewEntities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // unfeature Review
    reviewUnfeature: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.reviewEntities = state.reviewEntities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // fetch status overview of all review
    allUniStatusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.allUniReviewStatus = action.payload.allUniReviewStatus;
    },
    // fetch status overview of single university review
    singleUniStatusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.singleUniReviewStatus = action.payload.singleUniReviewStatus;
    },

    // fetch University List
    universityList: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.allUniversityOptions = action.payload.allUniversityOptions;
    },
    // fetch State List
    stateListFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.stateOptions = action.payload.stateOptions;
    },
    // fetch city List
    cityListFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.cityOptions = action.payload.cityOptions;
    },
    // delete review
    reviewDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.universityReviewId !== action.payload.id
      );
    },
    // delete review
    reviewTrashed: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.universityReviewId !== action.payload.id
      );
    },

    isTopUniversity: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
  },
});
