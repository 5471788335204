/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { openEditScholarship, openChangeStatusScholarship, openDeleteScholarship }
) {
  return (
    <>
      <a
        title="Edit Scholarship"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={() => openEditScholarship(row.universityScholarshipId)}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <i className="fa  fa-edit" aria-hidden="true"></i>
        </span>
      </a>
      <> </>
      <a
        title="Delete Scholarship"
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={() => {
          if (row.status !== "Deleted") {
            openChangeStatusScholarship(
              [parseInt(row.universityScholarshipId)],
              "Deleted"
            );
          } else {
            openDeleteScholarship([parseInt(row.universityScholarshipId)]);
          }
        }}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <i className="fa fa-trash" aria-hidden="true"></i>
        </span>
      </a>
    </>
  );
}
