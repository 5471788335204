/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/role-supports-aria-props */
import React from "react";
import SVG from "react-inlinesvg";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers";

export const SectionNavs = (data, level = 1) => {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const GenerateIcon = (info) => {
    if (info.iconImage && info.iconImage !== "") {
      return <SVG src={toAbsoluteUrl(info.iconImage)} />;
    } else if (info.iconClass && info.iconClass !== "") {
      return <i className={`menu-icon ${info.iconClass}`}></i>;
    } else {
      return <i className="menu-icon flaticon2-layers-2"></i>;
    }
  };

  if (data.children && data.children.length > 0) {
    const firstChilds = data.children;
    return (
      <li
        className={`menu-item menu-item-submenu ${getMenuItemActive(
          data?.link ?? "#",
          true
        )}`}
        aria-haspopup="true"
        data-menu-toggle="hover"
      >
        <NavLink className="menu-link menu-toggle" to={data.link ?? "#"}>
          <span className="svg-icon menu-icon">{GenerateIcon(data)}</span>
          <span className="menu-text">{data.menuTitle ?? ""}</span>
          <i className="menu-arrow" />
        </NavLink>

        <div className="menu-submenu ">
          <i className="menu-arrow" />
          <ul className="menu-subnav">
            <li className="menu-item  menu-item-parent" aria-haspopup="true">
              <span className="menu-link">
                <span className="menu-text">{data.menuTitle ?? ""}</span>
              </span>
            </li>
            {firstChilds.map((child, index) => {
              if (child.children && child.children.length > 0) {
                const secondChild = child.children;
                return (
                  <li
                    className={`menu-item menu-item-submenu  ${getMenuItemActive(
                      child.link ?? "#",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    key={`child${index}`}
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to={child.link ?? "#"}
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">{child.menuTitle ?? ""}</span>
                      <i className="menu-arrow" />
                    </NavLink>
                    <div className="menu-submenu">
                      <i className="menu-arrow" />
                      <ul className="menu-subnav">
                        {secondChild.map((child2, index) => {
                          return (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                child2.link ?? "#",
                                true
                              )}`}
                              aria-haspopup="true"
                              key={`child2${index}`}
                            >
                              <NavLink
                                className="menu-link"
                                to={child2.link ?? "#"}
                              >
                                <i className="menu-bullet menu-bullet-dot">
                                  <span />
                                </i>
                                <span className="menu-text">
                                  {child2.menuTitle ?? ""}
                                </span>
                              </NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </li>
                );
              } else {
                return (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      child.link ?? "#",
                      true
                    )}`}
                    aria-haspopup="true"
                    key={`child${index}`}
                  >
                    <NavLink className="menu-link" to={child.link ?? "#"}>
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">{child.menuTitle ?? ""}</span>
                    </NavLink>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </li>
    );
  } else {
    return (
      <li
        className={`menu-item ${getMenuItemActive(data.link ?? "#", false)}`}
        aria-haspopup="true"
      >
        <NavLink className="menu-link" to={`${data.link ?? "#"}`}>
          <span className="svg-icon menu-icon">{GenerateIcon(data)}</span>
          <span className="menu-text">{data.menuTitle ?? ""}</span>
        </NavLink>
      </li>
    );
  }
};

export default SectionNavs;
