import React, { useMemo } from "react";
import { CoursesTable } from "./course-table/CoursesTable";
import { CoursesFilter } from "./course-filter/CoursesFilter";

import { useCourseUIContext } from "./CourseUIContext";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";

export function CourseCard() {
  const courseUIContext = useCourseUIContext();

  const courseUIProps = useMemo(() => {
    return {
      ids: courseUIContext.ids,
      newCourse: courseUIContext.newCourse,
      openTrahCourse: courseUIContext.openTrahCourse,
    };
  }, [courseUIContext]);
  const selectedIds = courseUIProps.ids.map((item) => Number(item));
  return (
    <Card>
      <CardHeader title="All Courses">
        <CardHeaderToolbar>
          {selectedIds && selectedIds.length > 0 && (
            <button
              type="button"
              className="btn btn-danger ml-3"
              onClick={() => courseUIProps.openTrahCourse(selectedIds)}
            >
              Trash
            </button>
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CoursesFilter />
        <CoursesTable />
      </CardBody>
    </Card>
  );
}
