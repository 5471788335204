import { createSlice } from "@reduxjs/toolkit";

const initialUniState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  scholarshipForEdit: undefined,
  error: null,
  specializationOptions: [],
  allLevel: "",
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const scholarshipSlice = createSlice({
  name: "scholarship",
  initialState: initialUniState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getscholarshipById
    scholarshipFetched: (state, action) => {
      state.actionsLoading = false;
      state.scholarshipForEdit = action.payload.scholarshipForEdit;
      state.error = null;
    },

    // findscholarship
    allScholarshipFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createscholarship
    scholarshipCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },

    // updatescholarship
    scholarshipUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },

    // deletescholarship
    scholarshipDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.scholarshipId !== action.payload.id
      );
    },

    // deletescholarship
    scholarshipHardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.scholarshipId !== action.payload.id
      );
    },

    //fetch tag
    parentScholarshipFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.parentScholarship = action.payload.parentScholarship;
    },
    statusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.statusOverview = action.payload.statusOverview;
    },
    specializationFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.specializationOptions = action.payload.specializationOptions;
    },
    levelFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.allLevel = action.payload.allLevel;
    },
  },
});
