import { createSlice } from "@reduxjs/toolkit";

const initialUniState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  pageForEdit: undefined,
  error: null,
  authorOptions: [],
  pageTypeOption: [],
  pageReferenceOption: [],
  pageTemplateOption: [],
  pageGroupOption: [],
  statusOptions: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const pageSlice = createSlice({
  name: "pages",
  initialState: initialUniState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    //get University Profile
    profileFetched: (state, action) => {
      state.actionsLoading = false;
      state.uniProfile = action.payload.uniProfile;
      state.error = null;
    },

    // getPageById
    pageFetched: (state, action) => {
      state.actionsLoading = false;
      state.pageForEdit = action.payload.pageForEdit;
      state.error = null;
    },

    // findFiles
    pagesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // create Page
    pageCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
      return action.payload;
    },

    // update Page
    pageUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.pageId === action.payload.pageId) {
          return action.payload.pages;
        }
        return entity;
      });
    },

    // deleteFile
    pageTrashed: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.pageId !== action.payload.id
      );
    },
    // deleteFile
    pageDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.pageId !== action.payload.id
      );
    },

    // author fetch
    authorFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.authorOptions = action.payload.authorOptions;
    },
    // page type fetch
    pageTypeFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.pageTypeOption = action.payload.pageTypeOption;
    },
    // page reference fetch
    pageReferenceFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.pageReferenceOption = action.payload.pageReferenceOption;
    },
    // page template fetch
    pageTemplateFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.pageTemplateOption = action.payload.pageTemplateOption;
    },
    // page Group fetch
    pageGroupFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.pageGroupOption = action.payload.pageGroupOption;
    },
    pageStatusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.pageStatusOverview = action.payload.pageStatusOverview;
    },
  },
});
