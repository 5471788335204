import React, { useState, useEffect } from "react";
import "@techzolab/zodit/build/zodit.min.css";
import UniditEditor from "@techzolab/zodit-react"
import UniGlarray from "./UniGlarray";

let editorInstance =null;
export const fileBrows = (editor) => {
  document.querySelector('button[name="uniImageUploader"]').click();

  document
    .querySelector('button[name="insertimages"]')
    .addEventListener("click", function() {
      let data = document
        .querySelector('button[name="uniImageUploader"]')
        .getAttribute("data-srclink");

      if (data != null && data != "undefined" && data != "") {
        let img = `<img src="` + data + `" alt="ddd" width="300px">`;
        editor.s.insertHTML(`<p>` + img + `</p>`);
      }
    });
};

const buttons = [
  "undo",
  "redo",
  "|",
  "bold",
  "strikethrough",
  "underline",
  "italic",
  "|",
  "superscript",
  "subscript",
  "|",
  "align",
  "|",
  "ul",
  "ol",
  //   "outdent",
  //   "indent",
  "justify",
  "|",
  "font",
  "fontsize",
  "brush",
  "paragraph",
  "|",
  "image",
  {
    name: "gallery",
    tooltip: "Uni gallery",
    iconURL:
      "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgdmVyc2lvbj0iMS4xIj48dGl0bGU+UGhvdG88L3RpdGxlPjxnIGlkPSJQaG90byIgc3Ryb2tlPSIjNEE0QTRBIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHJlY3QgaWQ9IlJlY3RhbmdsZS0xNiIgeD0iMi41IiB5PSIyLjUiIHdpZHRoPSIxOSIgaGVpZ2h0PSIxOSIgcng9IjIiLz48cGF0aCBpZD0iUGF0aC05IiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBkPSJNNC44NjggMTlsMy43NjYtOS4wNDggNC4yMyA3LjQzNiAyLjQwNC00LjAwN0wxOS4wNjIgMTl6Ii8+PGNpcmNsZSBpZD0iT3ZhbCIgY3g9IjE2IiBjeT0iNy4xMzMzMzMzMyIgcj0iMi4xMzMzMzMzMyIvPjwvZz48bWV0YWRhdGE+PHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIiB4bWxuczpyZGZzPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwLzAxL3JkZi1zY2hlbWEjIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iPjxyZGY6RGVzY3JpcHRpb24gYWJvdXQ9Imh0dHBzOi8vaWNvbnNjb3V0LmNvbS9sZWdhbCNsaWNlbnNlcyIgZGM6dGl0bGU9InBob3RvIiBkYzpkZXNjcmlwdGlvbj0icGhvdG8iIGRjOnB1Ymxpc2hlcj0iSWNvbnNjb3V0IiBkYzpkYXRlPSIyMDE4LTA2LTI4IiBkYzpmb3JtYXQ9ImltYWdlL3N2Zyt4bWwiIGRjOmxhbmd1YWdlPSJlbiI+PGRjOmNyZWF0b3I+PHJkZjpCYWc+PHJkZjpsaT5BdmluYXNoIFA8L3JkZjpsaT48L3JkZjpCYWc+PC9kYzpjcmVhdG9yPjwvcmRmOkRlc2NyaXB0aW9uPjwvcmRmOlJERj48L21ldGFkYXRhPjwvc3ZnPg==",
    exec: (editor) => {
      fileBrows(editor);
    },

    //   exec: function(editor) {

    //     return ()

    //     //   let img= `<img src="https://dev.myunisearch.com/assets/images/home/Hero%20Img%203.png" alt="ddd" width="300px">`
    //     //   editor.s.insertHTML(`<p>`+img+`</p>`);
    //   }
  },
  "link",
  "table",
  "|",
  "hr",
  "eraser",
  "copyformat",
  "|",
  "fullsize",
  "selectall",
  "print",
  "|",
  "source",
  "|",
];

const editorConfig = {
  readonly: false,
  events: {  afterInit: (instance) => { 
    editorInstance = instance
  } },
  toolbar: true,
  spellcheck: true,
  language: "en",
  toolbarButtonSize: "medium",
  toolbarAdaptive: false,
  showCharsCounter: true,
  showWordsCounter: true,
  showXPathInStatusbar: false,
  askBeforePasteHTML: true,
  askBeforePasteFromWord: true,
  //defaultActionOnPaste: "insert_clear_html",
  buttons: buttons,
  uploader: {
    insertImageAsBase64URI: true,
  },
  width: 800,
  height: 300,
};

// buttonClick = (event) => { 
//   this.unidit.selection.insertHTML('<a href="">Anchor Tag</a>'); 
// };

function UnieditorText({ name, contents, onChange, height, width,placeholder }) {
  const initialContent = contents ?? ``;

  const [data, setData] = useState(initialContent);
  const [srcImage, setSrc] = useState(null);

  const target_name = name || "description";
  editorConfig.width = width || "auto";
  editorConfig.height = height || 300;
  if(placeholder)
    editorConfig.placeholder = placeholder;


  return (
    <div style={{ margin: "0 auto" }}>
      <UniditEditor
        value={contents}
        config={editorConfig}
        onChange={(value) => {
          const target = { name: target_name, value: value , events: {selection: editorInstance.selection}};
          setData(value);
          onChange({ target });
        }}
      />

      <UniGlarray
        name="uniImageUploader"
        onChange={(event) => {
          setSrc(event.target.src);
        }}
      />
    </div>
  );
}

export default UnieditorText;
