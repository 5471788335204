import { createSlice } from "@reduxjs/toolkit";

const initialUniState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  leadsFormForEdit: {
    title: "",
    description: "",
    template: "default",
    innerSubject: "",
    outerSubject: "",
    options: {
      eventDate: new Date(),
      startTime: null,
      endTime: null,
      featuresSection: [
        {
          featureSubtitle: "",
          featureTitle: "",
          featuresCard: [
            {
              cardIconSrc: undefined,
              cardTitle: undefined,
              cardDescription: undefined,
              cardColor: "#4A2ED7",
            },
          ],
        },
      ],
    },
    featureImageId: undefined,
    featureImageSrc: "",
    leadFormId: undefined,
  },
  error: null,
  leadFormOptions: [],
  allLeads: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const leadsFormSlice = createSlice({
  name: "leadsForm",
  initialState: initialUniState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getleadsFormById
    leadsFormFetched: (state, action) => {
      state.actionsLoading = false;
      state.leadsFormForEdit = action.payload.leadsFormForEdit;
      state.error = null;
    },
    // getleadsFormById
    formFieldNameFetched: (state, action) => {
      state.actionsLoading = false;
      state.formFieldNames = action.payload.formFieldNames;
      state.error = null;
    },

    // findleadsForm
    allLeadsFormFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createleadsForm
    leadsFormCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },

    // updateleadsForm
    leadsFormUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // fetch Category without children
    leadFormDropdownFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.leadFormOptions = action.payload.leadFormOptions;
    },
    // deleteleadsForm
    leadsFormHardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    //fetch leads
    leadsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.allLeads = action.payload.allLeads;
    },
  },
});
