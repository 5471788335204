import { createSlice } from "@reduxjs/toolkit";

const initialCoursesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  courseForEdit: undefined,
  lastError: null,
  courseLevel: [],
  specializations: [],
  departments: [],
  prerequsit: undefined,
  rankingType: [],
  rankingCompany: [],
  exams: [],
  courseRanking: [],
  editableRanking: undefined,
  admissionSessions: [],
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const coursesSlice = createSlice({
  name: "courses",
  initialState: initialCoursesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getCourseById
    courseFetched: (state, action) => {
      state.actionsLoading = false;
      state.courseForEdit = action.payload.courseForEdit;
      state.error = null;
    },

    // findFCourses
    coursesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createCourse
    courseCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.course);
    },

    // updateCourse
    courseUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },

    // delete Course
    courseDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.courseId != action.payload.id
      );
    },
    // delete Course
    courseHardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.courseId != action.payload.id
      );
    },

    // delete Courses
    coursesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },

    // Fetch Courses Level
    courseLevelFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.courseLevel = action.payload.courseLevel;
    },

    // Fetch Courses Level
    specializationFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.specializations = action.payload.specializations;
    },
    // Fetch Courses Level
    departmentFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.departments = action.payload.departments;
    },

    // get Prerequisit by Level Id
    prerequsitFetched: (state, action) => {
      state.actionsLoading = false;
      state.prerequsit = action.payload.prerequsit;
      state.error = null;
    },

    // get Prerequisit by Level Id
    rankingTypeFetched: (state, action) => {
      state.actionsLoading = false;
      state.rankingType = action.payload.rankingType;
      state.error = null;
    },

    // get Prerequisit by Level Id
    rankingCompanyFetched: (state, action) => {
      state.actionsLoading = false;
      state.rankingCompany = action.payload.rankingCompany;
      state.error = null;
    },

    // get Prerequisit by Level Id
    allExamsFetched: (state, action) => {
      state.actionsLoading = false;
      state.exams = action.payload.exams;
      state.error = null;
    },

    // get Admission Sessions
    allAdmissionSessionFetched: (state, action) => {
      state.actionsLoading = false;
      state.admissionSessions = action.payload.admissionSessions;
      state.error = null;
    },

    // get Prerequisit by Level Id
    courseRankingFetched: (state, action) => {
      state.actionsLoading = false;
      state.courseRanking = action.payload.courseRanking;
      state.error = null;
    },

    // get Prerequisit by Level Id
    rankSingleFetched: (state, action) => {
      state.actionsLoading = false;
      state.editableRanking = action.payload.editableRanking;
      state.error = null;
    },

    // get Prerequisit by Level Id
    clearRankFetched: (state, action) => {
      state.actionsLoading = false;
      state.editableRanking = action.payload.editableRanking;
      state.error = null;
    },

    // createCourse
    courseRankCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.courseRanking = action.payload.courseRanking;
      // state.courseRanking.push(action.payload.courseRanking);
    },

    // createCourse
    courseRankDeleted: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.courseRanking = state.courseRanking.filter(
        (el) => el.id !== action.payload.id
      );
    },

    // createCourse
    rankingUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.courseRanking = action.payload.courseRanking;
    },
    statusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.statusOverview = action.payload.statusOverview;
    },
  },
});
