// import axios from "axios";
import {
  postAPI,
  getAPI,
  putAPI,
  deleteAPI,
  patchAPI,
} from "../../../../../_unisearch/util/authUtils";

export const API_ENDPOINTS = `${process.env.REACT_APP_API_URL}`;
export const COURSE_ENDPOINTS = `/admin/university-course`;

// CREATE =>  POST: add a new course to the server
export function createCourse(course) {
  return postAPI(`${COURSE_ENDPOINTS}/create-course`, course);
}

// READ
export function getAllCourses() {
  return getAPI(COURSE_ENDPOINTS);
}

// Get course by ID
export function getCourseById(id) {
  return getAPI(`${COURSE_ENDPOINTS}/${id}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCourses(university, queryParams) {
  const { filter, pageNumber, pageSize, sortField, sortOrder } = queryParams;

  return postAPI(`/admin/university-course/findcourses/${university}`, {
    filter: filter,
    pageNumber: pageNumber,
    pageSize: pageSize,
    sortField: sortField,
    sortOrder: sortOrder,
  });
}

// UPDATE => PUT: update the country on the server
export function updateCourse(course, id) {
  return patchAPI(`${COURSE_ENDPOINTS}/${id}`, course);
}
// UPDATE => PUT: update the country on the server
export function updateCourseIntake(intake, id) {
  return patchAPI(`${COURSE_ENDPOINTS}/update-course-intake/${id}`, intake);
}
export function updateCourseFee(fee, id) {
  return patchAPI(`${COURSE_ENDPOINTS}/update-tuition-fees/${id}`, fee);
}
export function updateCourseExpense(expense, id) {
  return patchAPI(`${COURSE_ENDPOINTS}/other-expense/${id}`, expense);
}
export function updateMediaFile(media, id) {
  return postAPI(`${COURSE_ENDPOINTS}/media-upload/${id}`, media);
}
export function updateSource(value, id) {
  return patchAPI(`${COURSE_ENDPOINTS}/course-source/${id}`, value);
}

// DELETE => delete the Course from the server
export function deleteCourse(id) {
  // return deleteAPI(`${COURSE_ENDPOINTS}/${id}`);
  return patchAPI(`${COURSE_ENDPOINTS}`, id);
}

// DELETE Course by ids
export function deleteCourses(ids) {
  return postAPI(`${COURSE_ENDPOINTS}/deleteCourses`, { ids });
}

export function getAllCourseLevel() {
  return getAPI(`study/course/level-dropdown`);
}

//get specialization
export function getDepartment() {
  return getAPI(`/public/specialization`);
}
//get Department
export function getAllSpecialization(id) {
  return getAPI(`/public/department/${id}`);
}

// Get Prerequisit by level ID
export function getPrerequsiteByLevel(id) {
  return getAPI(`/public/education/${id}`);
}

// Get Prerequisit by level ID
export function getRankingType() {
  return getAPI(`admin/ranking-type`);
}

// Get Prerequisit by level ID
export function getRankingCompany() {
  return getAPI(`admin/ranking-company`);
}

// Get Prerequisit by level ID
export function getAllExams() {
  return getAPI(`study/course-exams`);
}

// Get Admission Session
export function getAdmissionSessions() {
  return getAPI(`study/admission-session/dropdown`);
}

// Get Prerequisit by level ID
export function getCourseRanking(id) {
  return getAPI(`${API_ENDPOINTS}/ranking/course/${id}`);
}

// Get Prerequisit by level ID
export function getSingleRanking(id) {
  return getAPI(`${API_ENDPOINTS}/ranking/${id}`);
}

// CREATE =>  POST: add a new course Ranking to the server
export function createCourseRank(data) {
  return postAPI(`${API_ENDPOINTS}/ranking/course`, data);
}

// DELETE COURSE RANKING
export function deleteCourseRanking(id) {
  return deleteAPI(`${API_ENDPOINTS}/ranking/${id}`);
}
// DELETE COURSE RANKING
export function updateCourseRanking(data, id) {
  return putAPI(`${API_ENDPOINTS}/ranking/${id}`, data);
}

export function fetchStatus(uniId) {
  return getAPI(`admin/university-course/status-overview/${uniId}`);
}
export function hardDeleteCourse(courseId) {
  return deleteAPI(`admin/university-course/${courseId}`);
}
export function deleteCourseIntake(id) {
  return deleteAPI(`admin/university-course/delete-intake/${id}`);
}
export function deleteCourseFee(id) {
  return deleteAPI(`admin/university-course/delete-tuition-fee/${id}`);
}
export function deleteCourseExpense(id) {
  return deleteAPI(`admin/university-course/other-expense/${id}`);
}
