import { createSlice } from "@reduxjs/toolkit";

const initialUniState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  leadsContactForEdit: {
    title: "",
    properties: [
      {
        id: 0,
        name: "",
        label: "",
        type: "",
        isRequired: 0,
        columnSize: "",
        placeholder: "",
      },
    ],
  },
  error: null,
  authorOptions: [],
  categoryOptions: [],
  leadsContactTemplateOptions: [],
  leadsContactTags: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const leadsContactSlice = createSlice({
  name: "leadsContact",
  initialState: initialUniState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getleadsContactById
    leadsContactFetched: (state, action) => {
      state.actionsLoading = false;
      state.leadsContactForEdit = action.payload.leadsContactForEdit;
      state.error = null;
    },

    // findleadsContact
    allLeadsContactFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createleadsContact
    leadsContactCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },

    // updateleadsContact
    leadsContactUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },

    // deleteleadsContact
    leadsContactStatusChanged: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },

    // fetch Category without children
    categoryDropdownFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.categoryOptions = action.payload.categoryOptions;
    },
    // fetch Category with children
    categoriesFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.leadsContactCategory = action.payload.leadsContactCategory;
    },

    //fetch author
    authorFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.authorOptions = action.payload.authorOptions;
    },
    // fetch leadsContact template
    leadsContactTemplateFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.leadsContactTemplateOptions =
        action.payload.leadsContactTemplateOptions;
    },
    //fetch tag
    tagsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.leadsContactTags = action.payload.leadsContactTags;
    },
    statusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.statusOverview = action.payload.statusOverview;
    },
    playlistFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.leadsContactPlaylist = action.payload.leadsContactPlaylist;
    },
    // createTag
    tagCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },
    // deleteleadsContact
    leadsContactHardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
  },
});
