import { Formik } from "formik";
import { isEqual } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { useCourseUIContext } from "../CourseUIContext";
import * as actions from "../_redux/courses/coursesAction";
import "./dateRangeStyles.scss";

import { addDays } from "date-fns";
import moment from "moment/moment";
import { DateRangePicker } from "react-date-range";
const prepareFilter = (queryParams, values) => {
  const {
    status,
    courseLevelId,
    searchText,
    universityId,
    specializationParent,
    specialization,
    country,
    createDate,
    updateDate,
  } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};

  filter.status = status;
  filter.universityId = universityId;
  filter.specializationParent = specializationParent;
  filter.specialization = specialization;
  filter.country = country;
  filter.createDate = createDate;
  filter.updateDate = updateDate;

  // Filter by level
  filter.courseLevelId = courseLevelId !== "" ? +courseLevelId : undefined;

  // Filter by all fields
  if (searchText) {
    filter.courseName = searchText.trim();
  }
  newQueryParams.filter = filter;

  return newQueryParams;
};

export function CoursesFilter(props) {
  const [uniName, setUniName] = useState();
  const [uniSearchText, setUniSearchText] = useState();
  const [uniPageNumber, setUniPageNumber] = useState();
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown1(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target)
      ) {
        setShowDropdown2(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  const [updateDateRange, setUpdateDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  // Courses UI Context
  const coursesUIContext = useCourseUIContext();
  const dispatch = useDispatch();

  const {
    listLoading,
    currentState,
    uniDropdown,
    statusOverview,
    departments,
    specializations,
    hasMore,
    countryDropdown,
  } = useSelector(
    (state) => ({
      listLoading: state.courses.listLoading,
      currentState: state.allCourse,
      uniDropdown: state.allCourse.uniDropdown,
      statusOverview: state.allCourse.statusOverview,
      departments: state.allCourse.departments,
      specializations: state.allCourse.specializations,
      countryDropdown: state.allCourse.countryDropdown,
      hasMore: state.allCourse.hasMore,
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(actions.fetchStatus());
    dispatch(actions.fetchStatus());
    dispatch(actions.fetchDepartment());
    dispatch(actions.fetchUniDropdownList());
    dispatch(actions.fetchCountry());
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.fetchUniDropdownList(uniPageNumber, uniSearchText));
  }, [uniPageNumber, uniSearchText, dispatch]);

  const coursesUIProps = useMemo(() => {
    return {
      queryParams: coursesUIContext.queryParams,
      setQueryParams: coursesUIContext.setQueryParams,
    };
  }, [coursesUIContext]);
  const { filteredStatusOverview } = currentState;
  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(coursesUIProps.queryParams, values);
    if (!isEqual(newQueryParams, coursesUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      coursesUIProps.setQueryParams(newQueryParams);
    }
  };
  const loadOptions = (search, loadedOptions, { page }) => {
    setUniPageNumber(page);
    // dispatch(actions.fetchUniDropdownList(page, search));
    return {
      options: uniDropdown,
      hasMore: hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <>
      <Formik
        initialValues={{
          status: coursesUIProps?.queryParams?.filter?.status,
          courseLevelId: "",
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit} className="mb-5">
            <div className="dynamic_filter">
              <h4>{uniName ? uniName.label : "All"} Course Information</h4>
              <div className="row ml-1 mr-1">
                <div
                  className={`custom-table-count col p-0 mr-3 ${
                    coursesUIProps &&
                    coursesUIProps?.queryParams?.filter?.courseLevelId === 1
                      ? "all_border"
                      : ""
                  }`}
                >
                  <div
                    className="custom-table-text"
                    onClick={() => {
                      setFieldValue("courseLevelId", 1);
                      handleSubmit();
                    }}
                  >
                    <p
                      className={`count ${
                        coursesUIProps &&
                        coursesUIProps?.queryParams?.filter?.courseLevelId === 1
                          ? "all_active"
                          : ""
                      }`}
                    >
                      {filteredStatusOverview &&
                      filteredStatusOverview.undergraduate
                        ? filteredStatusOverview.undergraduate
                        : 0}
                    </p>
                    <p className="name">Undergraduate</p>
                  </div>
                  <div
                    className={`active_bar ${
                      coursesUIProps &&
                      coursesUIProps?.queryParams?.filter?.courseLevelId === 1
                        ? "all"
                        : ""
                    }`}
                  ></div>
                </div>
                <div
                  className={`custom-table-count col p-0 mr-3 ${
                    coursesUIProps &&
                    coursesUIProps?.queryParams?.filter?.courseLevelId === 2
                      ? "all_border"
                      : ""
                  }`}
                >
                  <div
                    className="custom-table-text"
                    onClick={() => {
                      setFieldValue("courseLevelId", 2);
                      handleSubmit();
                    }}
                  >
                    <p
                      className={`count ${
                        coursesUIProps &&
                        coursesUIProps?.queryParams?.filter?.courseLevelId === 2
                          ? "all_active"
                          : ""
                      }`}
                    >
                      {filteredStatusOverview &&
                      filteredStatusOverview.postgraduate
                        ? filteredStatusOverview.postgraduate
                        : 0}
                    </p>
                    <p className="name">Postgraduate</p>
                  </div>
                  <div
                    className={`active_bar ${
                      coursesUIProps &&
                      coursesUIProps?.queryParams?.filter?.courseLevelId === 2
                        ? "all"
                        : ""
                    }`}
                  ></div>
                </div>
                <div
                  className={`custom-table-count col p-0 mr-3 ${
                    coursesUIProps &&
                    coursesUIProps?.queryParams?.filter?.status === undefined
                      ? "all_border"
                      : ""
                  }`}
                >
                  <div
                    className="custom-table-text"
                    onClick={() => {
                      setFieldValue("status", undefined);
                      handleSubmit();
                    }}
                  >
                    <p
                      className={`count ${
                        coursesUIProps &&
                        coursesUIProps?.queryParams?.filter?.status ===
                          undefined
                          ? "all_active"
                          : ""
                      }`}
                    >
                      {filteredStatusOverview && filteredStatusOverview.all
                        ? filteredStatusOverview.all
                        : 0}
                    </p>
                    <p className="name">Total Course</p>
                  </div>
                  <div
                    className={`active_bar ${
                      coursesUIProps &&
                      coursesUIProps?.queryParams?.filter?.status === undefined
                        ? "all"
                        : ""
                    }`}
                  ></div>
                </div>
                <div
                  className={`custom-table-count col p-0 mr-3 ${
                    coursesUIProps &&
                    coursesUIProps?.queryParams?.filter?.status === "Active"
                      ? "published_border"
                      : ""
                  }`}
                >
                  <div
                    className="custom-table-text"
                    onClick={() => {
                      setFieldValue("status", "Active");
                      handleSubmit();
                    }}
                  >
                    <p
                      className={`count ${
                        coursesUIProps &&
                        coursesUIProps?.queryParams?.filter?.status === "Active"
                          ? "published_active"
                          : ""
                      }`}
                    >
                      {filteredStatusOverview && filteredStatusOverview.publish
                        ? filteredStatusOverview.publish
                        : 0}
                    </p>
                    <p className="name">Active</p>
                  </div>
                  <div
                    className={`active_bar ${
                      coursesUIProps &&
                      coursesUIProps?.queryParams?.filter?.status === "Active"
                        ? "published"
                        : ""
                    }`}
                  ></div>
                </div>
                <div
                  className={`custom-table-count col p-0 mr-3 ${
                    coursesUIProps &&
                    coursesUIProps?.queryParams?.filter?.status === "Draft"
                      ? "draft_border"
                      : ""
                  }`}
                >
                  <div
                    className="custom-table-text"
                    onClick={() => {
                      setFieldValue("status", "Draft");
                      handleSubmit();
                    }}
                  >
                    <p
                      className={`count ${
                        coursesUIProps &&
                        coursesUIProps?.queryParams?.filter?.status === "Draft"
                          ? "draft_active"
                          : ""
                      }`}
                    >
                      {filteredStatusOverview && filteredStatusOverview.draft
                        ? filteredStatusOverview.draft
                        : 0}
                    </p>
                    <p className="name">Draft</p>
                  </div>
                  <div
                    className={`active_bar ${
                      coursesUIProps &&
                      coursesUIProps?.queryParams?.filter?.status === "Draft"
                        ? "draft"
                        : ""
                    }`}
                  ></div>
                </div>
                <div
                  className={`custom-table-count col p-0 ${
                    coursesUIProps &&
                    coursesUIProps?.queryParams?.filter?.status === "Deleted"
                      ? "trashed_border"
                      : ""
                  }`}
                >
                  <div
                    className="custom-table-text"
                    onClick={() => {
                      setFieldValue("status", "Deleted");
                      handleSubmit();
                    }}
                  >
                    <p
                      className={`count ${
                        coursesUIProps &&
                        coursesUIProps?.queryParams?.filter?.status ===
                          "Deleted"
                          ? "trashed_active"
                          : ""
                      }`}
                    >
                      {filteredStatusOverview && filteredStatusOverview.trash
                        ? filteredStatusOverview.trash
                        : 0}
                    </p>
                    <p className="name">Trash</p>
                  </div>
                  <div
                    className={`active_bar ${
                      coursesUIProps &&
                      coursesUIProps?.queryParams?.filter?.status === "Deleted"
                        ? "trashed"
                        : ""
                    }`}
                  ></div>
                </div>
              </div>
            </div>

            <div className="filterForm mt-10 row">
              <div
                className={
                  "form-control btn btn-outline-danger btn-md col-md-1"
                }
                onClick={() => {
                  resetForm();
                  setFieldValue("specialization", undefined);
                  dispatch(actions.fetchSpecialization());
                  handleSubmit();
                  setUniName();
                  setUpdateDateRange([
                    {
                      startDate: new Date(),
                      endDate: addDays(new Date(), 0),
                      key: "selection",
                    },
                  ]);
                  setDateRange([
                    {
                      startDate: new Date(),
                      endDate: addDays(new Date(), 0),
                      key: "selection",
                    },
                  ]);
                }}
              >
                Reset
              </div>
              <div ref={dropdownRef}>
                <div
                  style={{ position: "relative" }}
                  className={`btn btn-outline-secondary btn-xl ${
                    values?.createDate ? "buttonColor" : ""
                  }`}
                  onClick={() => setShowDropdown1(!showDropdown1)}
                >
                  Created At
                </div>

                {showDropdown1 && (
                  <div style={{ position: "absolute", zIndex: "111" }}>
                    <div className="date-range-picker">
                      <DateRangePicker
                        onChange={(item) => setDateRange([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        maxDate={addDays(new Date(), 0)}
                        ranges={dateRange}
                        calendarFocus="backwards"
                        direction="horizontal"
                      />
                      <div className="calendar-footer justify-content-end pb-4 pr-4">
                        <div className="mr-5 mt-2">
                          Selected Range :{" "}
                          {moment(dateRange[0].startDate).format("YYYY-MM-DD")}{" "}
                          - {moment(dateRange[0].endDate).format("YYYY-MM-DD")}
                        </div>
                        <div
                          className="btn btn-outline-secondary btn-sm mr-2"
                          onClick={() => {
                            setDateRange([
                              {
                                startDate: new Date(),
                                endDate: addDays(new Date(), 0),
                                key: "selection",
                              },
                            ]);
                            setFieldValue("createDate", undefined);
                            handleSubmit();
                          }}
                        >
                          Clear
                        </div>
                        <div
                          className="btn btn-primary btn-sm mr-4"
                          onClick={() => {
                            const dateString = `${moment(
                              dateRange[0].startDate
                            ).format("YYYY-MM-DD")} - ${moment(
                              dateRange[0].endDate
                            ).format("YYYY-MM-DD")}`;
                            setFieldValue("createDate", dateString);
                            handleSubmit();
                          }}
                        >
                          Apply
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div ref={dropdownRef2}>
                <div
                  style={{ position: "relative" }}
                  className={`btn btn-outline-secondary btn-xl ${
                    values?.updateDate ? "buttonColor" : ""
                  }`}
                  onClick={() => setShowDropdown2(!showDropdown2)}
                >
                  Updated At
                </div>

                {showDropdown2 && (
                  <div style={{ position: "absolute", zIndex: "111" }}>
                    <div className="date-range-picker">
                      <DateRangePicker
                        onChange={(item) => {
                          setUpdateDateRange([item.selection]);
                        }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        maxDate={addDays(new Date(), 0)}
                        ranges={updateDateRange}
                        calendarFocus="backwards"
                        direction="horizontal"
                      />
                      <div className="justify-content-end pb-4 calendar-footer pr-5">
                        <div className="mr-5 mt-2">
                          Selected Range :{" "}
                          {moment(updateDateRange[0].startDate).format(
                            "YYYY-MM-DD"
                          )}{" "}
                          -{" "}
                          {moment(updateDateRange[0].endDate).format(
                            "YYYY-MM-DD"
                          )}
                        </div>
                        <div
                          className="btn btn-outline-alert btn-sm mr-2"
                          onClick={() => {
                            setUpdateDateRange([
                              {
                                startDate: new Date(),
                                endDate: addDays(new Date(), 0),
                                key: "selection",
                              },
                            ]);
                            setFieldValue("updateDate", undefined);
                            handleSubmit();
                          }}
                        >
                          Clear
                        </div>
                        <div
                          className="btn btn-primary btn-sm mr-2"
                          onClick={() => {
                            const dateString = `${moment(
                              updateDateRange[0].startDate
                            ).format("YYYY-MM-DD")} - ${moment(
                              updateDateRange[0].endDate
                            ).format("YYYY-MM-DD")}`;
                            setFieldValue("updateDate", dateString);
                            handleSubmit();
                          }}
                        >
                          Apply
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col p-0 m-0">
                <Select
                  placeholder="Filter by Preference"
                  name="specializationParent"
                  options={departments}
                  onBlur={handleBlur}
                  onChange={(item) => {
                    setFieldValue("specializationParent", parseInt(item.value));
                    setFieldValue("specialization", undefined);
                    dispatch(actions.fetchSpecialization(parseInt(item.value)));
                    handleSubmit();
                  }}
                  value={
                    departments &&
                    departments.filter(
                      (item) =>
                        parseInt(item.value) ===
                        parseInt(values.specializationParent)
                    )
                  }
                />
                <small className="form-text text-muted">
                  <b>Filter</b> By Preference
                </small>
              </div>
              <div className="col p-0 m-0">
                <Select
                  placeholder="Filter by Specialization"
                  name="specialization"
                  options={specializations}
                  onBlur={handleBlur}
                  onChange={(item) => {
                    setFieldValue("specialization", parseInt(item.value));
                    handleSubmit();
                  }}
                  value={
                    values.specialization && specializations
                      ? specializations.filter(
                          (item) =>
                            parseInt(item.value) ===
                            parseInt(values.specialization)
                        )
                      : []
                  }
                />
                <small className="form-text text-muted">
                  <b>Filter</b> By Specialization
                </small>
              </div>

              <div className="col p-0 m-0">
                <Select
                  placeholder="Filter by Country"
                  name="country"
                  options={countryDropdown}
                  onBlur={handleBlur}
                  onChange={(item) => {
                    setFieldValue("country", parseInt(item.value));
                    handleSubmit();
                  }}
                  value={
                    countryDropdown &&
                    countryDropdown.filter(
                      (item) =>
                        parseInt(item.value) === parseInt(values.country)
                    )
                  }
                />
                <small className="form-text text-muted">
                  <b>Filter</b> By Country
                </small>
              </div>
              <div className="col p-0 m-0">
                <AsyncPaginate
                  value={[uniName]}
                  loadOptions={loadOptions}
                  placeholder="Filter By University"
                  onInputChange={(e) => {
                    setUniSearchText(e);
                    setUniPageNumber(1);
                  }}
                  onChange={(item) => {
                    setFieldValue("universityId", parseInt(item.value));
                    setUniName({ label: item.label, value: item.value });
                    handleSubmit();
                  }}
                  additional={{
                    page: 1,
                  }}
                />
                <small className="form-text text-muted">
                  <b>Filter</b> By University
                </small>
              </div>

              <div className="col p-0 m-0">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                  onBlur={handleSubmit}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all Course Name or slug
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
