import { countries } from "country-data";
import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { swalError } from "../../../_unisearch/helpers";
import { getAPI } from "../../../_unisearch/util/authUtils";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../controls";
export function VisitorMap({ timeValue, dateChange, props }) {
  const [mapOverview, setMapOverview] = useState("map");
  const [graphData, setGraphData] = useState([["Country", "Popularity"]]);
  const [countryData, setCountryData] = useState([]);
  const [loading, setLoading] = useState();

  const siteURL = process.env.REACT_APP_GRAPH_URL;
  const graphSecret = process.env.REACT_APP_ANALYSIS_SECRET_KEY;
  useEffect(() => {
    let unmount = false;
    const fetchCountryDataList = async () => {
      try {
        if (unmount) return;
        await getAPI(
          `https://analytics.myunisearch.com/api/stats/${siteURL}/countries?period=${timeValue}&date=${dateChange}&filters={}&auth=${graphSecret}`
        ).then((res) => {
          if (!res.error) {
            let countryData = [["Country", "Visitors"]];
            res.map((item, i) => {
              countryData.push([`${countries[item.name].name}`, item.count]);
            });
            setGraphData(countryData);
            let formattedData = res.map((item, i) => {
              return {
                country: countries[item.name].alpha2,
                name: countries[item.name].name,
                value: item.count,
                percentage: item.percentage,
              };
            });
            setCountryData(formattedData);
          }
          if (res.error) {
            swalError(res.data);
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchCountryDataList();
    return () => {
      unmount = true;
    };
  }, [timeValue]);
  const options = {
    colorAxis: {
      colors: ["#F47F20", "#3699FF"],
    },
    defaultColor: "#F47F20",
    legend: "none",
  };
  return (
    <Card>
      <CardHeader title="Countries">
        <CardHeaderToolbar>
          <div
            className={`btn ${
              mapOverview === "map" ? "btn-primary" : "btn-outline-primary"
            } btn-sm mr-2`}
            onClick={() => setMapOverview("map")}
          >
            Map
          </div>
          <div
            className={`btn ${
              mapOverview !== "map" ? "btn-primary" : "btn-outline-primary"
            } btn-sm`}
            onClick={() => setMapOverview("mapDetails")}
          >
            Countries
          </div>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {mapOverview === "map" ? (
          <Chart
            chartType="GeoChart"
            data={graphData}
            width="100%"
            height="400px"
            legendToggle
            options={options}
            mapsApiKey="AIzaSyAqo9H5Mf9lwjZvOpBKjnq-qSO2W9l32GQ"
          />
        ) : (
          <div className="country_stats_wrapper">
            <div className="country_stats_title">
              <span>COUNTRY</span>
              <span>USERS</span>
            </div>
            <ul>
              {countryData.map((item, i) => {
                return (
                  <li key={`${item.percentage}statData`}>
                    <span>{item.name}</span>
                    <span>{item.value}</span>
                    <div
                      className="line"
                      style={{ width: `${item.percentage}%` }}
                    ></div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </CardBody>
    </Card>
  );
}
