import React from "react";
import { CourseUIProvider } from "./CourseUIContext";
import { CourseCard } from "./CourseCard";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import * as actions from "./_redux/courses/coursesAction";
export function AllUniCourses({ history }) {
  let { slug } = useParams();
  const dispatch = useDispatch();
  const courseUIEvents = {
    newCourse: () => {
      history.push(`/university/${slug}/course/new`);
    },
    openEditCourse: (id, slug) => {
      history.push(`/courses/${id}/edit`);
    },
    openTrahCourse: (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "To trash this item?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, trash it!",
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            dispatch(actions.deleteCourse(id));
            Swal.fire("Trashed!", "Course has been trashed.", "success");
            setTimeout(() => {
              history.push(`/courses`);
            }, 100);
          } catch (error) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          }
        }
      });
    },
    openDeleteCourse: (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "To delete this item?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            dispatch(actions.hardDeleteCourse(id));
            Swal.fire("Deleted!", "Course has been Deleted.", "success");
            setTimeout(() => {
              history.push(`/courses`);
            }, 100);
          } catch (error) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          }
        }
      });
    },
  };

  return (
    <CourseUIProvider courseUIEvents={courseUIEvents}>
      <CourseCard />
    </CourseUIProvider>
  );
}
