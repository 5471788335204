import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./ScholarshipUIHelpers";

const ScholarshipUIContext = createContext();

export function useScholarshipUIContext() {
  return useContext(ScholarshipUIContext);
}

export function ScholarshipUIProvider({ scholarshipUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);

  const [ids, setIds] = useState([]);

  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initScholarship = {
    id: undefined,
    scholarshipName: "",
    alt: "",
    src: "",
    tag: "",
    mimetype: "",
    status: "Active",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initScholarship,
    newScholarship: scholarshipUIEvents?.newScholarship,
    openEditScholarship: scholarshipUIEvents?.openEditScholarship,
    openChangeStatusScholarship:
      scholarshipUIEvents?.openChangeStatusScholarship,
    openDeleteScholarship: scholarshipUIEvents?.openDeleteScholarship,
    openFetchScholarshipsDialog:
      scholarshipUIEvents?.openFetchScholarshipsDialog,
    openFetchScholarship: scholarshipUIEvents?.openFetchScholarship,
  };

  return (
    <ScholarshipUIContext.Provider value={value}>
      {children}
    </ScholarshipUIContext.Provider>
  );
}
