import React, { useEffect, useState } from "react";
import { swalError } from "../../../_unisearch/helpers";
import { getAPI } from "../../../_unisearch/util/authUtils";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../controls";
export function Devices({ timeValue, dateChange, props }) {
  const [devices, setDevices] = useState([]);
  const [deviceOverview, setDeviceOverview] = useState("Screen Size");
  const siteURL = process.env.REACT_APP_GRAPH_URL;
  const graphSecret = process.env.REACT_APP_ANALYSIS_SECRET_KEY;
  useEffect(() => {
    let unmount = false;
    const fetchDeviceDataList = async () => {
      try {
        if (unmount) return;
        await getAPI(
          `https://analytics.myunisearch.com/api/stats/${siteURL}/${
            deviceOverview === "Browser"
              ? "browsers"
              : deviceOverview === "OS"
              ? "operating-systems"
              : "screen-sizes"
          }?period=${timeValue}&date=${dateChange}&filters={}&auth=${graphSecret}`
        ).then((res) => {
          if (!res.error) {
            setDevices(res);
          }
          if (res.error) {
            swalError(res.data);
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchDeviceDataList();
    return () => {
      unmount = true;
    };
  }, [timeValue, deviceOverview]);
  return (
    <Card>
      <CardHeader title="Devices">
        <CardHeaderToolbar>
          <div
            onClick={() => setDeviceOverview("Screen Size")}
            className={`btn ${
              deviceOverview === "Screen Size"
                ? "btn-primary"
                : "btn-outline-primary"
            } btn-sm mr-2`}
          >
            Size
          </div>
          <div
            className={`btn ${
              deviceOverview === "Browser"
                ? "btn-primary"
                : "btn-outline-primary"
            } btn-sm mr-2`}
            onClick={() => setDeviceOverview("Browser")}
          >
            Browser
          </div>
          <div
            className={`btn ${
              deviceOverview === "OS" ? "btn-primary" : "btn-outline-primary"
            } btn-sm`}
            onClick={() => setDeviceOverview("OS")}
          >
            OS
          </div>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="country_stats_wrapper">
          <div className="country_stats_title">
            <span>{deviceOverview}</span>
            <span>Visitors</span>
          </div>
          <ul>
            {devices.map((item, i) => {
              return (
                <li key={`${i}statData`}>
                  <span>{item.name}</span>
                  <span>{item.count}</span>
                  <div
                    className="line"
                    style={{ width: `${item.percentage}%` }}
                  ></div>
                </li>
              );
            })}
          </ul>
        </div>
      </CardBody>
    </Card>
  );
}
