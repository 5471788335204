/* eslint-disable eqeqeq */
import axios from "axios";
import { useHistory } from "react-router-dom";
import store from "../../redux/store";

// const token = () => {
//   let token = JSON.parse(localStorage.getItem("authToken"));
//   if (token != undefined) return token;
//   return false;
// };

// const customInstance = axios.create({
//   // baseURL : 'http://localhost:8080/',
//   baseURL: `${process.env.REACT_APP_API_URL}`,
//   headers: {
//     Accept: "application/json",
//     Authorization: `${token()}`,
//   },
// });

const axiosInstance = axios.create({
  // baseURL : 'http://localhost:8080/',
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const {
      auth: { authToken },
    } = store.getState();

    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (err) => Promise.reject(err)
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status >= 200 && response.status <= 299) {
      return response;
    }
    return Promise.reject(response);
  },
  (error) => {
    const history = useHistory();
    const expErr =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;
    if (error.response.status === 401) {
      history.push("/logout");
    }

    if (!expErr) {
      console.log("Unexpected error : ", error.response);
    }

    if (error.response) {
      return error.response.data;
    }

    return Promise.reject(error);
  }
);

// axiosInstance.interceptors.response.use(null, (error) => {
//   const expErr =
//     error.response &&
//     error.response.status >= 400 &&
//     error.response.status < 500;
//   if (!expErr) {
//     console.log("Unexpected error : ", error.response);
//   }
//   if (error.response) {
//     return error.response;
//   }
//   return Promise.reject(error);
// });

export default axiosInstance;
