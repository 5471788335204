import React, { useMemo, useEffect } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/scholarship/scholarshipAction";
import { useScholarshipUIContext } from "../ScholarshipUIContext";
import Select from "react-select";
const prepareFilter = (queryParams, values) => {
  const {
    status,
    levelId,
    searchText,
    university,
    specializationId,
    country,
  } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};

  filter.status = status;
  filter.university = university;
  filter.specializationId = specializationId;
  filter.country = country;

  // Filter by level
  // filter.levelId = 1;

  // Filter by all fields
  filter.scholarshipName = searchText;
  newQueryParams.filter = filter;

  return newQueryParams;
};

export function ScholarshipsFilter(props) {
  // Scholarships UI Context
  const scholarshipsUIContext = useScholarshipUIContext();
  const dispatch = useDispatch();

  const {
    listLoading,
    currentState,
    uniDropdown,
    statusOverview,
    departments,
    workingCountryOptions,
  } = useSelector(
    (state) => ({
      listLoading: state.allScholarship.listLoading,
      currentState: state.allScholarship,
      uniDropdown: state.allScholarship.uniDropdown,
      statusOverview: state.allScholarship.statusOverview,
      departments: state.allScholarship.departments,
      workingCountryOptions: state.allScholarship.workingCountryOptions,
    }),
    shallowEqual
  );
  useEffect(() => {
    // dispatch(actions.fetchStatus());
    dispatch(actions.fetchUniDropdownList());
    dispatch(actions.fetchStatus());
    dispatch(actions.fetchDepartment());
    dispatch(actions.fetchAllWorkingCountryDropdown());
  }, [dispatch]);
  const scholarshipsUIProps = useMemo(() => {
    return {
      queryParams: scholarshipsUIContext.queryParams,
      setQueryParams: scholarshipsUIContext.setQueryParams,
    };
  }, [scholarshipsUIContext]);
  const { filteredStatusOverview } = currentState;
  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(
      scholarshipsUIProps.queryParams,
      values
    );
    if (!isEqual(newQueryParams, scholarshipsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      scholarshipsUIProps.setQueryParams(newQueryParams);
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          status: scholarshipsUIProps?.queryParams?.filter?.status,
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit} className="mb-5">
            <div className="filterForm">
              <div className="form-column status-filter">
                <button
                  className={`btn btn-published ${
                    scholarshipsUIProps &&
                    scholarshipsUIProps?.queryParams?.filter?.status ===
                      "Active"
                      ? "active"
                      : ""
                  }`}
                  type="button"
                  onClick={() => {
                    resetForm();
                    setFieldValue("status", "Active");
                    handleSubmit();
                  }}
                >
                  <span>Active</span>
                  <strong>{statusOverview && statusOverview.publish}</strong>
                </button>
                <button
                  className={`btn btn-draft ${
                    scholarshipsUIProps &&
                    scholarshipsUIProps?.queryParams?.filter?.status === "Draft"
                      ? "active"
                      : ""
                  }`}
                  type="button"
                  onClick={() => {
                    resetForm();
                    setFieldValue("status", "Draft");
                    handleSubmit();
                  }}
                >
                  <span>Draft</span>
                  <strong>{statusOverview && statusOverview.draft}</strong>
                </button>
                <button
                  className={`btn btn-trashed ${
                    scholarshipsUIProps &&
                    scholarshipsUIProps?.queryParams?.filter?.status ===
                      "Deleted"
                      ? "active"
                      : ""
                  }`}
                  type="button"
                  onClick={() => {
                    resetForm();
                    setFieldValue("status", "Deleted");
                    handleSubmit();
                  }}
                >
                  <span>Trashed</span>
                  <strong>{statusOverview && statusOverview.trash}</strong>
                </button>
              </div>
              <div
                className={
                  " form-control btn btn-outline-danger btn-md col-md-1"
                }
                onClick={() => {
                  resetForm();
                  handleSubmit();
                }}
              >
                Reset
              </div>
              <div className="col-lg-2">
                <Select
                  placeholder="Filter by Country"
                  name="country"
                  options={workingCountryOptions}
                  onBlur={handleBlur}
                  onChange={(item) => {
                    setFieldValue("country", parseInt(item.value));
                    handleSubmit();
                  }}
                  value={
                    workingCountryOptions &&
                    workingCountryOptions.filter(
                      (item) =>
                        parseInt(item.value) === parseInt(values.country)
                    )
                  }
                />
                <small className="form-text text-muted">
                  <b>Filter</b> By Country
                </small>
              </div>
              <div className="col-lg-2">
                <Select
                  placeholder="Filter by Preference"
                  name="specializationId"
                  options={departments}
                  onBlur={handleBlur}
                  onChange={(item) => {
                    setFieldValue("specializationId", parseInt(item.value));
                    handleSubmit();
                  }}
                  value={
                    departments &&
                    departments.filter(
                      (item) =>
                        parseInt(item.value) ===
                        parseInt(values.specializationId)
                    )
                  }
                />
                <small className="form-text text-muted">
                  <b>Filter</b> By Preference
                </small>
              </div>
              <div className="col-lg-2">
                <Select
                  placeholder="Filter by university"
                  name="university"
                  options={uniDropdown}
                  onBlur={handleBlur}
                  onChange={(item) => {
                    setFieldValue("university", parseInt(item.value));
                    handleSubmit();
                  }}
                  value={
                    uniDropdown &&
                    uniDropdown.filter(
                      (item) =>
                        parseInt(item.value) === parseInt(values.university)
                    )
                  }
                />
                <small className="form-text text-muted">
                  <b>Filter</b> By University
                </small>
              </div>
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all Scholarship Name or slug
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
