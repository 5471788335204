/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { Fragment, useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { postAPI } from "../../_unisearch/util/authUtils";
import { MediaBox } from "../common";
import { UploadForm } from "../common/media";
import UniPager from "../common/UniPager";
import { swalError } from "../helpers";
// import { encryptStorage } from "../helpers";
import styles from "./ImageInput.module.scss";
export const UniGlarray = ({
  name,
  onChange,
  component_type = "default",
  allowItem = "image", //"video", "pdf", "xlsx"
  mailwidgetlists = [],
}) => {
  //initial media name and files
  const target_name = name || "featured_image";
  const FILES_URL = `/media-manager/find-mediaFile`;

  // const uniInfo = encryptStorage.getItem("uniProfile");

  //handle popup
  const [popup, setPop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [is_EnableForm, setEnableForm] = useState(false);

  //get Media Data
  const [mediaData, setMediaData] = useState();

  //Pager Info
  const [totalPage, setTotalPage] = useState(1);
  const [perpage, setPerpage] = useState(24);
  const [currentPage, setCurrentPage] = useState(1);

  //handle close
  const handleClose = () => {
    setPop(false);
  };

  //handle show
  const handleShow = () => {
    setPop(true);
    //get media data
    handleSearch();
  };

  //search handleClose
  const [searchFields, setSearchFields] = useState({
    filter: { mediaName: "", alt: "", tag: allowItem },
    sortOrder: "DESC",
    sortField: "mediaManagerId",
    pageNumber: currentPage,
    pageSize: 24,
  });

  //handle serach input
  const handleInput = (e) => {
    const { name, value } = e.target;
    const filter = { ...searchFields.filter, [name]: value };
    setSearchFields({ ...searchFields, filter });
  };

  const handleOrder = (e) => {
    const { name, value } = e.target;
    setSearchFields({ ...searchFields, [name]: value });
  };

  //when click on handle serach button
  const handleSearch = async (customSearch = undefined) => {
    //loading true
    setLoading(true);
    let filterSearch = { ...searchFields };

    if (customSearch) {
      filterSearch = customSearch;
    }

    //post handle search
    if (mailwidgetlists) {
      await postAPI(`${FILES_URL}`, filterSearch)
        .then((response) => {
          if (response.error) {
            swalError("Something Wrong!");
          }

          if (!response.error) {
            const { total, results } = response.data;

            //set total items
            setTotalPage(total);

            //set media data
            setMediaData({ total, entities: results });
          }
        })
        .catch((error) => {
          swalError(error);
        });
    }

    //loading false
    setLoading(false);
  };

  //Manage image path
  let placeholder =
    allowItem && allowItem == "video"
      ? "/images/video-placeholder.png"
      : "/images/image-placeholder.png";

  const [imageUrl, setImageUrl] = useState(null);
  const [selectwidget, setSelectwidget] = useState(null);

  const [selected, setSelected] = useState();

  const imageChange = (data) => {
    setSelected(data);
    setImageUrl(data.src);
  };
  const widgetChange = (data) => {
    setSelectwidget(data);
  };

  const handleInsert = () => {
    const target = { src: selected.src };
    setImageUrl(selected.src);
    //pass parent props
    if (onChange) {
      onChange({ target });
    }
    setPop((prevPop) => !prevPop);
  };

  const handlePager = (page) => {
    setCurrentPage(page);
    setSearchFields({ ...searchFields, pageNumber: page });
  };

  //when change page
  useEffect(async () => {
    await handleSearch();
  }, [currentPage]);

  //change image url
  //   useEffect(() => {
  //     setImageUrl(placeholder);
  //   }, [url, placeholder]);

  //handleList
  const handleList = () => {
    setEnableForm(false);
    setCurrentPage(1);
  };

  //get data form child
  const checkUpload = (result) => {
    if (result) {
      setEnableForm(false);
      handleSearch({ ...searchFields, pageNumber: 1 });
    } else {
      swalError("Upload Error");
    }
  };

  if (component_type == "button") {
    return (
      <Fragment>
        <button
          type="button"
          className={styles.button}
          name={target_name}
          onClick={handleShow}
        ></button>
        <Modal
          dialogClassName="modal-90w"
          show={popup}
          backdrop="static"
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title className={styles.filterArea}>
              {!is_EnableForm && (
                <div className="form form-label-right">
                  <Form.Group className="row mb-0">
                    <Col xs={3}>
                      <small className="form-text text-muted">
                        Filter by Type
                      </small>
                      <select
                        className="form-control"
                        name="tag"
                        placeholder="Filter by Type"
                        onChange={handleInput}
                        value={searchFields.filter.tag}
                        disabled
                      >
                        <option value="">All</option>
                        <option value="image">Image</option>
                        <option value="video">Video</option>
                        <option value="audio">Audio</option>
                        <option value="pdf">PDF</option>
                      </select>
                    </Col>
                    <Col xs={3}>
                      <small className="form-text text-muted">
                        Short Order
                      </small>
                      <select
                        className="form-control"
                        name="sortOrder"
                        placeholder="Filter by Order"
                        onChange={handleOrder}
                        value={searchFields.sortOrder}
                      >
                        <option value="DESC">Short Order</option>
                        <option value="DESC">DESC</option>
                        <option value="ASC">ASC</option>
                      </select>
                    </Col>
                    <Col xs={3}>
                      <small className="form-text text-muted">
                        Filter by name
                      </small>
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control"
                        name="mediaName"
                        placeholder="Search"
                        value={searchFields.searchText}
                        onChange={handleInput}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSearch();
                          }
                        }}
                      />
                    </Col>
                    <Col xs={1} style={{ textAlign: "left" }}>
                      <button
                        type="button"
                        className="btn btn-icon btn-clean btn-lg mt-5"
                        onClick={() => handleSearch()}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </Col>
                    <Col xs={2} style={{ textAlign: "right" }}>
                      <button
                        type="button"
                        className="btn btn-icon btn-clean btn-lg mt-5"
                        onClick={(e) => setEnableForm(true)}
                        style={{ marginLeft: "2px" }}
                      >
                        <i className="fa fa-upload" />
                      </button>
                    </Col>
                  </Form.Group>
                </div>
              )}

              {is_EnableForm && (
                <Row>
                  <Col xs={9}>
                    <h5 className={`${styles.modalTitle}`}> Upload Files</h5>
                  </Col>
                  <Col xs={3} style={{ textAlign: "right" }}>
                    <button
                      type="button"
                      className={`btn btn-icon btn-clean btn-lg ${styles.upclsIcon}`}
                      onClick={handleList}
                    >
                      <i className="fa fa-th-large" />
                    </button>
                  </Col>
                </Row>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.modalBody}>
            {!is_EnableForm && (
              <Row>
                {loading && (
                  <div className="d-block text-center w-100 py-5">
                    <span className="spinner p-5"></span>
                  </div>
                )}
                {mediaData &&
                  mediaData.entities &&
                  mediaData.entities.map((entity, index) => {
                    return (
                      <MediaBox
                        data={entity}
                        key={entity.mediaManagerId}
                        onClick={imageChange}
                        is_selected={
                          selected &&
                          entity.mediaManagerId == selected.mediaManagerId
                        }
                      />
                    );
                  })}
                {(!mediaData?.entities || mediaData?.entities.length == 0) && (
                  <div className={styles.noRecord}>No Record Found</div>
                )}
              </Row>
            )}

            {/* Upload form */}
            {is_EnableForm && <UploadForm checkUpload={checkUpload} />}
          </Modal.Body>
          <Modal.Footer>
            {/* {!is_EnableForm && ( */}
            <UniPager
              pageClicked={handlePager}
              total={parseInt(totalPage)}
              perpage={perpage}
              current={currentPage}
            />
            {/* )} */}
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {!is_EnableForm && (
              <Button variant="primary" onClick={handleInsert}>
                Insert
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }

  return (
    <div>
      <button
        data-mailwidgetlists={selectwidget ?? ""}
        type="button"
        style={{ display: "none" }}
        name={target_name}
        onClick={handleShow}
        data-srclink={imageUrl ?? 0}
      />

      {mailwidgetlists.length > 0 ? (
        <Modal show={popup} size="sm" backdrop="static" onHide={handleClose}>
          <Modal.Body>
            {mailwidgetlists &&
              mailwidgetlists.length > 0 &&
              mailwidgetlists.map((item, index) => {
                return (
                  <span
                    className="form-names label label-lg label-light-success label-inline m-1 widgetListData"
                    type="button"
                    onClick={() => {
                      widgetChange(item);
                    }}
                    key={`fieldName${index}`}
                  >
                    {item}
                  </span>
                );
              })}
          </Modal.Body>
          <Modal.Footer className="p-1">
            <button
              className="btn btn-sm btn-secondary"
              variant="secondary"
              onClick={handleClose}
            >
              Close
            </button>

            <button
              data-mailwidgetlists={selectwidget ?? ""}
              name="insertWidgetData"
              className="btn btn-sm btn-primary"
              variant="primary"
              onClick={handleClose}
            >
              {`Insert ${selectwidget ?? ""}`}
            </button>
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal
          dialogClassName="modal-90w"
          show={popup}
          backdrop="static"
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title className={styles.filterArea}>
              {!is_EnableForm && (
                <div id="imgSearch" className="form form-label-right">
                  <Form.Group className="row mb-0">
                    <Col xs={3}>
                      <small className="form-text text-muted">
                        Filter by Type
                      </small>
                      <select
                        className="form-control"
                        name="tag"
                        placeholder="Filter by Type"
                        onBlur={() => handleSearch()}
                        onChange={handleInput}
                        value={searchFields.filter.tag}
                      >
                        <option value="">All</option>
                        <option value="image">Image</option>
                        <option value="video">Video</option>
                        <option value="audio">Audio</option>
                        <option value="pdf">PDF</option>
                      </select>
                    </Col>
                    <Col xs={3}>
                      <small className="form-text text-muted">
                        Short Order
                      </small>
                      <select
                        className="form-control"
                        name="sortOrder"
                        placeholder="Filter by Type"
                        onBlur={() => handleSearch()}
                        onChange={handleOrder}
                        value={searchFields.sortOrder}
                      >
                        <option value="DESC">DESC</option>
                        <option value="ASC">ASC</option>
                      </select>
                    </Col>
                    <Col xs={3}>
                      <small className="form-text text-muted">
                        Filter by name
                      </small>
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control"
                        name="mediaName"
                        placeholder="Search"
                        onBlur={() => handleSearch()}
                        value={searchFields.searchText}
                        onChange={handleInput}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSearch();
                          }
                        }}
                      />
                    </Col>
                    <Col xs={1} style={{ textAlign: "left" }}>
                      <button
                        type="button"
                        className="btn btn-icon btn-clean btn-lg mt-5"
                        onClick={() => handleSearch()}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </Col>
                    <Col xs={2} style={{ textAlign: "right" }}>
                      <button
                        type="button"
                        className="btn btn-icon btn-clean btn-lg mt-5"
                        onClick={(e) => setEnableForm(true)}
                        style={{ marginLeft: "2px" }}
                      >
                        <i className="fa fa-upload" />
                      </button>
                    </Col>
                  </Form.Group>
                </div>
              )}

              {is_EnableForm && (
                <Row>
                  <Col xs={9}>
                    <h5 className={`${styles.modalTitle}`}> Upload Files</h5>
                  </Col>
                  <Col xs={3} style={{ textAlign: "right" }}>
                    <button
                      type="button"
                      className={`btn btn-icon btn-clean btn-lg ${styles.upclsIcon}`}
                      onClick={handleList}
                    >
                      <i className="fa fa-th-large" />
                    </button>
                  </Col>
                </Row>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.modalBody}>
            {!is_EnableForm && (
              <Row>
                {loading && (
                  <div className="d-block text-center w-100 py-5">
                    <span className="spinner p-5"></span>
                  </div>
                )}
                {mediaData &&
                  mediaData.entities &&
                  mediaData.entities.map((entity, index) => {
                    return (
                      <MediaBox
                        data={entity}
                        key={entity.mediaManagerId}
                        onClick={imageChange}
                        is_selected={
                          selected &&
                          entity.mediaManagerId == selected.mediaManagerId
                        }
                      />
                    );
                  })}

                {(!mediaData?.entities || mediaData?.entities.length == 0) && (
                  <div className={styles.noRecord}>No Record Found</div>
                )}
              </Row>
            )}

            {/* Upload form */}
            {is_EnableForm && <UploadForm checkUpload={checkUpload} />}
          </Modal.Body>
          <Modal.Footer>
            {!is_EnableForm && (
              <UniPager
                pageClicked={handlePager}
                total={parseInt(totalPage)}
                perpage={parseInt(perpage)}
                current={parseInt(currentPage)}
              />
            )}
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {!is_EnableForm && (
              <Button
                name="insertimages"
                data-srclink={imageUrl ?? null}
                variant="primary"
                onClick={handleInsert}
              >
                Insert
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default UniGlarray;
