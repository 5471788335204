import { createSlice } from "@reduxjs/toolkit";

const initialUniState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  groupForEdit: undefined,
  error: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const forumGroupSlice = createSlice({
  name: "group",
  initialState: initialUniState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getgroupById
    groupFetched: (state, action) => {
      state.actionsLoading = false;
      state.groupForEdit = action.payload.groupForEdit;
      state.error = null;
    },

    // findgroup
    allGroupFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // creategroup
    groupCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },

    // updategroup
    groupUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },

    // deletegroup
    groupDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.groupId !== action.payload.id
      );
    },

    // deletegroup
    groupHardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.groupId !== action.payload.id
      );
    },

    statusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.statusOverview = action.payload.statusOverview;
    },
  },
});
