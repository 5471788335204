import { createSlice } from "@reduxjs/toolkit";

const initialUniState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  error: null,
  statusOverview: {},
  branchOptions: [],
  branchForEdit: {},
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const uniBranchSlice = createSlice({
  name: "branch",
  initialState: initialUniState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // findbranch
    allBranchFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // deletebranch
    branchDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.branchId !== action.payload.id
      );
    },

    // deletebranch
    branchHardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.branchId !== action.payload.id
      );
    },

    branchUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.branchId !== action.payload.id
      );
    },

    branchFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.branchForEdit = action.payload.branchForEdit;
    },

    statusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.statusOverview = action.payload.statusOverview;
    },

    parentBranchFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.branchOptions = action.payload.branchOptions;
    },

    // Active branch
    branchActive: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },

    // createbranch
    branchCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },
  },
});
