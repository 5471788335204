import { createSlice } from "@reduxjs/toolkit";

const initialUniState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  uniDepartmentForEdit: undefined,
  error: null,
  statusOverview: {},
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const uniDepartmentSlice = createSlice({
  name: "uniDepartment",
  initialState: initialUniState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getuniDepartmentById
    uniDepartmentFetched: (state, action) => {
      state.actionsLoading = false;
      state.uniDepartmentForEdit = action.payload.uniDepartmentForEdit;
      state.error = null;
    },

    // finduniDepartment
    allUniDepartmentFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createuniDepartment
    uniDepartmentCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },

    // updateuniDepartment
    uniDepartmentUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },

    // deleteuniDepartment
    uniDepartmentDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.uniDepartmentId !== action.payload.id
      );
    },

    // deleteuniDepartment
    uniDepartmentHardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.uniDepartmentId !== action.payload.id
      );
    },
    statusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.statusOverview = action.payload.statusOverview;
    },
  },
});
