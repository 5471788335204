/* eslint-disable array-callback-return */
/* eslint-disable no-dupe-keys */
import moment from "moment";
export const StatusCssClasses = {
  Active: "success",
  Inactive: "danger",
  Draft: "info",
};

export const StatusTitles = ["Active", "Inactive", "Draft"];

export const TypeCssClasses = ["success", "primary", ""];

export const defaultSorted = [{ dataField: "id", order: "asc" }];

export const sizePerPageList = (totalCount) => {
  let returnDAta = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "30", value: 30 },
  ];
  if (totalCount > 30) {
    returnDAta.concat({ text: totalCount, value: totalCount });
  }
};

export const initialFilter = {
  filter: {
    name: "",
    id: "",
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10,
};
export const socialMediaNames = [
  { name: "Facebook" },
  { name: "Twitter" },
  { name: "Snapchat" },
  { name: "Instagram" },
  { name: "Youtube" },
  { name: "Tiktok" },
  { name: "Twitch" },
  { name: "Whatsapp" },
  { name: "Line" },
  { name: "WeChat" },
  { name: "Kik" },
  { name: "Pinterest" },
  { name: "Tumblr" },
  { name: "Ask.fm" },
  { name: "SoundCloud" },
  { name: "Spotify" },
  { name: "LinkedIn" },
  { name: "Skype" },
  { name: "QQ" },
  { name: "KakoTalk" },
  { name: "VK" },
  { name: "OK" },
  { name: "Github" },
  { name: "Oculus" },
];
export const yearList = () => {
  return [
    { value: 2022, label: 2022 },
    { value: 2021, label: 2021 },
    { value: 2020, label: 2020 },
    { value: 2019, label: 2019 },
    { value: 2018, label: 2018 },
    { value: 2017, label: 2017 },
  ];
};

//status options
export const statusOptions = [
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Deleted",
    label: "Trash",
  },
  {
    value: "Draft",
    label: "Draft",
  },
];
//status options
export const addStatusOptions = [
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Draft",
    label: "Draft",
  },
];
//video options
export const videoOptions = [
  {
    value: "youtube",
    label: "Youtube",
  },
  {
    value: "vimeo",
    label: "Vimeo",
  },
];

//News status
export const vlogStatusOptions = [
  {
    value: "Active",
    label: "Publish",
  },
  {
    value: "Draft",
    label: "Draft",
  },
  {
    value: "Inactive",
    label: "Trash",
  },
];

//News Formate
export const newsFormats = [
  {
    value: "standard",
    label: "Standard",
  },
  {
    value: "audio",
    label: "Audio",
  },
  {
    value: "gallery",
    label: "Gallery",
  },
  {
    value: "link",
    label: "Link",
  },
  {
    value: "quote",
    label: "Quote",
  },
  {
    value: "video",
    label: "Video",
  },
];

//News Formate
export const newsTyps = [
  {
    value: "isFeatured",
    label: "Is Featured",
  },
  {
    value: "isBreakingNews",
    label: "Breaking News",
  },
  {
    value: "isMustRead",
    label: "Must Read",
  },
];

//blog formates
export const blogFormat = [
  { value: "standard", label: "Standard" },
  { value: "audio", label: "Audio" },
  { value: "gallery", label: "Gallery" },
  { value: "link", label: "link" },
  { value: "quote", label: "quote" },
  { value: "video", label: "video" },
];

//nav sections
export const sectionOptions = [
  {
    value: "Overview",
    label: "Overview",
  },
  {
    value: "Common",
    label: "Common",
  },
  {
    value: "University",
    label: "University",
  },
  {
    value: "Settings",
    label: "Settings",
  },
  {
    value: "Leads",
    label: "Leads",
  },
  {
    value: "Unisearch",
    label: "Unisearch",
  },
];
export const typeOptions = [
  {
    value: "Study",
    label: "Study",
  },
  {
    value: "Elearning",
    label: "Elearning",
  },
  {
    value: "Accommodation",
    label: "Accommodation",
  },
  {
    value: "Internships",
    label: "Internships",
  },
];
export const linkTarget = [
  {
    value: "_blank",
    label: "_blank",
  },
  {
    value: "_self",
    label: "_self",
  },
  {
    value: "_parent",
    label: "_parent",
  },
  {
    value: "_top",
    label: "_top",
  },
];

//document types
export const documentType = [
  {
    value: "brochure",
    label: "Brocure",
  },
  {
    value: "prospectus",
    label: "Prospectus",
  },
];
// evaluation objective porpose options
export const objectivePurposeOptions = [
  {
    value: "job",
    label: "Job",
  },
  {
    value: "pr",
    label: "PR",
  },
  {
    value: "education",
    label: "Education",
  },
];

//date formater
export const dateFormater = (data, formate) => {
  return moment(data).format(formate);
};

//ucfirst
export const UCfirst = (str) => {
  if (str) {
    const Capitalfirst = str.charAt(0).toUpperCase() + str.slice(1);
    return Capitalfirst;
  }
  return str;
};

//University Types
export const universityTypes = [
  {
    value: "public",
    label: "Public",
  },
  {
    value: "private",
    label: "Private",
  },
];

//react select sytle
export const reactSelectStyle = {
  control: (base, state) => ({
    ...base,
    height: "50px",
    boxShadow: " 0px 2px 5px rgba(38, 51, 77, 0.03)",
    borderColor: "none",
    "&:hover": { borderColor: "none" }, // border style on hover
    borderColor: state.isFocused ? "#e0e0e0" : "#e0e0e0",
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    color: state.isSelected ? "#F47F20" : "#B5B5C3",
    backgroundColor: state.isSelected
      ? "#F5F6FC"
      : state.isFocused
      ? "#fff"
      : provided.backgroundColor,
    "&:hover": {
      backgroundColor: "#F5F6FC",
      color: "#F47F20",
    },
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

//export caree options
export const careerOptions = [
  {
    value: "Full Time",
    label: "Full Time",
  },
  {
    value: "Part time",
    label: "Part time",
  },
];
//export course level options
export const courseLevelOptions = [
  {
    value: "1",
    label: "Undergraduate",
  },
  {
    value: "2",
    label: "Postgraduate",
  },
];
//export course level options
export const studentTypes = [
  {
    value: "website",
    label: "Website",
  },
  {
    value: "Lead",
    label: "Lead",
  },
  {
    value: "Walk in Student",
    label: "Walk in Student",
  },
];
//export course intake
export const intakeOptions = [
  {
    value: "2022 - January - March",
    label: "2022 - January - March",
  },
  {
    value: "2022 - April - June",
    label: "2022 - April - June",
  },
  {
    value: "2022 - July - September",
    label: "2022 - July - September",
  },
  {
    value: "2022 - October - December",
    label: "2022 - October - December",
  },
  {
    value: "2023 - January - March",
    label: "2023 - January - March",
  },
  {
    value: "2023 - April - June",
    label: "2023 - April - June",
  },
  {
    value: "2023 - July - September",
    label: "2023 - July - September",
  },
  {
    value: "2023 - October - December",
    label: "2023 - October - December",
  },
  {
    value: "2024 - January - March",
    label: "2024 - January - March",
  },
  {
    value: "2024 - April - June",
    label: "2024 - April - June",
  },
  {
    value: "2024 - July - September",
    label: "2024 - July - September",
  },
  {
    value: "2024 - October - December",
    label: "2024 - October - December",
  },
];

//link
export const WEB_LINK = process.env.REACT_APP_FRONTEND_URL;

//skils options
export const skillsOptions = [
  {
    value: "PHP",
    label: "PHP",
  },
];

//excerpt post
export const the_excerpt = (str, length) => {
  let string = str.replace(/(<([^>]+)>)/gi, "");
  return string.length > length ? string.substring(0, length) + "..." : string;
};

//excerpt titleCase
export const titleCase = (str) => {
  return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
};

//generate Nest Node Items
export const generateNestad = (data) => {
  if (!data || data.length < 1) return false;
  let returnData = [];
  data.map((item) => {
    let obj = { label: item.menuTitle, value: item.menuId };
    if (item.children) {
      let child = generateNestad(item.children);
      if (child) {
        obj.children = child;
      }
      returnData.push(obj);
    } else {
      returnData.push(obj);
    }
  });

  return returnData;
};

//generate Nest Node Items
export const getParentNode = (data, ArrayItem) => {
  if (!data || data.length < 1) return false;
  let returnData = [];
  data.map((item) => {
    if (ArrayItem.includes(item.value)) {
      returnData.push(parseInt(item.value));
    }

    if (item.children) {
      let childArr = getParentNode(item.children, ArrayItem);
      if (childArr.length > 0) {
        returnData.push(parseInt(item.value));
        returnData = [...returnData, ...childArr];
        return returnData;
      }
    }
  });

  return returnData;
};

//generate Nest Node Items
export const generateField = (string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};
export const blogImageRatio = 970 / 545;
