import { createSlice } from "@reduxjs/toolkit";

const initialUniState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  mentorForEdit: undefined,
  mentorEarningSummary: undefined,
  error: null,
  authorOptions: [],
  refStudent: { totalCount: 0, entities: null },
  connStudent: { totalCount: 0, entities: null },
  categoryOptions: [],
  mentorTemplateOptions: [],
  mentorTags: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const mentorSlice = createSlice({
  name: "mentor",
  initialState: initialUniState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getmentorById
    mentorFetched: (state, action) => {
      state.actionsLoading = false;
      state.mentorForEdit = action.payload.mentorForEdit;
      state.error = null;
    },

    // findmentor
    allMentorFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findmentor
    allReferredStudendFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.refStudent.entities = action.payload.refStudent.entities;
      state.refStudent.totalCount = action.payload.refStudent.totalCount;
    },
    allConnectedStudendFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.connStudent.entities = action.payload.connStudent.entities;
      state.connStudent.totalCount = action.payload.connStudent.totalCount;
    },

    // creatementor
    mentorCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },

    // updatementor
    mentorUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter(
      //   (el) => el.id !== action.payload.id
      // );
    },
    mentorEarningUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },

    // deletementor
    mentorStatusChanged: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },

    // fetch Category without children
    categoryDropdownFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.categoryOptions = action.payload.categoryOptions;
    },
    // fetch Category with children
    categoriesFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.mentorCategory = action.payload.mentorCategory;
    },

    //fetch author
    authorFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.authorOptions = action.payload.authorOptions;
    },
    // fetch mentor template
    mentorTemplateFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.mentorTemplateOptions = action.payload.mentorTemplateOptions;
    },
    //fetch tag
    tagsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.mentorTags = action.payload.mentorTags;
    },
    statusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.statusOverview = action.payload.statusOverview;
    },
    playlistFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.mentorPlaylist = action.payload.mentorPlaylist;
    },
    // createTag
    tagCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },
    // deletementor
    mentorHardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    mentorSummaryFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.mentorSummary = action.payload.mentorSummary;
    },
    mentorEarningSummaryFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.mentorEarningSummary = action.payload.mentorEarningSummary;
    },
    mentorEarningSettingsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.mentorEarningSettings = action.payload.mentorEarningSettings;
    },
    // fetch Category without children
    workableCountryDropdownFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.workableCountryOptions = action.payload.workableCountryOptions;
    },
    // fetch Category without children
    countryDropdownFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.countryOptions = action.payload.countryOptions;
    },
  },
});
