import { createSlice } from "@reduxjs/toolkit";

const initialUniState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  memberForEdit: undefined,
  error: null,
  branchOptions: [],
  countryOptions: [],
  memberCategory: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const memberSlice = createSlice({
  name: "member",
  initialState: initialUniState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getmemberById
    memberFetched: (state, action) => {
      state.actionsLoading = false;
      state.memberForEdit = action.payload.memberForEdit;
      state.error = null;
    },

    // findmember
    allMemberFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createmember
    memberCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },

    // updatemember
    memberUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter(
      //   (el) => el.id !== action.payload.id
      // );
    },

    // deletemember
    memberStatusChanged: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },

    // countryDropdown
    countryDropdownFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.countryOptions = action.payload.countryOptions;
    },
    // branchDropdown
    branchDropdownFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.branchOptions = action.payload.branchOptions;
    },
    // fetch Category with children
    categoriesFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.memberCategory = action.payload.memberCategory;
    },

    //fetch author
    authorFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.authorOptions = action.payload.authorOptions;
    },
    // fetch member template
    memberTemplateFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.memberTemplateOptions = action.payload.memberTemplateOptions;
    },
    //fetch tag
    tagsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.memberTags = action.payload.memberTags;
    },
    statusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.statusOverview = action.payload.statusOverview;
    },
    playlistFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.memberPlaylist = action.payload.memberPlaylist;
    },
    // createTag
    tagCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },
    // deletemember
    memberHardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
  },
});
