/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Fragment, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
} from "../../../../../../_metronic/_partials/controls";

import { Field } from "formik";

import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import Swal from "sweetalert2";
import { PersonalVideo } from "@material-ui/icons";

export const OtherExpenses = ({
  data,
  setFieldValue,
  errors,
  touched,
  ...props
}) => {
  const [expenseArray, setExpenseArray] = useState([]);
  const [expenses, setExpenses] = useState({});

  useEffect(() => {
    setExpenses({});
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setExpenses((prev) => ({ ...prev, [name]: value }));
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const list = [...expenseArray];
        list.splice(index, 1);
        setExpenseArray(list);

        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setExpenseArray([
      ...expenseArray,
      { livingCostType: "", valueFrom: "", valueTo: "" },
    ]);
  };

  useEffect(() => {
    if (data.livingCost && data.livingCost.length > 0) {
      setExpenseArray(data.livingCost);
    } else {
      setExpenseArray([{ livingCostType: "", valueFrom: "", valueTo: "" }]);
    }
  }, [data]);

  const handleAditionalChange = (e, index) => {
    //const { name, value } = e.target;
    const name = e.target.name;
    const value = parseInt(e.target.value);
    let prevState = [...expenseArray];
    let UpdateItem = { ...prevState[index], [name]: value };
    prevState[index] = UpdateItem;
    setExpenseArray(prevState);
  };
  const handleAditionalChangeTitle = (e, index) => {
    //const { name, value } = e.target;
    const name = e.target.name;
    const value = e.target.value;
    let prevState = [...expenseArray];
    let UpdateItem = { ...prevState[index], [name]: value };
    prevState[index] = UpdateItem;
    setExpenseArray(prevState);
  };

  useEffect(() => {
    setFieldValue("livingCost", expenseArray);
  }, [expenseArray]);

  return (
    <Fragment>
      <Card>
        <CardHeader title="Other expenses in 1st year" />
        <CardBody>
          {expenseArray.map((expense, i) => {
            const livingCostErrors = (errors?.length && errors[i]) || {};
            const livingCostTouched =
              (touched.livingCost?.length && touched.livingCost[i]) || {};
            return (
              <div className="row" key={`expense_${i}`}>
                <div className="col-md-4">
                  <div className="form-group">
                    <Field
                      name="livingCostType"
                      component={Input}
                      className={` form-control ${
                        livingCostErrors.livingCostType &&
                        livingCostTouched.livingCostType
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Expense Title"
                      label="Expense Title"
                      value={expenseArray[i].livingCostType}
                      onChange={(e) => handleAditionalChangeTitle(e, i)}
                      autoComplete="off"
                    />
                    {livingCostErrors.livingCostType &&
                    livingCostTouched.livingCostType ? (
                      <div className="invalid-feedback">
                        {livingCostErrors.livingCostType}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <Field
                      name="valueFrom"
                      type="number"
                      className={`form-control ${
                        livingCostErrors.valueFrom &&
                        livingCostTouched.valueFrom
                          ? "is-invalid"
                          : ""
                      }`}
                      component={Input}
                      placeholder="Expense Amount From"
                      label="Expense Amount From"
                      value={expenseArray[i].valueFrom}
                      onChange={(e) => handleAditionalChange(e, i)}
                      autoComplete="off"
                    />
                    {livingCostErrors.valueFrom &&
                    livingCostTouched.valueFrom ? (
                      <div className="invalid-feedback">
                        {livingCostErrors.valueFrom}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <Field
                      name="valueTo"
                      type="number"
                      className={`form-control ${
                        livingCostErrors.valueTo && livingCostTouched.valueTo
                          ? "is-invalid"
                          : ""
                      }`}
                      component={Input}
                      placeholder="Expense Amount To"
                      label="Expense Amount To"
                      value={
                        expenseArray[i].valueTo &&
                        expenseArray[i].valueTo != null
                          ? expenseArray[i].valueTo
                          : ""
                      }
                      onChange={(e) => handleAditionalChange(e, i)}
                      autoComplete="off"
                    />
                    {livingCostErrors.valueTo && livingCostTouched.valueTo ? (
                      <div className="invalid-feedback">
                        {livingCostErrors.valueTo}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-2 pt-6">
                  {expenseArray.length !== 1 && (
                    <button
                      type="button"
                      className="btn"
                      onClick={() => handleRemoveClick(i)}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </span>
                    </button>
                  )}
                  {expenseArray.length - 1 === i && (
                    <button
                      type="button"
                      className="btn"
                      onClick={() => handleAddClick()}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default OtherExpenses;
