import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./CourseUIHelpers";

const CourseUIContext = createContext();

export function useCourseUIContext() {
  return useContext(CourseUIContext);
}

export function CourseUIProvider({ courseUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);

  const [ids, setIds] = useState([]);

  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initCourse = {
    id: undefined,
    courseName: "",
    alt: "",
    src: "",
    tag: "",
    mimetype: "",
    status: "Active",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initCourse,
    newCourse: courseUIEvents?.newCourse,
    openEditCourse: courseUIEvents?.openEditCourse,
    openTrahCourse: courseUIEvents?.openTrahCourse,
    openDeleteCourse: courseUIEvents?.openDeleteCourse,
    openFetchCoursesDialog: courseUIEvents?.openFetchCoursesDialog,
    openFetchCourse: courseUIEvents?.openFetchCourse,
  };

  return (
    <CourseUIContext.Provider value={value}>
      {children}
    </CourseUIContext.Provider>
  );
}
