import { createSlice } from "@reduxjs/toolkit";

const initialTestimonialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  testimonialForEdit: undefined,
  error: null,
  authorOptions: [],
  categoryOptions: [],
  testimonialTemplateOptions: [],
  testimonialTags: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const testimonialSlice = createSlice({
  name: "testimonial",
  initialState: initialTestimonialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // gettestimonialById
    testimonialFetched: (state, action) => {
      state.actionsLoading = false;
      state.testimonialForEdit = action.payload.testimonialForEdit;
      state.error = null;
    },

    // findtestimonial
    allTestimonialFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createtestimonial
    testimonialCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },

    // updatetestimonial
    testimonialUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter(
      //   (el) => el.id !== action.payload.id
      // );
    },

    // deletetestimonial
    testimonialStatusChanged: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },

    // fetch Category without children
    categoryDropdownFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.categoryOptions = action.payload.categoryOptions;
    },
    // fetch Category with children
    categoriesFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.testimonialCategory = action.payload.testimonialCategory;
    },

    //fetch author
    authorFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.authorOptions = action.payload.authorOptions;
    },
    // fetch testimonial template
    testimonialTemplateFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.testimonialTemplateOptions =
        action.payload.testimonialTemplateOptions;
    },
    //fetch tag
    tagsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.testimonialTags = action.payload.testimonialTags;
    },
    statusFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.statusOverview = action.payload.statusOverview;
    },
    playlistFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.testimonialPlaylist = action.payload.testimonialPlaylist;
    },
    // createTag
    tagCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload);
    },
    // deletetestimonial
    testimonialHardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
  },
});
