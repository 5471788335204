/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, withRouter } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useSubheader } from "../../../../../_metronic/layout";
import { getAPI } from "../../../../../_unisearch/util/authUtils";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Input,
  ModalProgressBar,
  Select as FormSelect,
} from "../../../../../_metronic/_partials/controls";
import { ImageInput } from "../../../../../_unisearch/forms/ImageInput";
import { encryptStorage } from "../../../../../_unisearch/helpers";
import * as actions from "../../_redux/courses/coursesAction";
import { durations, StatusTitles } from "../CourseUIHelpers";
import { ExamComponent } from "./exam";
import { IntakeComponent } from "./intake";
import { OtherExpenses } from "./other-expenses";
import { Ranking } from "./ranking";
import { TutionFeesComponent } from "./tution-fee";
import UnieditorText from "../../../../../_unisearch/forms/UnieditorText";

//status options
const statusOptions = [
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Deleted",
    label: "Deleted",
  },
  {
    value: "Draft",
    label: "Draft",
  },
];

const initialCourse = {
  courseName: "",
  courseLevelId: undefined,
  specializationId: undefined,
  departmentId: undefined,
  courseDuration: "",
  courseDescription: "",
  intake: [{ admissionSessionId: "", month: "", monthTo: "January" }],
  status: "Draft",
  featureImageId: undefined,
  prospectusFileId: undefined,
  additionalInfo: "",
  applicationFee: undefined,
  courseRequirement: "",
  tuitionFee: [
    {
      tuitionFor: "",
      amountFrom: "",
      amountTo: "",
      numberOfCredit: "",
    },
  ],
  livingCost: [{ livingCostType: "", valueFrom: "", valueTo: "" }],
  examRequiremnt: [{ examId: "", score: "", summary: "" }],
  courseDurationType: "month",
};

export const EditCourse = ({
  history,
  match: {
    params: { id },
  },
}) => {
  let { slug } = useParams();

  // Subheader
  const subHeader = useSubheader();
  const [cardTitle, setCardTitle] = useState("Create New Course");
  const [departmentId, setDepartmentId] = useState();
  const uniInfo = encryptStorage.getItem("uniProfile");
  // const [courseDescription, setCourseDescription] = useState();
  const [examRequiremnt, setExamRequiremnt] = useState();

  const dispatch = useDispatch();

  const {
    actionsLoading,
    courseForEdit,
    courseLevel,
    specializations,
    departments,
    prerequsit,
    exams,
    admissionSessions,
  } = useSelector(
    (state) => ({
      actionsLoading: state.courses.actionsLoading,
      courseForEdit: state.courses.courseForEdit,
      courseLevel: state.courses.courseLevel,
      specializations: state.courses.specializations,
      departments: state.courses.departments,
      prerequsit: state.courses.prerequsit,
      exams: state.courses.exams,
      admissionSessions: state.courses.admissionSessions,
    }),
    shallowEqual
  );

  useEffect(async () => {
    dispatch(actions.fetchCourse(id));
    dispatch(actions.fetchCourseLevel());
    dispatch(actions.fetchDepartment());
    dispatch(actions.fetchRankintType());
    dispatch(actions.fetchRankintCompany());
    dispatch(actions.fetchAllExams());
    dispatch(actions.fetchaAdmissionSessions());
  }, [id, dispatch]);

  useEffect(async () => {
    if (courseForEdit?.departmentId)
      dispatch(actions.fetchSpecialization(courseForEdit.departmentId));
  }, [id, dispatch, courseForEdit?.departmentId]);

  useEffect(() => {
    let _title = !id ? "Create New Course" : "";
    if (courseForEdit && id) {
      _title = `Edit Course  "${courseForEdit.courseName}"`;
    }
    setCardTitle(_title);
    subHeader.setTitle(_title);
  }, [courseForEdit, id]);

  useEffect(() => {
    if (courseForEdit && courseForEdit.examRequiremnt.length > 0) {
      setExamRequiremnt(courseForEdit.examRequiremnt);
    } else {
      setExamRequiremnt([
        {
          examId: "",
          score: "",
          summary: "",
        },
      ]);
    }
  }, [id, courseForEdit, dispatch]);

  const [loading, setLoading] = useState(actionsLoading);
  const [prerequsitList, setPrerequisits] = useState();

  useEffect(async () => {
    if (courseForEdit && courseForEdit.courseLevelId) {
      await checkPrerequsite(courseForEdit.courseLevelId);
    }
  }, [courseForEdit]);

  //update Course Exam
  const handleCourseExam = (e, index, setFieldValue) => {
    const { name, value } = e.target;
    const fieldName = `examRequiremnt.${index}.${name}`;
    setFieldValue(fieldName, value);
  };

  //Add More Exam
  const AddExam = (values, setFieldValue) => {
    if (examRequiremnt.length < exams.length) {
      setExamRequiremnt([
        ...examRequiremnt,
        {
          examId: "",
          score: "",
          summary: "",
        },
      ]);

      //set values on formik state
      const newExamValue = [
        ...values.examRequiremnt,
        {
          examId: "",
          score: "",
          summary: "",
        },
      ];
      setFieldValue("examRequiremnt", newExamValue);
    }
  };

  //Remove More Exam
  const RemoveExam = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const list = [...examRequiremnt];
        list.splice(index, 1);
        setExamRequiremnt(list);
        Swal.fire({
          icon: "success",
          title: "Your file has been deleted.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };

  const btnRef = useRef();
  const saveFileClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };
  const { pathname } = useLocation();
  const backToCoursesList = () => {
    if (pathname.includes("university") === false) {
      history.push(`/courses`);
    } else {
      history.push(`/university/${slug}/course/all`);
    }
  };

  //form validation
  const CourseEditSchema = Yup.object().shape({
    courseName: Yup.string().required("Required Field"),
    courseLevelId: Yup.number().required("Required Field"),
    departmentId: Yup.number().required("Required Field"),
    courseDuration: Yup.number()
      .typeError("you must specify a number")
      .required("Required Field"),
    applicationFee: Yup.number().typeError("you must specify a number"),

    intake: Yup.array().of(
      Yup.object().shape({
        admissionSessionId: Yup.number().required("Required Field"),
        month: Yup.string().required("Required Field"),
      })
    ),
    examRequiremnt: Yup.array().of(
      Yup.object().shape({
        examId: Yup.number().required("Required Field"),
        score: Yup.string().required("Required Field"),
        summary: Yup.string(),
      })
    ),
    livingCost: Yup.array().of(
      Yup.object().shape({
        livingCostType: Yup.string().required("Required Field"),
        valueFrom: Yup.number()
          .typeError("you must specify a number")
          .required("Required Field")
          .min(0),
        valueTo: Yup.number()
          .min(0)
          .typeError("you must specify a number")
          .nullable(true),
      })
    ),
    tuitionFee: Yup.array().of(
      Yup.object().shape({
        tuitionFor: Yup.string().required("Required Field"),
        amountFrom: Yup.number()
          .required("Required Field")
          .min(0),
        amountTo: Yup.number().typeError("you must specify a number"),
        numberOfCredit: Yup.number().typeError("you must specify a number"),
      })
    ),
  });

  //get rerequsite
  const checkPrerequsite = async (value, setFieldValue = undefined) => {
    await getAPI(`/public/education/${value}`).then((res) => {
      if (!res.errors) {
        let resData = res.data;
        if (resData.length > 0) {
          if (setFieldValue) {
            setFieldValue(`coursePreReqMark.courseLevelId`, resData[0].levelId);
          }
          setPrerequisits(resData);
        } else {
          if (setFieldValue) {
            setFieldValue(`coursePreReqMark.courseLevelId`, "");
          }
          setPrerequisits({});
        }
      }
    });
  };

  //save or update course
  const saveCourse = async (values, formAction) => {
    let courseData = { ...values };

    delete courseData.courseRanking;

    courseData.isTopListed = 0;
    courseData.specializationId =
      values.specializationId !== undefined
        ? values.specializationId
        : values.departmentId;
    delete courseData.departmentId;
    if (values.isTopListed && values.isTopListed.length > 0) {
      courseData.isTopListed = values.isTopListed.includes("1") ? 1 : 0;
    }

    courseData.universityId = uniInfo?.universityId;

    Swal.fire({
      title: "Are you sure?",
      text: "store this course information!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, submit it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        if (id) {
          await dispatch(actions.updateCourse(courseData, id)).then(
            async (response) => {
              if (response) {
                Swal.fire({
                  icon: "success",
                  title: "Success!",
                  text: "Your course information hasbeen updated.",
                  showConfirmButton: false,
                  timer: 1500,
                });
                // formAction.resetForm();
              } else {
                setLoading(false);
                Swal.fire({
                  icon: "error",
                  title: "Sorry!",
                  text: "Something wrong please try with correct information.",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            }
          );
        } else {
          await dispatch(actions.createCourse(courseData)).then((response) => {
            if (response) {
              setLoading(false);
              Swal.fire({
                icon: "success",
                title: "Success!",
                text: "Your course information hasbeen created.",
                showConfirmButton: false,
                timer: 1500,
              });
              // formAction.resetForm();

              history.push(
                `/university/${slug}/course/${response.data?.courseId}/edit`
              );
            } else {
              setLoading(false);
              Swal.fire({
                icon: "error",
                title: "Sorry!",
                text: "Something wrong please try with correct information",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          });
        }
        setLoading(false);
      }
    });
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={courseForEdit || initialCourse}
        validationSchema={CourseEditSchema}
        onSubmit={(values, action) => {
          saveCourse(values, action);
        }}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          getFieldProps,
          setFieldTouched,
          errors,
          touched,
        }) => {
          return (
            <Form className="form form-label-right">
              <div className="row">
                <div className="col-md-9">
                  <Card>
                    {loading && <ModalProgressBar />}
                    <CardHeader title={cardTitle}>
                      <CardHeaderToolbar>
                        <button
                          type="button"
                          onClick={backToCoursesList}
                          className="btn btn-light"
                        >
                          <i className="fa fa-arrow-left"></i>
                          Back
                        </button>
                      </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                      <div className="form-group">
                        <Field
                          name="courseName"
                          component={Input}
                          placeholder="Course Title"
                          label="Course Title"
                          autoComplete="off"
                        />
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>level</label>
                            <Select
                              id="level"
                              name="courseLevelId"
                              classnameprefix="react-select"
                              className={`select form-control ${
                                errors.courseLevelId && touched.courseLevelId
                                  ? "is-invalid"
                                  : ""
                              }`}
                              options={courseLevel ?? []}
                              multi={false}
                              onChange={(item) => {
                                setFieldValue("courseLevelId", item.value);
                                checkPrerequsite(item.value, setFieldValue);
                              }}
                              value={
                                values.courseLevelId
                                  ? courseLevel.filter(
                                      (item) =>
                                        item.value == values.courseLevelId
                                    )
                                  : false
                              }
                            />
                            {errors.courseLevelId && touched.courseLevelId ? (
                              <div className="invalid-feedback">
                                {errors.courseLevelId}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Department</label>
                            <Select
                              id="department"
                              name="departmentId"
                              classnameprefix="react-select"
                              className={`select form-control ${
                                errors.departmentId && touched.departmentId
                                  ? "is-invalid"
                                  : ""
                              }`}
                              options={departments ?? []}
                              multi={false}
                              onChange={(item) => {
                                setFieldValue("departmentId", item.value);
                                setFieldValue("specializationId", undefined);
                                dispatch(
                                  actions.fetchSpecialization(item.value)
                                );
                              }}
                              value={
                                values.departmentId
                                  ? departments.filter(
                                      (item) =>
                                        item.value == values.departmentId
                                    )
                                  : false
                              }
                            />
                            {errors.departmentId && touched.departmentId ? (
                              <div className="invalid-feedback">
                                {errors.departmentId}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Specialization</label>
                            <Select
                              id="specialization"
                              name="specializationId"
                              classnameprefix="react-select"
                              className={`select form-control ${
                                errors.specializationId &&
                                touched.specializationId
                                  ? "is-invalid"
                                  : ""
                              }`}
                              options={specializations ?? []}
                              multi={false}
                              onChange={(item) => {
                                setFieldValue("specializationId", item.value);
                              }}
                              value={
                                values.specializationId
                                  ? specializations.filter(
                                      (item) =>
                                        item.value == values.specializationId
                                    )
                                  : false
                              }
                            />
                            {errors.specializationId &&
                            touched.specializationId ? (
                              <div className="invalid-feedback">
                                {errors.specializationId}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Duration</label>
                            <Select
                              id="courseDuration"
                              name="courseDuration"
                              classnameprefix="react-select"
                              className={`select form-control ${
                                errors.courseDuration && touched.courseDuration
                                  ? "is-invalid"
                                  : ""
                              }`}
                              options={durations ?? []}
                              multi={false}
                              onChange={(item) => {
                                setFieldValue("courseDuration", item?.value);
                              }}
                              value={
                                durations
                                  ? durations.filter(
                                      (item) =>
                                        item.value == values?.courseDuration
                                    )
                                  : {}
                              }
                            />
                            {errors.courseDuration && touched.courseDuration ? (
                              <div className="invalid-feedback">
                                {errors.courseDuration}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <label>Select Intake</label>
                        </div>
                        <div className="col-lg-12">
                          <IntakeComponent
                            setFieldValue={setFieldValue}
                            sessions={admissionSessions ?? []}
                            intakes={
                              values.intake ?? [
                                { admissionSessionId: "", month: "" },
                              ]
                            }
                            errors={errors.intake ? errors.intake : []}
                            touched={touched}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>

                  {/* TutionFeesComponent */}
                  <TutionFeesComponent
                    data={courseForEdit?.tuitionFee ?? initialCourse.tuitionFee}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    values={values}
                  />

                  {/* OtherExpenses change   */}
                  <OtherExpenses
                    setFieldValue={setFieldValue}
                    data={courseForEdit ?? initialCourse}
                    errors={errors.livingCost ? errors.livingCost : []}
                    touched={touched}
                  />

                  <Card>
                    <CardHeader title="Entry Requirements"></CardHeader>
                    <CardBody>
                      {prerequsitList && prerequsitList.length > 0 && (
                        <>
                          <div className="row">
                            <div className="col-lg-12">
                              <div
                                className={`${
                                  errors.coursePreReqMark &&
                                  errors.coursePreReqMark.courseLevelId &&
                                  touched.coursePreReqMark &&
                                  touched.coursePreReqMark.courseLevelId
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                <select
                                  id="prerequisitLevel"
                                  name="coursePreReqMark.courseLevelId"
                                  className={`prerequisitLevel`}
                                  value={
                                    values.coursePreReqMark &&
                                    values.coursePreReqMark.courseLevelId
                                      ? values.coursePreReqMark.courseLevelId
                                      : ""
                                  }
                                  onChange={(e) =>
                                    setFieldValue(
                                      "coursePreReqMark.courseLevelId",
                                      e.target.value
                                    )
                                  }
                                >
                                  {prerequsitList.map((preq, i) => {
                                    return (
                                      <option
                                        value={preq.levelId}
                                        key={`prereq_${i}`}
                                      >
                                        {preq.levelName}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div
                                className={`form-group ${
                                  errors.courseRequirement &&
                                  touched.courseRequirement
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                <UnieditorText
                                  name="courseRequirement"
                                  height="100"
                                  is_small={true}
                                  contents={
                                    courseForEdit?.courseRequirement ??
                                    initialCourse?.courseRequirement
                                  }
                                  onChange={(e) => {
                                    const course_requirment = e.target.value.replace(
                                      /(<([^>]+)>)/gi,
                                      ""
                                    );
                                    setFieldValue(
                                      "courseRequirement",
                                      course_requirment ? course_requirment : ""
                                    );
                                  }}
                                />
                                {errors.courseRequirement &&
                                touched.courseRequirement ? (
                                  <div className="validation-error">
                                    {errors.courseRequirement}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <Field
                                  name="coursePreReqMark.courseMark"
                                  component={Input}
                                  type="number"
                                  placeholder="Course Mark"
                                  label="Course Mark"
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Prerequisit Description</label>
                                <Field
                                  name="coursePreReqMark.preReqDescription"
                                  className="form-control"
                                  as="textarea"
                                  placeholder="Pre Required Description"
                                  label="Pre Required Description"
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {examRequiremnt &&
                        examRequiremnt.length > 0 &&
                        examRequiremnt.map((examItem, i) => {
                          const examErrors =
                            (errors.examRequiremnt?.length &&
                              errors.examRequiremnt[i]) ||
                            {};

                          const examTouched =
                            (touched.examRequiremnt?.length &&
                              touched.examRequiremnt[i]) ||
                            {};

                          return (
                            <div className="row" key={`coursereq_${i}`}>
                              <ExamComponent
                                exams={exams.filter(
                                  (elem) =>
                                    !examRequiremnt.find(
                                      (ex) => ex.examId == elem.id
                                    ) || examItem.examId == elem.id
                                )}
                                selectedExam={examItem}
                                index={i}
                                onChange={(e, index) =>
                                  handleCourseExam(e, index, setFieldValue)
                                }
                                errors={examErrors}
                                touched={examTouched}
                                values={
                                  values?.examRequiremnt
                                    ? values?.examRequiremnt[i]
                                    : {}
                                }
                              />

                              <div className="col-md-2 pt-6">
                                {examRequiremnt.length !== 1 && (
                                  <button
                                    type="button"
                                    className="btn"
                                    onClick={() => RemoveExam(i)}
                                  >
                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </button>
                                )}
                                {examRequiremnt.length - 1 === i &&
                                  examRequiremnt.length < exams.length && (
                                    <button
                                      type="button"
                                      className="btn"
                                      onClick={(e) => {
                                        AddExam(values, setFieldValue);
                                      }}
                                    >
                                      <i
                                        className="fa fa-plus"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  )}
                              </div>
                            </div>
                          );
                        })}
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Additional info</label>
                            <UnieditorText
                              name="additionalInfo"
                              height="100"
                              is_small={true}
                              contents={
                                courseForEdit?.additionalInfo ??
                                initialCourse?.additionalInfo
                              }
                              onChange={(e) =>
                                setFieldValue(
                                  "additionalInfo",
                                  e.target?.value ? e.target.value : ""
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label htmlFor="ghh">Course Description</label>
                            <UnieditorText
                              name="courseDescription"
                              height="450"
                              contents={
                                courseForEdit?.courseDescription ??
                                initialCourse?.courseDescription
                              }
                              onChange={(e) => {
                                setFieldValue(
                                  "courseDescription",
                                  e.target?.value ? e.target.value : ""
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>

                <div className="col-md-3">
                  <Card>
                    <CardHeader title="Action" />
                    <CardBody>
                      <div className="form-group">
                        <label>Status</label>
                        <Select
                          menuPortalTarget={
                            typeof document !== "undefined" ? document.body : ""
                          }
                          id="status"
                          name="status"
                          instanceId="status"
                          options={statusOptions ?? []}
                          onChange={(item) => {
                            setFieldValue("status", item.value);
                          }}
                          value={
                            statusOptions
                              ? statusOptions.filter(
                                  (item) => item.value === values?.status
                                )
                              : {}
                          }
                        />
                      </div>

                      <div className="form-group">
                        <label
                          className="checkbox checkbox-lg checkbox-single"
                          style={{ display: "flex", gap: "10px" }}
                          htmlFor="isTopListed"
                        >
                          <Field
                            type="checkbox"
                            name="isTopListed"
                            id="isTopListed"
                            value="1"
                            checked={
                              values.isTopListed &&
                              values.isTopListed.includes("1")
                                ? 1
                                : 0
                            }
                          />
                          <span></span>
                          Top Course
                        </label>
                      </div>

                      <button
                        type="submit"
                        onClick={() => handleSubmit}
                        className="btn btn-primary"
                        disabled={loading}
                      >
                        {id ? "Update" : "Save"}
                      </button>
                    </CardBody>
                  </Card>

                  <ImageInput
                    component_type="default"
                    allowItem="image"
                    title={`Featured Image`}
                    url={
                      courseForEdit?.featureImage
                        ? courseForEdit.featureImage.src
                        : ""
                    }
                    onChange={(event) => {
                      setFieldValue("featureImageId", event.target.value);
                    }}
                    name="featureImageId"
                    returnItem="mediaManagerId"
                  />

                  <ImageInput
                    title={`Prospectus`}
                    url={
                      courseForEdit?.prospectusFile
                        ? courseForEdit.prospectusFile.src
                        : ""
                    }
                    onChange={(event) => {
                      setFieldValue("prospectusFileId", event.target.value);
                    }}
                    name={`prospectusFileId`}
                    returnItem="mediaManagerId"
                    allowItem="pdf"
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      {/* {courseForEdit && courseForEdit.courseId && (
        <Ranking courseId={courseForEdit.courseId} />
      )} */}
    </>
  );
};

export default withRouter(EditCourse);
