// import axios from 'axios';
import API from "./api";

//Set the logged in user data in local session
const setLoggedInUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postRegister = async (url, data) => {
  return await API.post(url, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postLogin = async (url, data) => {
  return await API.post(url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500) {
        throw response.data;
      }
      return response.data;
    })
    .catch((err) => {
      throw err.errors[0];
    });
};

// postForgetPwd
const postForgetPwd = async (url, data) => {
  return await API.post(url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

// Form POST
const postAPI = async (url, data) => {
  return await API.post(url, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299) {
        return response.data;
      }
      // return response;
      throw response;
    })
    .catch((err) => {
      let error = { error: true };
      if (err && err.statusCode) {
        switch (err.statusCode) {
          case 400:
            return err;
          case 404:
            error.message =
              "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            error.message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            error.message = "Invalid credentials";
            break;
          default:
            error.message = err.message;
            break;
        }
        return error;
      }
      throw error;
    });
};

// Form PUT
const putAPI = async (url, data) => {
  return await API.put(url, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// patch api
const patchAPI = async (url, data) => {
  return await API.patch(url, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch((err) => {
      var message;
      if (err && err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Form GET
const getAPI = async (url) => {
  return await API.get(url)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch((err) => {
      var message;
      if (err && err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Form DELETE
const deleteAPI = async (url, data) => {
  return await API.delete(url, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch((err) => {
      var message;
      if (err && err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

export {
  setLoggedInUser,
  getLoggedInUser,
  isUserAuthenticated,
  postRegister,
  postLogin,
  postForgetPwd,
  postAPI,
  getAPI,
  deleteAPI,
  putAPI,
  patchAPI,
};
