/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler } from "../../../../_metronic/_partials/dropdowns";
import { encryptStorage } from "../../../../_unisearch/helpers";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { openEditCourse, openTrahCourse, openDeleteCourse }
) {
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          className="btn btn-clean btn-hover-light-primary btn-lg"
          variant="transparent"
          id="dropdown-toggle-top"
          as={DropdownCustomToggler}
          split={true}
        >
          <i className="ki ki-bold-more-hor" />
        </Dropdown.Toggle>

        <Dropdown.Menu flip={true}>
          <Dropdown.Item
            href={`${row.courseSource}`}
            target="_blank"
            rel="noreferrer"
            disabled={row.courseSource ? false : true}
          >
            View Source
          </Dropdown.Item>
          <Dropdown.Item
            href={`${process.env.REACT_APP_FRONTEND_URL}/study/${row.country.slug}/university/${row.university.slug}/${row.slug}`}
            target="_blank"
            rel="noreferrer"
          >
            View Course
          </Dropdown.Item>
          <Dropdown.Item
            href={`/university/${row.university.slug}/course/${row.courseId}/edit`}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              encryptStorage.setItem(
                "uniProfile",
                JSON.stringify(row.university)
              );
            }}
          >
            Edit Course
          </Dropdown.Item>
          {/* <Dropdown.Item
            onClick={() => openEditCourse(row.courseId, row.university.slug)}
          >
            Edit
          </Dropdown.Item> */}
          <Dropdown.Item
            onClick={() => {
              if (row.status !== "Deleted") {
                openTrahCourse([parseInt(row.courseId)]);
              } else {
                openDeleteCourse(parseInt(row.courseId));
              }
            }}
          >
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export function DurationFormater(cellContent, row, rowIndex) {
  return <>{row.courseDuration} Month</>;
}
export function FeesFormater(cellContent, row, rowIndex) {
  return (
    <>
      {row?.courseDuration} {row?.country?.currency}
    </>
  );
}
