// import axios from "axios";
import {
  postAPI,
  getAPI,
  putAPI,
  deleteAPI,
  patchAPI,
} from "../../../../../_unisearch/util/authUtils";

export const FILES_URL = `media-manager`;

// CREATE =>  POST: add a new file to the server
export function createFile(file) {
  return postAPI(FILES_URL, file);
}

// READ
export function getAllFiles() {
  return getAPI(FILES_URL);
}

export function getFileById(fileId) {
  return getAPI(`${FILES_URL}/${fileId}`);
}

export function findFiles(queryParams) {
  // return postAPI(`${FILES_URL}/find`, { queryParams });
  const { filter, pageNumber, pageSize, sortField, sortOrder } = queryParams;

  return postAPI(`/${FILES_URL}/find-mediaFile`, {
    filter: filter,
    pageNumber: pageNumber,
    pageSize: pageSize,
    sortField: sortField,
    sortOrder: sortOrder,
  });
}

// UPDATE => PUT: update the country on the server
export function updateFile(file, id) {
  return patchAPI(`${FILES_URL}/${id}`, file);
}

// DELETE => delete the File from the server
export function deleteFile(id) {
  return patchAPI(`${FILES_URL}/`, id);
}

// // DELETE File by ids
// export function deleteFiles(ids) {
//   return patchAPI(`/media-manager/hard-delete`, { ids });
// }
// DELETE File by ids
export function deleteFiles(ids) {
  return deleteAPI(`/media-manager/${ids.toString()}`);
}
